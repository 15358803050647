import React, { memo, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CarouselSliderPage from "../Components/CarouselSlider";
import NavigationBar from "../Components/NavigationBar";
import Client from "../Images/clients.png";
import "../Css/Style.css";
import AboutImage from "../Images/About.png";
import ServicePage from "../Images/service.png";
import serviceimg from "../Images/service.jpg";
import serviceIcon from "../Images/icons/iconservice.png";
import YearIcon from "../Images/icons/yearsicon.png";
import ExpertIcon from "../Images/icons/experticon.png";
import HandIcon from "../Images/icons/handicon.png";
import WorldIcon from "../Images/icons/worldicon.png";
import { Container } from "react-bootstrap";
import FooterPage from "../Components/Footer";
import Carroussel from "../Components/StyledSlider";
import CardModelImage from "../Images/Pages/card-model-img.png";
import person from "../Images/person.png";
import PolygonImg from "../Images/Pages/polygon-shape.png";
import TestimonialSlider from "../Components/ModelCardSlider";
import polygon from "../Images/Pages/polygon.jpeg";
import { Link } from "react-router-dom";
import SliderEl from "./Slider";
import ServiceCards from "./ServiceCards";
import RotateCard from "./RotateCard";
import YoutubeIcon from "../Images/icons/logos_youtube-icon.png";
import InIcon from "../Images/icons/Group(1).png";
import FBIcon from "../Images/icons/logos_facebook.png";
import InstaIcon from "../Images/icons/skill-icons_instagram.png";
import TwitterIcon from "../Images/icons/pajamas_twitter.png";
import { styled } from "@mui/system";
import MobileSlider from "../Components/MobileSlider";
import aboutimage from "../Images/aboutimage.png";

import Talkifyimg from "../Images/talkify.png"
import rsuite from "../Images/rsuite.png";
import rmart from "../Images/rmart.png"
import rapidverse from "../Images/rapidverse.png"
import rapidtoken from "../Images/rapidtoken.png"
import rapidproperty from "../Images/rapidproperty.png"
import rapidehr from "../Images/rapidehr.png"
import qube from "../Images/qubsqare.png"
import efan from "../Images/efan.png"
import doct from "../Images/doct.png"
import axios from "axios";



const cardData = [
  {
    key: 1,
    content: (
      <Box className="ModernSlider" p={3}>
        <img src={CardModelImage} width={"100%"} alt="card" />
        <Box sx={{ display: "flex", justifyContent: "end", pt: 2, pr: 2 }}>
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              marginTop: "-9%",
              position: "absolute",
              padding: "10px 20px",
              fontSize: "17px",
            }}
            className="SubmitButton"
          >
            Learn More
          </Button>
        </Box>
      </Box>
    ),
  },
  {
    key: 2,
    content: (
      <Box className="ModernSlider" p={3}>
        <img src={CardModelImage} width={"100%"} alt="card" />
        <Box sx={{ display: "flex", justifyContent: "end", pt: 2, pr: 2 }}>
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              marginTop: "-9%",
              position: "absolute",
              padding: "10px 20px",
              fontSize: "17px",
            }}
            className="SubmitButton"
          >
            Learn More
          </Button>
        </Box>
      </Box>
    ),
  },
  {
    key: 3,
    content: (
      <Box className="ModernSlider" p={3}>
        <img src={CardModelImage} width={"100%"} alt="card" />
        <Box sx={{ display: "flex", justifyContent: "end", pt: 2, pr: 2 }}>
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              marginTop: "-9%",
              position: "absolute",
              padding: "10px 20px",
              fontSize: "17px",
            }}
            className="SubmitButton"
          >
            Learn More
          </Button>
        </Box>
      </Box>
    ),
  },
  {
    key: 4,
    content: (
      <Box className="ModernSlider" p={3}>
        <img src={CardModelImage} width={"100%"} alt="card" />
        <Box sx={{ display: "flex", justifyContent: "end", pt: 2, pr: 2 }}>
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              marginTop: "-9%",
              position: "absolute",
              padding: "10px 20px",
              fontSize: "17px",
            }}
            className="SubmitButton"
          >
            Learn More
          </Button>
        </Box>
      </Box>
    ),
  },
  {
    key: 5,
    content: (
      <Box className="ModernSlider" p={3}>
        <img src={CardModelImage} width={"100%"} alt="card" />
        <Box sx={{ display: "flex", justifyContent: "end", pt: 2, pr: 2 }}>
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              marginTop: "-9%",
              position: "absolute",
              padding: "10px 20px",
              fontSize: "17px",
            }}
            className="SubmitButton"
          >
            Learn More
          </Button>
        </Box>
      </Box>
    ),
  },
];

export default function HomePage({ handleOpenDialog }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const [showAll, setShowAll] = useState(false);

  // Determine the number of cards to show based on the state
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeContent, setActiveContent] = useState("web3"); // Default content

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Use 'sm' for mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
  
    setLoading(true);
    setError(null);
    setSuccess(null);
  
    // Create a new FormData object
    const formData = new FormData();
    formData.append('userName', name);
    formData.append('workEmail', email);
    formData.append('messageTxt', message);
  
    try {
      // Send the form data using Axios
      const response = await axios.post('http://localhost/bahrain/contact_us', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
      });
  
      setSuccess('Message sent successfully!');
      setName('');
      setEmail('');
      setMessage('');
      alert(response.data.message)
    } catch (error) {
      setError('Failed to send message. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slidesNew.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slidesNew.length);
  };

  const getCardStyle = (index) => {
    const isCurrent = index === currentIndex;
    const isNext = index === (currentIndex + 1) % slidesNew.length;
    const isPrev =
      index === (currentIndex === 0 ? slidesNew.length - 1 : currentIndex - 1);

    if (index == 0) {
      return { width: 400, height: 270, fontSize: "14px" };
    } else if (index == 1) {
      return { width: 300, height: 250, fontSize: "12px" };
    } else {
      return { width: 300, height: 200, fontSize: "10px" };
    }
  };

  const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
        color: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
        color: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
        color: "#fff",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#fff",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#fff",
    },
  }));
  
  const CustomTextField = memo((props) => <StyledTextField {...props} />);

  const services = [
    {
      title: "Cloud & Infrastructure",
      description:
        "We offer comprehensive cloud services and manage IT infrastructure to optimize performance and scalability, ensuring seamless operations.",
    },
    {
      title: "Application Management Services",
      description:
        "Our services oversee and optimize the performance, availability, and security of your enterprise applications, ensuring they align with your business goals.",
    },
    {
      title: "Product Development",
      description:
        "From ideation to launch, we specialize in developing innovative products that meet market demands and drive business growth.",
    },
    {
      title: "Customer Experience",
      description:
        "We enhance customer engagement through web, mobile, and omni-channel experiences, ensuring seamless and satisfying experiences across all touchpoints.",
    },
    {
      title: "Data & Intelligence",
      description:
        "Harness the power of data with our solutions that analyze and derive insights, empowering informed decision-making and strategic planning.",
    },
    {
      title: "Automations",
      description:
        "We automate manual tasks using cutting-edge technologies, such as RPA and AI, to improve efficiency and reduce errors in your processes.",
    },
    {
      title: "Sustainability",
      description:
        "We help businesses implement sustainable practices, including ESG reporting, carbon footprint analysis, and sustainable supply chain management, to reduce environmental impact and drive long-term value.",
    },
    {
      title: "Advisory, Consulting & Education",
      description:
        "Our experts provide strategic advice, consultancy, and educational programs to help you navigate the complex IT landscape and achieve your business objectives.",
    },
  ];
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const cardStyle = {
    position: "relative",
    overflow: "hidden",
    transition: "background-color 0.3s ease",
    backgroundColor: isHovered ? "#07A117" : "transparent", // Change color on hover
    padding: "16px",
  };

  const buttonStyle = {
    display: isHovered ? "block" : "none",
    position: "absolute",
    bottom: "16px",
    right: "16px",
    backgroundColor: "white",
    color: "#07A117",
    border: "none",
    padding: "8px 16px",
    cursor: "pointer",
    borderRadius: "4px",
    fontWeight: "700",
    transition: "opacity 0.3s ease",
  };

  const slides = [
    {
      id: 1,
      title: "R-Suite",
      link: "/rsuite",
      image:rsuite,
      content:
        "Optimize operations with R-Suite, an ERP solution designed for efficiency, scalability, productivity, and growth acceleration.",
    },
    {
      id: 2,
      title: "Rapidverse",
      link: "/rapidverse",
      image: rapidverse,
      content:
        "Explore RapidVerse, the Metaverse solution redefining digital interaction, blending physical and virtual worlds seamlessly.",
    },
    {
      id: 3,
      title: "Rapid Token",
      link: "/rapidtoken",
      image: rapidtoken,
      content:
        "RapidToken revolutionizes NFTs, enhancing digital asset management and ownership with advanced features and integration.",
    },
    {
      id: 4,
      title: "Talkify",
      link: "/talkify",
      image:Talkifyimg,
      content:
        "Transform customer interaction with Talkify's advanced AI, revolutionizing service, automating tasks, and enhancing efficiency seamlessly",
    },
    {
      id: 5,
      title: "Qubesphere",
      link: "/qubesphere",
      image: qube,
      content:
        "Enhance your institution with QubeSphere, a comprehensive school management solution for streamlined administration and improved efficiency.",
    },
    {
      id: 6,
      title: "docT",
      image:doct,
      link: "/doct",
      content:
        "docT streamlines secure document sharing among partners or departments, supporting various formats and transfer methods efficiently.",
    },
    {
      id: 7,
      title: "Rapid EHR",
      image:rapidehr,
      link: "/rapidehr",
      content:
        "Rapid EHR prioritizes patient needs, empowering them with control over health information to enhance healthcare quality.",
    },
    {
      id: 8,
      title: "R-Mart",
      image: rmart,
      link: "/rmart",
      content:
        "R-Mart is a Versatile global solution for managing products, payments, orders, customers, and integrating with diverse gateways and carriers.",
    },
    {
      id: 9,
      title: "eFanEng",
      image:efan,
      link: "/efaneng",
      content:
        "eFanEng is a Mobile sports app with predict-to-win games, live coverage, analysis, podcasts, and blockchain-enhanced fan engagement.",
    },
    {
      id: 10,
      title: "Rapid Property",
      image: rapidproperty,
      link: "/rapidproperty",
      content:
        "Transform your business processes with R-Suite, the ultimate ERP solution tailored to meet the unique needs of your organization.",
    },
  ];

  const slidesNew = [
    {
      id: 1,
      image: person,
      title: "Jane Cooper",
      Role: "Marketing Coordinator",
      content:
        "Ask CDCR San Quintin State Prison 2008. We installed Purex dispensers throughout the prison to combat diseases…and it was a Roaring Success (as in Roaring Drunk) I mean we had Long lines of prisoners fist fighting to use them.",
    },
    {
      id: 2,
      image: person,
      title: "Jane Cooper",
      Role: "Marketing Coordinator",
      content:
        "Ask CDCR San Quintin State Prison 2008. We installed Purex dispensers throughout the prison to combat diseases…and it was a Roaring Success (as in Roaring Drunk) I mean we had Long lines of prisoners fist fighting to use them.",
    },
    {
      id: 3,
      image: person,
      title: "Jane Cooper",
      Role: "Marketing Coordinator",
      content:
        "Ask CDCR San Quintin State Prison 2008. We installed Purex dispensers throughout the prison to combat diseases…and it was a Roaring Success (as in Roaring Drunk) I mean we had Long lines of prisoners fist fighting to use them.",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    afterChange: (index) => setCurrentSlide(index),
  };

  const settingsnew = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true, // Center the active slide
    centerPadding: "0px", // Remove extra padding
    afterChange: (index) => setCurrentSlide(index),
  };

  const handlePrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const footerRef = useRef(null);

  const visibleSlides = showAll ? slides : slides.slice(0, 4);

  const handleButtonClick = (contentKey) => {
    setActiveContent(contentKey);
    setCurrentIndex(0); // Reset slider to the first slide
  };

  return (
    <Box sx={{ fontFamily: "Montserrat",overflow:"hidden" }}>
      <NavigationBar scrollToFooter={scrollToFooter} />
      <CarouselSliderPage handleOpenDialog={handleOpenDialog} />
      <Box sx={{ backgroundColor: "#fff", overflow: "hidden", py: 4 }}>
        <Box className="infinite-scroll">
          <img src={Client} width={"100%"} alt="clients" />
          <img src={Client} width={"100%"} alt="clients" />
        </Box>
      </Box>
      <Box sx={{ p: 3, backgroundColor: "#1C1D22" }}>
        <Box
          sx={{
            p: 3,
            display: {
              xs: "none",
              md: "block",
            },
          }}
        >
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontFamily: "Montserrat",
              color: "#07A117",
              fontWeight: "600",
              margin: "10px 0",
              // Responsive styles
              fontSize: {
                xs: "1rem", // Small font size for extra small screens
                sm: "1rem", // Larger font size for small screens
                md: "1rem", // Medium screens
                lg: "1.5rem", // Large screens
              },
            }}
          >
            Featured Products
          </Typography>
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontFamily: "Montserrat",
              color: "#fff",
              // Responsive styles
              fontSize: {
                xs: "0.8rem", // Small font size for extra small screens
                sm: "0.8rem", // Larger font size for small screens
                md: "1rem", // Medium screens
                lg: "1.2rem", // Large screens
              },
            }}
          >
            Transform your operations and stay ahead of the
          </Typography>
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontFamily: "Montserrat",
              color: "#fff",
              // Responsive styles
              mt: 1,
              fontSize: {
                xs: "0.8rem", // Small font size for extra small screens
                sm: "0.8rem", // Larger font size for small screens
                md: "1rem", // Medium screens
                lg: "1.2rem", // Large screens
              },
            }}
          >
            competition with our state-of-the-art solution.
          </Typography>
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontFamily: "Montserrat",
              color: "#fff",

              // Responsive styles
              fontSize: {
                xs: "0.8rem", // Small font size for extra small screens
                sm: "0.8rem", // Larger font size for small screens
                md: "1rem", // Medium screens
                lg: "1.2rem", // Large screens
              },
            }}
          ></Typography>
        </Box>
        <Box
          sx={{
            display: {
              xs: "block",
              lg: "none",
            },
          }}
        >
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontFamily: "Montserrat",
              color: "#07A117",
              textAlign: "center",
              // Responsive styles
              fontSize: {
                xs: "1.5rem", // Small font size for extra small screens
                sm: "1.5rem", // Larger font size for small screens
                md: "1.75rem", // Medium screens
                lg: "2rem", // Large screens
              },
            }}
          >
            Featured Products
          </Typography>
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontFamily: "Montserrat",
              color: "#fff",
              textAlign: "center",
              margin: "10px 0px",

              // Responsive styles
              fontSize: {
                xs: "0.8rem", // Small font size for extra small screens
                sm: "0.8rem", // Larger font size for small screens
                md: "1.75rem", // Medium screens
                lg: "2rem", // Large screens
              },
            }}
          >
            Delivering Solutions Leveraging “Best in class” Digital Technology
            to Enhance your Business Delivering.
          </Typography>
        </Box>

        <Box>
          {isMobile || isTablet ? (
            <div>
              <Grid container spacing={2}>
                {visibleSlides.map((slide) => (
                  <Grid item xs={6} key={slide.id}>
                    <Card
                       sx={{
                        maxWidth: isMobile ? '305px' : '400px', 
                        // height: isMobile ? "200px" : '300px',
                        fontFamily: 'Montserrat',
                        backgroundColor: '#fff',
                      }}
                    >
                      <CardContent sx={{ p: 1 }}>
                        <Typography
                          variant="h5"
                          component="div"
                          
                          sx={{
                            mb: 1.5,
                            fontSize: {
                              xs: '10px',  // Mobile
                              sm: '14px',  // Tablet and mobile
                              md: '16px',  // Tablets and up
                              lg: '18px',  // Larger screens
                            },
                            fontWeight:"bold",
                            color: 'black',
                          }}
                        >
                          {slide.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: "Montserrat",
                            fontSize: {
                              xs: '8px',  // Mobile
                              sm: '14px',  // Tablet and mobile
                              md: '16px',  // Tablets and up
                              lg: '18px',  // Larger screens
                            },
                            height: "100px",
                          }}
                        >
                          {slide.content}
                        </Typography>
                        <Link to={slide.link}>
                          <Button
                            sx={{
                              mt: 3,
                              backgroundColor: "#07A117",
                              fontSize: "10px",
                            }}
                            variant="contained"
                          >
                            View Product
                          </Button>
                        </Link>
                      </CardContent>
                      <Box>
                       
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Box mt={2} textAlign="center">
                {!showAll ? (
                  <button
                    style={{
                      backgroundColor: "#07A117",
                      fontSize: "14px",
                      color: "white",
                      padding: "10px 5px",
                      border: "none",
                      outline: "none",
                    }}
                    onClick={() => setShowAll(true)}
                  >
                    View All Products
                  </button>
                ) : (
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      onClick={() => setShowAll(false)}
                      sx={{
                        backgroundColor: "transparent",
                        color: "#07A117",
                        borderBottom: "2px solid #07A117",
                        fontSize: "14px",
                      }}
                    >
                      View Less
                    </Button>
                  </Box>
                )}
              </Box>
            </div>
          ) : (
            <Box>
              <Slider ref={sliderRef} {...settings}>
                {slides.map((slide) => (
                  <Box
                    key={slide.id}
                    sx={{ px: 2 }}
                    style={{ backgroundColor: "white" }}
                  >
                    <Card
                      className="SlickCard"
                      sx={{
                        maxWidth: 305,
                        height: "400px",
                        fontFamily: "Montserrat",
                        bgcolor: "white",
                      }}
                    >
                      <CardContent sx={{ p: 4 }}>
                        <Typography
                          style={{ fontWeight: "bold" }}
                          component="div"
                          className="headertext"
                        >
                          {slide.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ fontFamily: "Montserrat", mt: 1 }}
                          className="slidertext"
                        >
                          {slide.content}
                        </Typography>
                        <Link to={slide.link}>
                          <Button
                            className="SlickButton"
                            sx={{ mt: 4.5 }}
                            variant="contained"
                          >
                            View Product
                          </Button>
                        </Link>
                      </CardContent>
                      <Box className="hexagon-container">
                        <img
                          src={slide.image}
                          className="hexagon-image polygonImg"
                          style={{ marginTop: "0px" }}
                          width={"200"}
                          alt="products"
                        />
                      </Box>
                    </Card>
                  </Box>
                ))}
              </Slider>
              <Grid container justifyContent="end" spacing={2} sx={{ my: 2 }}>
                <Grid item>
                  <IconButton
                    sx={{
                      backgroundColor: "#fff",
                      "&:hover": { backgroundColor: "#fff" },
                      "&:focus": { backgroundColor: "#fff" },
                    }}
                    onClick={handlePrevSlide}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    sx={{
                      backgroundColor: "#fff",
                      "&:hover": { backgroundColor: "#fff" },
                      "&:focus": { backgroundColor: "#fff" },
                    }}
                    onClick={handleNextSlide}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#2C2D2F", p: { xs: 4, lg: 8 } }}>
        <Container>
          <Grid container spacing={3} sx={{ padding: { xs: "0%", lg: "5%" } }}>
            <Typography
              variant="h4"
              component="div"
              sx={{
                fontFamily: "Montserrat",
                padding: "20px",
                color: "#07A117",
                fontWeight: "600",
                display: { xs: "block", lg: "none" },
                fontFamily: "Montserrat",
                fontSize: {
                  xs: "1.4rem",
                  md: "1.2rem",
                },
              }}
            >
              About Us
            </Typography>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <img src={aboutimage} width={"100%"} alt="about image" />
            </Grid>
            <Grid
              item
              lg={7}
              md={7}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ padding: "2%" }}>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{
                    fontFamily: "Montserrat",

                    fontWeight: "600",
                    mb: 2,
                    display: { xs: "none", lg: "block" },
                    fontFamily: "Montserrat",
                    fontSize: {
                      xs: "1rem",
                      md: "1.4rem",
                    },
                  }}
                >
                  About Us
                </Typography>
                <Typography
                  variant=""
                  component="div"
                  sx={{
                    fontFamily: "Montserrat",
                    color: "#fff",
                    lineHeight: { xs: "20px", lg: "40px" },
                    fontSize: "18px",
                    fontFamily: "Montserrat",
                    fontSize: {
                      xs: "0.8rem",
                      md: "1rem",
                    },
                  }}
                >
                  RapidQube Digital is a disruptive, results-driven, next-gen IT
                  services provider solving the modern digital challenges and
                  assisting customers to adopt digital technologies with
                  best-in-class services. We help accelerate digital
                  transformation through the development and deployment of
                  cutting-edge solutions applying the blockchain ecosystem along
                  with IoT, Machine Learning (ML), Integration, and in the
                  omnichannel space.
                </Typography>
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <Button
                    sx={{
                      fontFamily: "Montserrat",
                      mt: 3,
                      width: {
                        xs: "100%",
                        md: "auto",
                      },
                      px: 4,
                      display: {
                        xs: "flex",
                      },
                      justifyContent: {
                        xs: "center",
                        md: "left",
                      },
                    }}
                    className="WebButton"
                  >
                    Learn More
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "#1C1D22", p: { xs: 0, lg: 2 } }}>
        {/* <Container>
    <Grid container spacing={3} p={4}>
        <Grid item lg={4} md={4} sm={12} xs={12} sx={{ position: "relative" }}>
            <img src={serviceimg} width={'100%'} alt="about image" style={{ opacity: 0.3 }} />
            <Box sx={{ position: "absolute", top: { xs: "30%", lg: "20%" }, left: "5%", padding: { xs: "20px", lg: "10px" } }}>
                <Typography sx={{ fontSize: { xs: "24px", md: "34px" }, color: "#07A117", fontWeight: "bold" }}>Our Services</Typography>
                <Typography sx={{ fontSize: { xs: "18px", md: "34px" } }}>Partner with us to unlock your business's full potential.</Typography>
                <Link to="services">
                    <Button sx={{ backgroundColor: "#07A117", color: "white", "&:hover": { backgroundColor: "#07A117", margin: { xs: "10px 0px", lg: "0px" } } }}>
                        Explore Our Services
                    </Button>
                </Link>
            </Box>
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
            <Grid container spacing={3}>
                {[
                    { title: 'Cloud & Infrastructure', description: 'We offer comprehensive cloud services and manage IT infrastructure to optimize performance and scalability, ensuring seamless operations.' },
                    { title: 'Application Management Services', description: 'We offer comprehensive cloud services and manage IT infrastructure to optimize performance and scalability, ensuring seamless operations.' },
                    { title: 'Product Development', description: 'We offer comprehensive cloud services and manage IT infrastructure to optimize performance and scalability, ensuring seamless operations.' },
                    { title: 'Customer Experience', description: 'We offer comprehensive cloud services and manage IT infrastructure to optimize performance and scalability, ensuring seamless operations.' },
                    { title: 'Data & Intelligence', description: 'We offer comprehensive cloud services and manage IT infrastructure to optimize performance and scalability, ensuring seamless operations.' },
                    { title: 'Advisory, Consulting & Education Services', description: 'We offer comprehensive cloud services and manage IT infrastructure to optimize performance and scalability, ensuring seamless operations.' }
                ].map((item, index) => (
                    <Grid item lg={4} md={4} sm={12} xs={12} key={index}>
                        <Card style={{ width: "100%", height: "265px", position: "relative", overflow: "hidden", backgroundColor: "black" }}>
                            <div style={{ perspective: "1000px", position: "relative", width: "100%", height: "100%" }}>
                                <div style={{ transformStyle: "preserve-3d", transition: "transform 0.6s", position: "absolute", width: "100%", height: "100%" }}>
                                    <div style={{ backfaceVisibility: "hidden", position: "absolute", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 2, backgroundColor: "#3E4046" }}>
                                        <CardContent style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", color: "white" }}>
                                            <div>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <img src={serviceIcon} width={'100'} alt="icon" />
                                                </div>
                                                <Typography style={{ marginTop: "16px", textAlign: "center", fontWeight: "bold" }}>
                                                    {item.title}
                                                </Typography>
                                            </div>
                                        </CardContent>
                                    </div>
                                    <div style={{ transform: "rotateY(180deg)", backfaceVisibility: "hidden", position: "absolute", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "black" }}>
                                        <CardContent style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", color: "white" }}>
                                            <Typography style={{ textAlign: "center" }}>
                                                {item.description}
                                            </Typography>
                                        </CardContent>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    </Grid>
</Container> */}
        <RotateCard />

        <Container>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "Center",
              mt: 5,
            }}
          >
            <Typography sx={{ color: "#07A117", fontSize: "36px" }}>
              Tech Stack
            </Typography>
            <Typography sx={{ fontSize: "16px", color: "white" }}>
              We provide scalable, secure, and innovative IT solutions using a
              <br /> modern technology stack designed to meet diverse client
              needs.
            </Typography>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "Center",
              mt: 5,
            }}
          >
            <Typography sx={{ color: "#07A117", fontSize: "28px" }}>
              Tech Stack
            </Typography>
            <Typography
              sx={{ fontSize: "16px", color: "white", padding: "15px" }}
            >
              We provide scalable, secure, and innovative IT solutions using a
              modern technology stack designed to meet diverse client needs.
            </Typography>
          </Box>

          <SliderEl
            activeContent={activeContent}
            handleButtonClick={handleButtonClick}
            setCurrentIndex={setCurrentIndex}
            setActiveContent={setActiveContent}
            currentIndex={currentIndex}
          />
        </Container>
      </Box>
      <TestimonialSlider />
      {/* <Box sx={{ backgroundColor: "#1C1D22", p: 4,maxWidth: '100%' }}>
                <Typography sx={{ color: "#07A117", fontSize: "36px", fontWeight: 700 }}>
                    What Our Client Says
                </Typography>
                <Typography sx={{ color: "white", fontSize: "36px", fontWeight: 500 }}>
                    Delivering Solutions Leveraging “Best in Class” Digital Technology to enhance your Business
                </Typography>
                <Box sx={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>

            <Box sx={{ display: 'flex',  alignItems: 'center',justifyContent:"center", width: '100%', overflow: 'hidden' }}>
                {slidesNew.map((slide, index) => {
                    const style = getCardStyle(index);

                    return (
                        <Box
                            key={index}
                            className='SlickCard'
                            sx={{
                                ...style,
                                margin: '0 10px',
                                cursor: 'pointer',
                                transition: 'transform 0.5s ease-in-out, width 0.5s ease-in-out, height 0.5s ease-in-out, padding 0.5s ease-in-out, opacity 0.5s ease-in-out',
                                // transform: `translateX(${(index - currentIndex) * (window.innerWidth - 1200)}px)`,
                                flexShrink: 0,
                            }}
                        >
                            <CardContent sx={{ p: style.padding,display:"flex",justifyContent:"center",alignItems:"start",gap:2 }}>
                                <Box sx={{width:"100px",height:"100px"}}>
                                 <img src={slide.image} />
                                </Box>
                                <Box>
                                <Typography  component="div" sx={{ mb: 1.5 }}>
                                    {slide.title}
                                </Typography>
                                <Typography  component="div" sx={{ mb: 1.5 }}>
                                    {slide.Role}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: style.fontSize }}>
                                    {slide.content}
                                </Typography>
                                </Box>

                            </CardContent>
                        </Box>
                    );
                })}
            </Box>

        </Box>
            </Box> */}
      <Box
        sx={{ backgroundColor: "#1C1D22", padding: "5%" }}
        className="bgimageitem"
      >
        <Typography
          variant="h4"
          component="div"
          sx={{
            color: "#fff",
            lineHeight: {xs:"20px",md:"40px"},
            textAlign: "center",
            fontSize: { xs: "14px", lg: "34px" },
            fontWeight: 700,
          }}
        >
          Empower Your Business with Access to World-Class Products, Services,
          and Solutions
        </Typography>
        <Typography
          variant="h6"
          component="div"
          sx={{
            color: "#fff",
            lineHeight: {xs:"20px",md:"40px"},
            textAlign: "center",
            fontSize: { xs: "12px", lg: "20px" },
            padding: isMobile ? "5px" : "0px 80px",

            mt: 3,
          }}
        >
          With Extensive Expertise, We Deliver Tailored IT Solutions and Digital{" "}
          <br />
          Transformation, Driving Innovation and Exceptional Results.
        </Typography>
        <Grid container  spacing={2} paddingTop={"5%"}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card
              className="OulinedCard"
              sx={{ width: "100%", height: "220px" }}
            >
              <CardContent>
                <Box>
                  <Box sx={{ display: "flex" }}>
                    <img src={YearIcon} width={"50"} alt="icon" />
                    <Typography
                      variant=""
                      component="div"
                      sx={{
                        mt: 1,
                        ml: 2,
                        textAlign: "center",
                        color: "#07A117",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      8+ Years
                    </Typography>
                  </Box>
                  <Typography
                    variant=""
                    component="div"
                    sx={{
                      mt: 2,
                      color: "#fff",
                      lineHeight: "25.6px",
                      fontSize: "14px",
                    }}
                  >
                    Dedicated to ongoing learning and utilizing cutting-edge
                    technologies to drive success and exceed expectations.
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card
              className="OulinedCard"
              sx={{ width: "100%", height: "220px" }}
            >
              <CardContent>
                <Box>
                  <Box sx={{ display: "flex" }}>
                    <img src={ExpertIcon} width={"50"} alt="icon" />
                    <Typography
                      variant=""
                      component="div"
                      sx={{
                        mt: 1,
                        ml: 2,
                        textAlign: "center",
                        color: "#07A117",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      8 + Framework
                    </Typography>
                  </Box>
                  <Typography
                    component="div"
                    sx={{
                      mt: 2,
                      color: "#fff",
                      lineHeight: "25.6px",
                      fontSize: "14px",
                    }}
                  >
                    we specialize in leveraging frameworks to innovate, manage
                    projects, and enhance client relationships.
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card
              className="OulinedCard"
              sx={{ width: "100%", height: "220px" }}
            >
              <CardContent>
                <Box>
                  <Box sx={{ display: "flex" }}>
                    <img src={HandIcon} width={"50"} alt="icon" />
                    <Typography
                      variant=""
                      component="div"
                      sx={{
                        mt: 1,
                        ml: 2,
                        textAlign: "center",
                        color: "#07A117",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      50+ Experts
                    </Typography>
                  </Box>
                  <Typography
                    variant=""
                    component="div"
                    sx={{
                      mt: 2,
                      color: "#fff",
                      lineHeight: "25.6px",
                      fontSize: "14px",
                    }}
                  >
                    We boast a team of 50+ experts dedicated to delivering
                    exceptional solutions and expertise in our field.
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card
              className="OulinedCard"
              sx={{ width: "100%", height: "220px" }}
            >
              <CardContent>
                <Box>
                  <Box sx={{ display: "flex" }}>
                    <img src={WorldIcon} width={"50"} alt="icon" />
                    <Typography
                      variant=""
                      component="div"
                      sx={{
                        mt: 1,
                        ml: 2,
                        textAlign: "center",
                        color: "#07A117",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      4+ Countries
                    </Typography>
                  </Box>
                  <Typography
                    variant="p"
                    component="div"
                    sx={{
                      mt: 2,
                      color: "#fff",
                      lineHeight: "25.6px",
                      fontSize: "14px",
                    }}
                  >
                    We proudly serve clients in over 4 counties, providing
                    comprehensive IT solutions and support.
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box
        p={3}
        style={{ backgroundColor: "#ffffff" }}
        ref={footerRef}
        id="footer"
      >
        <Box padding={"3%"}>
          <Grid container spacing={3} paddingTop={"5%"}>
            <Grid
              item
              lg={8.5}
              md={8.5}
              sm={12}
              xs={12}
              p={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      textAlign: "center",
                      color: "#07A117",
                      fontWeight: "700",
                    }}
                  >
                    RapidQube
                  </Typography>
                  <Typography
                    variant=""
                    component="div"
                    sx={{
                      textAlign: "center",
                      color: "#000",
                      margin: "10px 0px",
                    }}
                  >
                    Partner With us to Unlock Your Business's Full Potential.
                  </Typography>
                  <Box p={3} sx={{ display: "flex", justifyContent: "center" }}>
                            
    <a href="https://www.youtube.com/channel/UCkwqExwAISY_9yYinPUWYpg" target="_blank" rel="noopener noreferrer">
      <img style={{ paddingRight: "10px" }} src={YoutubeIcon} width={40} alt="YouTube icon" />
    </a>
    <a href="https://www.linkedin.com/company/rapidqube-bahrain/" target="_blank" rel="noopener noreferrer">
      <img style={{ paddingRight: "10px" }} src={InIcon} width={30} alt="LinkedIn icon" />
    </a>
    <a href="https://www.facebook.com/RapidQubeBahrain" target="_blank" rel="noopener noreferrer">
      <img style={{ paddingRight: "10px" }} src={FBIcon} width={30} alt="Facebook icon" />
    </a>
    <a href="https://www.instagram.com/rapidqubebahrain/" target="_blank" rel="noopener noreferrer">
      <img style={{ paddingRight: "10px" }} src={InstaIcon} width={30} alt="Instagram icon" />
    </a>
    <a href="https://x.com/RapidqubeB" target="_blank" rel="noopener noreferrer">
      <img style={{ paddingRight: "10px" }} src={TwitterIcon} width={30} alt="Twitter icon" />
    </a>
  
                                </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Typography
                    variant=""
                    component="div"
                    sx={{ color: "#07A117", fontWeight: "700" }}
                  >
                    Services
                  </Typography>
                  <Box mt={1}>
                    <Link to="/cloudinfrastructure" className="FooterLink">
                      Cloud & Infrastructure
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/dataintelligence" className="FooterLink">
                      Data & Intelligence
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/productdevelopment" className="FooterLink">
                      Product Development
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/customerexperience" className="FooterLink">
                      Customer Experience
                    </Link>
                  </Box>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12} pb={3}>
                  <Typography
                    variant=""
                    component="div"
                    sx={{ color: "#07A117", fontWeight: "700" }}
                  >
                    Products
                  </Typography>
                  <Box mt={1}>
                    <Link to="/rsuite" className="FooterLink">
                      R-suite
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/doct" className="FooterLink">
                      docT
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/rapidverse" className="FooterLink">
                      Rapid Verse
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/talkify" className="FooterLink">
                      Talkify
                    </Link>
                  </Box>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12}>
                  <Typography
                    variant=""
                    component="div"
                    sx={{ color: "#07A117", fontWeight: "700" }}
                  >
                    Our Links
                  </Typography>
                  <Box mt={1}>
                    <Link to="/talkify" className="FooterLink">
                      Products
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/services" className="FooterLink">
                      Services
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/about" className="FooterLink">
                      About Us
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3.5} md={3.5} sm={12} xs={12} style={{ backgroundColor: '#07A117', padding: '16px' }}>
      <Typography variant="h6" component="div" style={{ color: '#fff', fontSize: '20px', fontWeight: 700 }}>
        Let's Get Started
      </Typography>
      <form onSubmit={handleSubmit} style={{ marginTop: '16px' }}>
        <TextField
          label="Name"
          size="small"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ width: '100%', marginTop: '8px' }}
          InputProps={{
            style: {
              color: '#fff',
              borderColor: '#fff', // Attempt to set border color here
            },
          }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#fff', // Outline color for TextField
              },
              '&:hover fieldset': {
                borderColor: '#fff', // Outline color when hovered
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff', // Outline color when focused
              },
            },
            '& .MuiInputLabel-root': {
              color: '#fff',
            },
            '& .MuiInputBase-input': {
              color: '#fff',
            },
          }}
        />
        <TextField
          label="Work Email"
          size="small"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ width: '100%', marginTop: '8px' }}
          InputProps={{
            style: { color: '#fff' },
          }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#fff',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
        />
        <TextField
          label="Message"
          size="small"
          variant="outlined"
          multiline
          rows={3}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={{ width: '100%', marginTop: '8px' }}
          InputProps={{
            style: { color: '#fff', height: 'auto' },
          }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#fff',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          style={{
            width: '100%',
            marginTop: '8px',
            backgroundColor: '#112F37',
            color: '#fff',
            boxShadow: 'none',
            textTransform: 'none',
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0e4a52'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#112F37'}
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send'}
        </Button>
        
      </form>
    </Grid>
          </Grid>
          <Box mt={4}>
            <hr />
          </Box>
        </Box>
        <Typography
          variant=""
          component="div"
          sx={{ mb: 2, textAlign: "center", color: "#000", fontSize: "16px" }}
        >
          Copyright © 2024 RapidQube Digital Solutions WLL. All Rights Reserved.{" "}
          <Link> Privacy Policy</Link>
        </Typography>
      </Box>
    </Box>
  );
}
