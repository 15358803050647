import { Box, Button, Grid, List, ListItem, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useRef } from 'react';
import AboutImage from '../../Images/About.png'
import '../../Css/Style.css'
import TimeSet from '../../Images/icons/timesetting.png'
import FooterPage from '../../Components/Footer';
import { Accordion, Container } from 'react-bootstrap';
import AboutCarousel from '../../Images/Carousel/Aboutcarousel.png'
import CheckCircleIcon from '../../Images/icons/check-circle-broken.png'
import CustomizedAccordions from '../../Components/Accordion';
import TestimonialSlider from '../../Components/ModelCardSlider';
import NavigationBar from '../NavigationBar';
import IconService from '../../Images/icons/iconservice.png'
import IconSettings from '../../Images/icons/Group 282(1).png'
import IncreaseIcon from '../../Images/icons/Vector(3).png'
import ReduceIcon from '../../Images/icons/Group(2).png'
import UserExpIcon from '../../Images/icons/Vector(4).png'
import ResponseIcon from '../../Images/icons/Vector(5).png'
import ProductBg from '../../Images/Pages/product-bgimg.png'
import ProductSlider from '../ProductsSlider';
import efaneng from "../../Images/efan.png"
import demo from "../../Images/demo.jpg"
import price from "../../Images/stack/price.png"
import building from "../../Images/stack/building.png"
import rewards from "../../Images/stack/recocnzition.png"
import updates from "../../Images/stack/updates.png"
import engagemnt from "../../Images/stack/engagementint.png"
import content from "../../Images/stack/content.png"

export default function EFanEngPage({handleOpenDialog}) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  

    const cardData = [
        {
          icon: price,
          title: "Enhanced Sports Experience",
          description: "Advanced search, personalization, and mobile responsiveness create a user-friendly shopping experience."
        },
        {
          icon: building,
          title: "Community Building",
          description: "Connect with sports enthusiasts, fostering a community and shared passion through meaningful interactions and engagement."
        },
        {
          icon: rewards,
          title: "Rewards and Recognition",
          description: "Earn rewards and gain recognition for accurate predictions and active participation in sports activities."
        },
        {
          icon: updates,
          title: "Real-Time Updates",
          description: "Stay updated with real-time notifications and live coverage, ensuring you never miss an important moment."
        },
        {
          icon: engagemnt,
          title: "Engagement and Interaction",
          description: "Increased engagement through interactive discussions and community interactions, making sports more enjoyable."
        },
        {
          icon: content,
          title: "Comprehensive Content",
          description: "A wide range of multimedia content and live streams, providing a comprehensive sports experience."
        }
      ];

   

    const cards = [
        { id: "01", title: "Predict-to-Win", description: "Allows users to predict the outcomes of live sports matches and win rewards based on their accuracy." },
        { id: "02", title: "Live Coverage", description: "Real-time broadcasting of sports events and activities, ensuring fans don’t miss any action." },
        { id: "03", title: "Fan Engagement", description: "Connect with other sports enthusiasts in a vibrant community room." },
        { id: "04", title: "Interactive Discussions", description: "Participate in lively discussions around world and regional sports events." },
        { id: "05", title: "Leaderboards", description: "Track your sports knowledge and predictions on the leaderboard." },
        { id: "06", title: "Multimedia Content", description: "Access videos, live streams, and other multimedia content related to sports events." },
        { id: "07", title: "Notifications and Alerts", description: "Receive real-time updates, notifications, and alerts about ongoing and upcoming matches." },
        { id: "08", title: "Personalized Experience", description: "Customize your profile and preferences to receive content tailored to your interests." },
        { id: "09", title: "Social Media Integration", description: "Share your predictions and achievements on various social media platforms." }
    ];

    const footerRef = useRef(null);
    const scrollToFooter = () => {
        footerRef.current.scrollIntoView({ behavior: 'smooth' });
      };

    return (
        <Box>
            <Box sx={{ position: 'relative',height:{xs:"100vh",md:"auto"} }}>
                <NavigationBar scrollToFooter={scrollToFooter}/>
                <img src={efaneng} width="100%" alt="slider1" style={{height:"100vh",objectFit:"cover"}}/>
                <Box>
                    <Typography
                        variant="h2"
                        component="div"
                        sx={{
                            color: "#fff",
                            fontWeight: "700",
                            position: "absolute",
                            top: "55%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: '75%',
                            '@media (max-width: 720px)': {
                                fontSize: '1rem',
                                marginTop: "10px"
                            },
                            '@media (min-width: 601px) and (max-width: 960px)': {
                                fontSize: '2.5rem',
                            },
                            '@media (min-width: 961px)': {
                                fontSize: '2.5rem',
                            },
                        }}
                    >
                        eFanEng - Interactive Sports<br/> Engagement
                        <Box sx={{
                            '@media (max-width: 720px)': {
                               
                            },
                        }}>
                            <Box sx={{ display: "flex" }}>
                                <Box sx={{
                                    fontSize: "1rem", width: "45%",mt:1,
                                    '@media (max-width: 720px)': {
                                        width: "100%",
                                        fontSize: ".7rem",

                                    },
                                }}>
                                    <Typography>
                                    eFanEng is a mobile sports app offering predict-to-win games, live audio coverage, analysis, podcasts, and programs in regional football. It leverages blockchain and crypto/NFT technologies for creating and trading collectibles, enhancing fan engagement with interactive match predictions and multimedia content delivered in local languages to connected devices.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Button className='WebButton' variant="contained" onClick={handleOpenDialog}>Request to Demo</Button>
                            </Box>
                        </Box>
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: "#1C1D22" }}>
                <Grid container spacing={3} paddingTop={"5%"} sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item lg={7} md={7} sm={12} xs={12} >
                        <Box sx={{ paddingLeft:{xs:"2%", md:"13%"}, }}>
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{
                                    color: "#fff",
                                    fontWeight: "700",
                                    textAlign: "center",
                                    px: 4,
                                    fontSize:{xs:"14px",md:"24px"}
                                }}
                            >
                                RapidQube Digital is a disruptive, results-driven, next-gen IT services provider solving the modern digital challenges and
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box p={4}>
            <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center",gap:5 }}>
                {cards.map((card, index) => {
                    const textColor = index % 2 === 0 ? "black" : "white";
                    const backgroundColor = index % 2 === 0 ? "white" : "#3f51b5"; // Use a contrasting background color for white text

                    return (
                        <Grid key={index} className="GridCardHeight" item lg={3.5} md={3.5} sm={5.5} xs={12}>
                            <Box
                                className={`GridCard${index % 2 === 0 ? '2' : '3'}`}
                                p={2}
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                     gap:2,
                                    backgroundColor: backgroundColor,
                                    color: textColor,
                                    width: isMobile ? '400px' : 'auto',
                                   
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                        fontWeight: "700",
                                        color: "#07A117",
                                    
                                       
                                    }}
                                >
                                    {card.id}
                                </Typography>
                                <Typography
                                    
                                    component="div"
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize:"18px"
                                      
                                    }}
                                >
                                    {card.title}
                                </Typography>
                                <Typography
                                    component="div"
                                    sx={{
                                    
                                        lineHeight: "1.5rem",
                                        pb: 1,
                                   
                                    }}
                                >
                                    {card.description}
                                </Typography>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
                <Box sx={{ backgroundColor: "#2C2D2F", mt: 4, padding: "3%" }}>
                    <Container>
                        <Typography
                            variant="h3"
                            component="div"
                            sx={{
                                color: "#07A117",
                                fontWeight: "700",
                                fontSize:{xs:"21px",lg:"32px"}
                              
                            }}
                        >
                           Why eFanEng Stands Out
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Grid container spacing={3} >
                                    <Grid item lg={8} md={5} sm={12} xs={12} >
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                color: "#fff",
                                                fontWeight: "400",
                                                marginTop:"10px",
                                                fontSize:{xs:"14px",md:"18px"}
                                            }}
                                        >
                                           Choose Rapid EHR to transform healthcare with improved data sharing, patient engagement, and streamlined workflows for enhanced care and efficiency.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
      container
      spacing={3}
      sx={{ 
        display: "flex", 
        justifyContent: "center", 
        mt: 3, 
        m:0,
        width:{xs:'100%',lg:'auto'},
        padding: { xs: "20px", lg: "0px" } 
      }}
    >
      {cardData.map((item, index) => (
        <Grid
          key={index}
          className="GridCard4"
          item
          lg={3.5}
          md={3.5}
          sm={4.5}
          xs={12}
          mt={3}
          sx={{ mx: { sm: 2, md: 1.5, lg: 3 },border:"1px solid white" }}
        >
          <Box px={2} py={5} my={3}>
            <img src={item.icon} width={80} alt="icon service" />
            <Typography
          
              component="div"
              sx={{
                fontWeight: "700",
                mt: 3
              }}
            >
              {item.title}
            </Typography>
            <Typography
              component="div"
              sx={{
                mt: 2,
                lineHeight: "1.5rem",
                pb: 3
              }}
            >
              {item.description}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
                    </Container>
                </Box>
                <Box
          sx={{
            position: "relative",
            width: "100%",
            height: {
              xs: "400px", // Mobile view
              sm: "400px", // Other views
            },
            overflow: "hidden", // Ensure the image doesn't overflow the box
          }}
        >
          <img
            src={demo}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensure the image covers the box area
            }}
            alt="product bg"
          />
          <Button
            sx={{
              position: "absolute",
              zIndex: 999,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            variant="contained"
            className="WebButton"
            onClick={() => handleOpenDialog()}
          >
            Ask for Demo
          </Button>
        </Box>
                <Box padding={"5%"}>
                    <ProductSlider />
                </Box>
            </Box>

            <FooterPage  footerRef={footerRef}/>
        </Box>
    )
}