import { Box, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import person from "../Images/person.png";
import Slider from "react-slick";
import clientone from "../Images/clientone.png";
import clienttwo from "../Images/clienttwo.png";
import clientthree from "../Images/clientthree.png";

const TestimonialSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const cascadeSliderRef = React.useRef(null);

  const cascadeSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isTablet ? 2 : 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '8%', // Adjust this to control the preview width
  };

  const handleCascadeNext = () => {
    cascadeSliderRef.current.slickNext();
  };

  const handleCascadePrev = () => {
    cascadeSliderRef.current.slickPrev();
  };

  const CardContent = () => (
    <Box p={3}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
        <img
          src={clientone}
          style={{ width: "80px", height: "80px", objectFit: "contain", display: "flex" }}
          alt="card"
        />
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "20px", textAlign: "left" }}>Mohammed Sadiq Ali</Typography>
          <Typography sx={{ color: "#07A117", fontSize: { xs: "14px" },textAlign:"left" }}>Chief Executive Officer</Typography>
        </Box>
      </Box>
      <Typography sx={{ my: 2, fontSize: "14px",textAlign:"left" }}>
      RapidQube team has the right balance of technical &amp; domain expertise because of which they always provide best-fit solutions across industries. We admire the level of understanding of customer challenges and solutions. We are thoroughly convinced about their Technical Capability, flexibility, and Transparency. They have a very professional, hard-working, and committed team. We would most definitely work with them for our upcoming digital projects.
    
      </Typography>
    </Box>
  );
  const CardContenttwo = () => (
    <Box p={3}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
        <img
          src={clienttwo}
          style={{ width: "80px", height: "80px", objectFit: "contain", display: "flex" }}
          alt="card"
        />
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "20px", textAlign: "left" }}>Satyajit Rao Vagvala</Typography>
          <Typography sx={{ color: "#07A117", fontSize: { xs: "14px" },textAlign:"left" }}>General Manager</Typography>
        </Box>
      </Box>
      <Typography sx={{ my: 2, fontSize: "14px",textAlign:"left" }}>
      RapidQube is NISG’s preferred partner for capability building of Government officials on emerging technologies such as Blockchain, Data analytics. The team of RapidQube is adept in fulfilling the needs of the business through meticulous execution of process.



      </Typography>
    </Box>
  );
  const CardContentthree = () => (
    <Box p={3}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
        <img
          src={clientthree}
          style={{ width: "80px", height: "80px", objectFit: "contain", display: "flex" }}
          alt="card"
        />
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "20px", textAlign: "left" }}>Prasad Kolte</Typography>
          <Typography sx={{ color: "#07A117", fontSize: { xs: "14px" },textAlign:"left" }}>CXO</Typography>
        </Box>
      </Box>
      <Typography sx={{ my: 2, fontSize: "14px",textAlign:"left" }}>
      We are working with RapidQube for more than two years now. We see RapidQube as one of our emerging technology solution providers. We had a very good experience with RapidQube for our first-ever Blockchain Solution implementation. RapidQube is having a very dedicated &amp; committed team who is always available to resolve any issues. At MahaIT, I see we have many opportunities to work together on various use-case in other areas. We really acknowledge and appreciate their technical expertise and solutions capability.




      </Typography>
    </Box>
  );

  const CardContentmblone = () => (
    <Box p={3} sx={{ backgroundColor: "#323235", mx: 3,  width: { xs: "80%", sm: "220px", md: isTablet ? "80%" : "220px" }, height:"350px", borderRadius: "5px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <img
          src={clientone}
          style={{ width: "40px", height: "40px", objectFit: "contain", display: "flex" }}
          alt="card"
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography>Mohammed Sadiq Ali</Typography>
          <Typography sx={{ color: "#07A117", fontSize: { xs: "14px", md: "24px" } }}>Chief Executive Officer</Typography>
        </Box>
      </Box>
      <Typography sx={{ my: 2, fontSize: "12px" }}>
      RapidQube team has the right balance of technical &amp; domain expertise because of which they always provide best-fit solutions across industries. We admire the level of understanding of customer challenges and solutions. We are thoroughly convinced about their Technical Capability, flexibility, and Transparency. They have a very professional, hard-working, and committed team. We would most definitely work with them for our upcoming digital projects.
    
      </Typography>
    </Box>
  );
  const CardContentmbltwo = () => (
    <Box p={3} sx={{ backgroundColor: "#323235", mx: 3,  width: { xs: "80%", sm: "220px", md: isTablet ? "80%" : "220px" }, height:"350px", borderRadius: "5px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <img
          src={clienttwo}
          style={{ width: "40px", height: "40px", objectFit: "contain", display: "flex" }}
          alt="card"
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography>Satyajit Rao Vagvala</Typography>
          <Typography sx={{ color: "#07A117", fontSize: { xs: "14px", md: "24px" } }}>General Manager</Typography>
        </Box>
      </Box>
      <Typography sx={{ my: 2, fontSize: "12px" }}>
      RapidQube is NISG’s preferred partner for capability building of Government officials on emerging technologies such as Blockchain, Data analytics. The team of RapidQube is adept in fulfilling the needs of the business through meticulous execution of process.

      </Typography>
    </Box>
  );
  const CardContentmblthree = () => (
    <Box p={3} sx={{ backgroundColor: "#323235", mx: 3,  width: { xs: "80%", sm: "220px", md: isTablet ? "80%" : "220px" }, height: "350px", borderRadius: "5px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <img
          src={person}
          style={{ width: "40px", height: "40px", objectFit: "contain", display: "flex" }}
          alt="card"
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography>Prasad Kolte</Typography>
          <Typography sx={{ color: "#07A117", fontSize: { xs: "14px", md: "24px" } }}>CXO</Typography>
        </Box>
      </Box>
      <Typography sx={{ my: 2, fontSize: "12px" }}>
      We are working with RapidQube for more than two years now. We see RapidQube as one of our emerging technology solution providers. We had a very good experience with RapidQube for our first-ever Blockchain Solution implementation. RapidQube is having a very dedicated &amp; committed team who is always available to resolve any issues. At MahaIT, I see we have many opportunities to work together on various use-case in other areas. We really acknowledge and appreciate their technical expertise and solutions capability.


      </Typography>
    </Box>
  );

  const updateSlides = (index) => {
    setCurrentIndex(index);
  };

  const handlePrev = () => {
    setCurrentIndex((currentIndex - 1 + cardData.length) % cardData.length);
  };

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % cardData.length);
  };

  const cardData = [
    { key: 1, content: <CardContent /> },
    { key: 2, content: <CardContenttwo /> },
    { key: 3, content: <CardContentthree /> },
  ];

  const cardDatambl = [
    { key: 1, content: <CardContentmblone /> },
    { key: 2, content: <CardContentmbltwo /> },
    { key: 3, content: <CardContentmblthree /> },
  ];

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: isMobile  ? "auto" : "550px",
  };

  const itemStyle = (index) => ({
    position: 'absolute',
    left: index === currentIndex ? '25%' :
      index === (currentIndex + 1) % cardData.length ? '60%' : '85%',
    top: index === currentIndex ? '50%' :
      index === (currentIndex + 1) % cardData.length ? '40%' : '40%',
    transform: `translateY(-50%) translateX(-50%) scale(${index === currentIndex ? 1 : index === (currentIndex + 1) % cardData.length ? 0.5 : 0.3})`,
    transition: 'all 1s ease',
    zIndex: index === currentIndex ? 5 : 2,
    textAlign: 'center',
    backgroundColor: "#323235",
    color: '#ffffff',
    width: index === currentIndex ? "550px" : index === (currentIndex + 1) % cardData.length ? "550px" : "550px",
    height: isMobile ? "auto" : "320px",
    borderRadius: '15px',
  });

  const arrowStyle = {
    display: 'inline-block',
    position: 'absolute',
    bottom: "0px",
    cursor: 'pointer',
    zIndex: 5,
    backgroundColor: '#07a117',
    color: '#fff',
    borderRadius: '50%',
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };

  const arrowLeftStyle = {
    ...arrowStyle,
    right: '80px',
    bottom: "10px"
  };

  const arrowRightStyle = {
    ...arrowStyle,
    right: '20px',
    bottom: "10px"
  };

  const cascadeArrowLeftStyle = {
    backgroundColor: "#07a117",
    color: "white"
  };

  const cascadeArrowRightStyle = {
    backgroundColor: "#07a117",
    color: "white"
  };

  return (
    <Box sx={{ padding: "0px 5px", backgroundColor: {xs:"none",lg:"#2B2B2E"} }}>
      <Box sx={{ display: { xs: "none", lg: "block",padding:"20px 0px" } }}>
        <Typography sx={{ fontSize: { xs: "24px", lg: "50px" }, color: "#07A117", padding: "0px 40px", fontWeight: "bold" }}>What Our Client Says</Typography>
        <Typography sx={{ fontSize: "18px", padding: "0px 40px" }}>Scalable, secure IT solutions tailored to your needs<br /> with innovative, exceptional results.</Typography>
      </Box>
      <Box sx={{ display: { xs: "block", lg: "none" }, margin: "20px" }}>
        <Typography sx={{ fontSize: { xs: "24px", lg: "50px" }, marginTop: "10px", color: "#07A117", padding: "0px", fontWeight: "bold" }}>What Our Client Says</Typography>
        <Typography sx={{ fontSize: "16px", margin: "5px" }}>Scalable, secure IT solutions tailored to your needs<br /> with innovative, exceptional results.</Typography>
      </Box>

      <Box style={containerStyle}>
        {isMobile || isTablet ? (
          <Box  style={{height:"auto"}}>
            <Slider ref={cascadeSliderRef} {...cascadeSliderSettings}>
              {cardDatambl.map((slide, index) => (
                <Box key={slide.key} style={{height:"auto"}}>
                  {slide.content}
                </Box>
              ))}
            </Slider>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'end', mt: 4, p: 2 }}>
              <IconButton style={cascadeArrowLeftStyle} onClick={handleCascadePrev}>
                <ArrowBackIosNewIcon />
              </IconButton>
              <IconButton style={cascadeArrowRightStyle} onClick={handleCascadeNext}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box className="cascade-slider_slides">
              {cardData.map((slide, index) => (
                <Box
                  key={slide.key}
                  style={itemStyle(index)}
                >
                  {slide.content}
                </Box>
              ))}
            </Box>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "space-between" }}>
              <IconButton
                style={arrowLeftStyle}
                onClick={handlePrev}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <IconButton
                style={arrowRightStyle}
                onClick={handleNext}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TestimonialSlider;
