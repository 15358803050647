import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Avatar, useTheme, Button } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProBanner from '../Images/Pages/productbanner.png'
import { Link, useNavigate } from 'react-router-dom';
import circuitImage from '../Images/Pages/circuit.jpeg';
import ServiceCircuitImage from '../Images/Pages/servicecircuits.jpeg';

const testimonials = [
    { name: 'Cloud Infrastructure', quote: 'Optimizing cloud and IT management for seamless performance, scalability, and reliable operations.', path: '/cloudinfrastructure' },
    { name: 'Application Management', quote: 'Ensuring high-performance, secure, and reliable application management tailored to your business goals.', path: '/applicationmanagement' },
    { name: 'Product Development', quote: 'From ideation to launch, we develop innovative products that drive business growth..', path: '/productdevelopment' },
    { name: 'Data Intelligence', quote: "We enhance customer engagement with seamless web, mobile, and omnichannel experiences.", path: '/dataintelligence' },
    { name: 'Customer Experience', quote: 'We create seamless web, mobile, and omnichannel experiences to boost customer engagement and loyalty.', path: '/customerexperience' },
    { name: 'Automations', quote: 'We automate manual tasks with RPA and AI, enhancing efficiency and accuracy while reducing errors.', path: '/automations' },
    { name: 'Sustainability', quote: 'Ensuring high-performance, secure, and reliable application management tailored to your business goals.', path: '/sustainability' },
    { name: 'Advisoryconsulting', quote: 'Expert advice, consultancy, and education to navigate IT challenges and achieve business goals.', path: '/advisoryconsulting' },
    
];

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <ArrowForwardIosSharpIcon
            onClick={onClick}
            sx={{
                color: 'white',
                fontSize: '2rem',
                cursor: 'pointer',
                position: 'absolute',
                bottom: '-70px',
                backgroundColor: "#07A117",
                p: 1,
                borderRadius: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                zIndex: 1
            }}
        />
    );
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <ArrowBackIosSharpIcon
            onClick={onClick}
            sx={{
                color: 'white',
                fontSize: '2rem',
                cursor: 'pointer',
                position: 'absolute',
                backgroundColor: "#07A117",
                p: 1,
                borderRadius: '50%',
                bottom: '-70px',
                right: '80px',
                transform: 'translateY(-50%)',
                zIndex: 1
            }}
        />
    );
};

const settings = {
   
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const ServiceSliderEl = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{py:8,backgroundColor:"#000"}}>
            <Box sx={{ position: 'relative',padding:"40px 0px",margin:{xs:"0px",lg:"0px 80px"} ,backgroundColor: "#000"}} className="boxele">
                <Slider {...settings} >
                    {testimonials.map((testimonial, index) => (
                        <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <Box sx={{
                                margin: '20px',
                                padding: '20px',
                                borderRadius: '10px',
                                maxWidth: '280px',
                                height:"250px",
                                backgroundColor: '#07A117'

                            }} >
                             
                                <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold', pt: 3 }}>
                                    {testimonial.name}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'white', py: 3 }}>
                                    {testimonial.quote}
                                </Typography>
                                <Link to={testimonial.path} style={{padding:"10px 5px",textDecoration:"none",color:"white",fontSize:"16px", '&:hover': {
          color: "#07A117", // Change this to your desired hover color
        }}}  >Read More <span>&#10230;</span></Link>
                            </Box>
                        </Box>
                    ))}
                </Slider>
            </Box>
        </Box>
    );
};

export default ServiceSliderEl;
