import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useRef,forwardRef, useState } from 'react';
import { Link } from 'react-router-dom';
import YoutubeIcon from '../Images/icons/logos_youtube-icon.png'
import InIcon from '../Images/icons/Group(1).png'
import FBIcon from '../Images/icons/logos_facebook.png'
import InstaIcon from '../Images/icons/skill-icons_instagram.png'
import TwitterIcon from '../Images/icons/pajamas_twitter.png'
import { styled } from '@mui/system';
import axios from 'axios';


const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#fff',
            color: "#fff"
        },
        '&:hover fieldset': {
            borderColor: '#fff',
            color: "#fff"
        },
        '&.Mui-focused fieldset': {
            borderColor: '#fff',
            color: "#fff"
        },
    },
    '& .MuiInputLabel-root': {
        color: '#fff',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#fff',
    },
}));

export default function FooterPage({footerRef}) {
    

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
  
    const handleSubmit = async (event) => {
      event.preventDefault(); // Prevent the default form submission
    
      setLoading(true);
      setError(null);
      setSuccess(null);
    
      // Create a new FormData object
      const formData = new FormData();
      formData.append('userName', name);
      formData.append('workEmail', email);
      formData.append('messageTxt', message);
    
      try {
        // Send the form data using Axios
        const response = await axios.post('http://localhost/bahrain/contact_us', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
          },
        });
    
        setSuccess('Message sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
      } catch (error) {
        setError('Failed to send message. Please try again.');
      } finally {
        setLoading(false);
      }
    };
  
    return (

        <Box p={3} style={{backgroundColor:"#ffffff"}} ref={footerRef} id="footer">
            <Box padding={"3%"}>
                <Grid container spacing={3} paddingTop={"5%"}>
                    <Grid item lg={8.5} md={8.5} sm={12} xs={12} p={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Grid container spacing={3}>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Typography variant="h4" component="div" sx={{ textAlign: "center", color: "#07A117", fontWeight: "700" }}>
                                    RapidQube
                                </Typography>
                                <Typography variant="" component="div" sx={{ textAlign: "center", color: "#000",margin:"10px 0px" }}>
                                    Partner With us to Unlock your Business's Full Potential.
                                </Typography>
                                <Box p={3} sx={{ display: "flex", justifyContent: "center" }}>
                            
    <a href="https://www.youtube.com/channel/UCkwqExwAISY_9yYinPUWYpg" target="_blank" rel="noopener noreferrer">
      <img style={{ paddingRight: "10px" }} src={YoutubeIcon} width={40} alt="YouTube icon" />
    </a>
    <a href="https://www.linkedin.com/company/rapidqube-bahrain/" target="_blank" rel="noopener noreferrer">
      <img style={{ paddingRight: "10px" }} src={InIcon} width={30} alt="LinkedIn icon" />
    </a>
    <a href="https://www.facebook.com/RapidQubeBahrain" target="_blank" rel="noopener noreferrer">
      <img style={{ paddingRight: "10px" }} src={FBIcon} width={30} alt="Facebook icon" />
    </a>
    <a href="https://www.instagram.com/rapidqubebahrain/" target="_blank" rel="noopener noreferrer">
      <img style={{ paddingRight: "10px" }} src={InstaIcon} width={30} alt="Instagram icon" />
    </a>
    <a href="https://x.com/RapidqubeB" target="_blank" rel="noopener noreferrer">
      <img style={{ paddingRight: "10px" }} src={TwitterIcon} width={30} alt="Twitter icon" />
    </a>
  
                                </Box>
                            </Grid>
                          
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Typography variant="" component="div" sx={{ color: "#07A117", fontWeight: "700" }}>
                                    Services
                                </Typography>
                                <Box mt={1}>
                                    <Link to="/cloudinfrastructure" className='FooterLink'>
                                        Cloud & Infrastructure
                                    </Link>
                                </Box>
                                <Box mt={1}>
                                    <Link to="/dataintelligence" className='FooterLink'>
                                        Data & Intelligence
                                    </Link>
                                </Box>
                                <Box mt={1}>
                                    <Link to="/productdevelopment" className='FooterLink'>
                                        Product Development
                                    </Link>
                                </Box>
                                <Box mt={1}>
                                    <Link to="/customerexperience" className='FooterLink'>
                                        Customer Experience
                                    </Link>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={2} sm={12} xs={12} pb={3}>
                                <Typography variant="" component="div" sx={{ color: "#07A117", fontWeight: "700" }}>
                                    Products
                                </Typography>
                                <Box mt={1}>
                                    <Link to="/rsuite" className='FooterLink'>
                                        R-suite
                                    </Link>
                                </Box>
                                <Box mt={1}>
                                    <Link to="/doct" className='FooterLink'>
                                        docT
                                    </Link>
                                </Box>
                                <Box mt={1}>
                                    <Link to="/rapidverse" className='FooterLink'>
                                        Rapid Verse
                                    </Link>
                                </Box>
                                <Box mt={1}>
                                    <Link to="/talkify" className='FooterLink'>
                                        Talkify
                                    </Link>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={2} sm={12} xs={12}>
                                <Typography variant="" component="div" sx={{ color: "#07A117", fontWeight: "700" }}>
                                    Our Links
                                </Typography>
                                <Box mt={1}>
                                    <Link to='/talkify' className='FooterLink'>
                                        Products
                                    </Link>
                                </Box>
                                <Box mt={1}>
                                    <Link to='/services' className='FooterLink'>
                                        Services
                                    </Link>
                                </Box>
                                <Box mt={1}>
                                    <Link to="/about" className='FooterLink'>
                                        About Us
                                    </Link>
                                </Box>
                               
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={3.5} md={3.5} sm={12} xs={12} style={{ backgroundColor: '#07A117', padding: '16px' }}>
      <Typography variant="h6" component="div" style={{ color: '#fff', fontSize: '20px', fontWeight: 700 }}>
        Let's Get Started
      </Typography>
      <form onSubmit={handleSubmit} style={{ marginTop: '16px' }}>
        <TextField
          label="Name"
          size="small"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ width: '100%', marginTop: '8px' }}
          InputProps={{
            style: {
              color: '#fff',
              borderColor: '#fff', // Attempt to set border color here
            },
          }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#fff', // Outline color for TextField
              },
              '&:hover fieldset': {
                borderColor: '#fff', // Outline color when hovered
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff', // Outline color when focused
              },
            },
            '& .MuiInputLabel-root': {
              color: '#fff',
            },
            '& .MuiInputBase-input': {
              color: '#fff',
            },
          }}
        />
        <TextField
          label="Work Email"
          size="small"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ width: '100%', marginTop: '8px' }}
          InputProps={{
            style: { color: '#fff' },
          }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#fff',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
        />
        <TextField
          label="Message"
          size="small"
          variant="outlined"
          multiline
          rows={3}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={{ width: '100%', marginTop: '8px' }}
          InputProps={{
            style: { color: '#fff', height: 'auto' },
          }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#fff',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          style={{
            width: '100%',
            marginTop: '8px',
            backgroundColor: '#112F37',
            color: '#fff',
            boxShadow: 'none',
            textTransform: 'none',
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0e4a52'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#112F37'}
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send'}
        </Button>
        
      </form>
    </Grid>
                </Grid>
                <Box mt={4}>
                    <hr />
                </Box>

            </Box>
            <Typography variant="" component="div" sx={{ mb: 2, textAlign: "center", color: "#000", fontSize: "16px" }}>
                Copyright © 2024 RapidQube Digital Solutions WLL. All Rights Reserved. <Link > Privacy Policy</Link>
            </Typography>
        </Box>

    )

}