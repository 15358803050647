import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import NavigationBar from "../../Components/NavigationBar";
import AboutImage from "../../Images/About.png";
import OurMission from "../../Images/Pages/Rectangle 4454(1).png";
import OurVision from "../../Images/Pages/ourvision.png";
import "../../Css/Style.css";
import TimeSet from "../../Images/icons/timesetting.png";
import FooterPage from "../../Components/Footer";
import { Accordion, Container } from "react-bootstrap";
import ServicesBanner from "../../Images/Pages/servicesbanner.jpeg";
import CheckCircleIcon from "../../Images/icons/check-circle-broken.png";
import CustomizedAccordions from "../../Components/Accordion";
import TestimonialSlider from "../../Components/ModelCardSlider";
import ServiceImage from "../../Images/Pages/ser-Image.png";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import circuitImage from "../../Images/Pages/circuit.jpeg";
import ServiceCircuitImage from "../../Images/Pages/servicecircuits.jpeg";
import ServiceSliderEl from "../ServiceSliderEl";
import img from "../../Images/serviceseven.png"
import subimg from "../../Images/servicesubseven.png"

const cardData = [
  { key: 1, content: <img src={AboutImage} width={"100%"} alt="1" /> },
  { key: 2, content: <img src={AboutImage} alt="2" /> },
  { key: 3, content: <img src={AboutImage} alt="3" /> },
  { key: 4, content: <img src={AboutImage} alt="3" /> },
  { key: 5, content: <img src={AboutImage} alt="3" /> },
];

export default function SustainabilityPage({ handleOpenDialog }) {
  const footerRef = useRef(null);
  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          textAlign: "center",
          height: { xs: "100vh", md: "auto" },
        }}
      >
        <NavigationBar scrollToFooter={scrollToFooter} />
        <div style={{ position: "relative", width: "100%", height: "100vh" }}>
          <img
            src={img}
            alt="slider1"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))",
            }}
          />
        </div>
        <Typography
          variant="h2"
          component="div"
          sx={{
            color: "#fff",
            fontWeight: "700",
            position: "absolute",
            top: "55%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            "@media (max-width: 600px)": {
              fontSize: "1rem",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              fontSize: "2.5rem",
            },
            "@media (min-width: 961px)": {
              fontSize: "2.5rem",
            },
          }}
        >
          Transform Your Business <br />
          with RapidQube
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                width: "45%",
                textAlign: "center",
                margin: { xs: "10px 0px", lg: "0px" },

                "@media (max-width: 720px)": {
                  width: "100%",
                  fontSize: "0.8rem",
                },
              }}
            >
              RapidQube delivers innovative tech solutions to drive growth,
              enhance efficiency, and optimize performance. Our expert team
              ensures seamless integration and tailored services for maximum
              impact.
            </Typography>
          </Box>
          <Box>
            <Button
              className="WebButton"
              sx={{ mr: 3 }}
              onClick={() => handleOpenDialog()}
              variant="contained"
            >
              Get Started
            </Button>
          </Box>
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: "#000" }}>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid
            item
            lg={10}
            md={10}
            sm={10}
            xs={10}
            sx={{
                marginTop: {
                  xs: '5%', // Mobile view
                  sm: '-8%', // Other views
                },
                position: 'relative',
                backgroundColor: {
                  xs: 'none', // Mobile view
                  sm: '#1C1D22', // Other views
                },
                padding: '3%',
                borderRadius: '15px',
                mb: 4,
              }}
          >
            <Typography
              variant="h4"
              component="div"
              sx={{
                color: "#fff",
                fontWeight: "700",
                textAlign: "center",
                py: 3,
                fontSize: { xs: "16px", lg: "32px" },
              }}
            >
              Sustainability
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", pb: 3 }}>
              <img src={subimg} width={"100%"} alt="service image" />
            </Box>
            <Typography
              variant=""
              component="div"
              sx={{
                color: "#fff",
                fontSize: "16px",
                lineHeight: "1.9rem",
                py: 3,
              }}
            >
              We help businesses implement sustainable practices, including ESG
              reporting, carbon footprint analysis, and sustainable supply chain
              management, to reduce environmental impact and drive long-term
              value. Our expertise in environmental, social, and governance
              (ESG) initiatives ensures that your company can meet regulatory
              requirements and stakeholder expectations while promoting
              sustainability.
            </Typography>
            <Typography
              variant=""
              component="div"
              sx={{
                color: "#fff",
                fontSize: "16px",
                lineHeight: "1.9rem",
                py: 3,
              }}
            >
              By conducting thorough carbon footprint analyses, we identify
              areas for improvement and provide actionable strategies to
              minimize emissions. Our sustainable supply chain management
              services optimize resource use and reduce waste, enhancing overall
              efficiency and sustainability.
            </Typography>
            <Typography
              variant=""
              component="div"
              sx={{
                color: "#fff",
                fontSize: "16px",
                lineHeight: "1.9rem",
                py: 3,
              }}
            >
              We work closely with you to develop comprehensive ESG reports that
              transparently communicate your sustainability efforts and
              achievements to investors, customers, and other stakeholders. Our
              tailored approach ensures that sustainability becomes an integral
              part of your business strategy, driving long-term value and
              positive environmental impact. Partner with us to build a greener
              future and strengthen your market position through sustainable
              business practices.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ py: 0 }}>
        <ServiceSliderEl />
      </Box>
      <FooterPage footerRef={footerRef} />
    </Box>
  );
}
