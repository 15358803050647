import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Avatar, useTheme, Button } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProBanner from '../Images/Pages/productbanner.png'
import { Link, useNavigate } from 'react-router-dom';
import doct from "../Images/doct.png";
import rsuite from "../Images/rsuite.png"
import rapidverse from "../Images/rapidverse.png"
import rapidtoken from "../Images/rapidtoken.png"
import talkify from "../Images/talkify.png"
import qubes from "../Images/qubsqare.png"
import rapidehr from "../Images/rapidehr.png"
import rmart from "../Images/rmart.png"
import efan from "../Images/efan.png"
import rapid from "../Images/rapidproperty.png"


const testimonials = [
    { name: 'R-Suite', quote: 'Optimize operations with R-Suite, an ERP solution designed for efficiency, scalability, productivity, and growth acceleration.', path: '/rsuite',img:rsuite},
    { name: 'Rapidverse', quote: 'Explore RapidVerse the Metaverse solution redefining digital interaction, blending physical and virtual worlds seamlessly', path: '/rapidverse',img:rapidverse },
    { name: 'Rapid Token', quote: 'RapidToken revolutionizes NFTs, enhancing digital asset management and ownership with advanced features and integration.', path: '/rapidtoken',img:rapidtoken },
    { name: 'Talkify', quote: "Transform customer interaction with Talkify's advanced AI, revolutionizing service, automating tasks, and enhancing efficiency seamlessly", path: '/talkify',img:talkify },
    { name: 'Qubesphere', quote: 'Enhance your institution with QubeSphere, a comprehensive school management solution for streamlined administration and improved efficiency.', path: '/qubesphere',img:qubes },
    { name: 'docT', quote: 'docT streamlines secure document sharing among partners or departments, supporting various formats and transfer methods efficiently.', path: '/doct',img:doct },
    { name: 'Rapid EHR', quote: 'Rapid EHR prioritizes patient needs, empowering them with control over health information to enhance healthcare quality.', path: '/rapidehr',img:rapidehr },
    { name: 'R-Mart', quote: 'R-Mart is a Versatile global solution for managing products, payments, orders, customers, and integrating with diverse gateways and carriers.', path: '/rmart',img:rmart },
    { name: 'eFanEng', quote: 'eFanEng is a Mobile sports app with predict-to-win games, live coverage, analysis, podcasts, and blockchain-enhanced fan engagement.', path: '/efaneng',img:efan },
    { name: 'Rapid Property', quote: 'Transform your business processes with R-Suite, the ultimate ERP solution tailored to meet the unique needs of your organization.', path: '/rapidproperty',img:rapid },
];

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <ArrowForwardIosSharpIcon
            onClick={onClick}
            sx={{
                color: 'white',
                fontSize: '2rem',
                cursor: 'pointer',
                position: 'absolute',
                bottom: {xs:'-100px',lg:'-70px'},
                backgroundColor: "#07A117",
                p: 1,
                borderRadius: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                zIndex: 1,
               
            }}
        />
    );
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <ArrowBackIosSharpIcon
            onClick={onClick}
            sx={{
                color: 'white',
                fontSize: '2rem',
                cursor: 'pointer',
                position: 'absolute',
                backgroundColor: "#07A117",
                p: 1,
                borderRadius: '50%',
                bottom: {xs:'-100px',lg:'-70px'},
                right: '80px',
                transform: 'translateY(-50%)',
                zIndex: 1
            }}
        />
    );
};

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const ProductSlider = () => {
    const navigate = useNavigate();

    return (
        <Box >
            <Box sx={{ position: 'relative',padding:"40px 0px",margin:{xs:"20px 0px",lg:"0px"} }}>
                <Slider {...settings}>
                    {testimonials?.map((testimonial, index) => (
                        <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{
                                margin: '20px',
                                padding: '20px',
                                borderRadius: '10px',
                                maxWidth: '400px',
                            }}>
                                <Box sx={{}}>
                                    <img src={testimonial.img} width={"100%"} alt="" />
                                </Box>
                                <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold', pt: 3 }}>
                                    {testimonial.name}
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#777', py: 3 }}>
                                    {testimonial.quote}
                                </Typography>
                                <Link to={testimonial.path} variant="contained" style={{padding:"10px 5px",textDecoration:"none"}}  className='WebButton'>View Product</Link>
                            </Box>
                        </Box>
                    ))}
                </Slider>
            </Box>
        </Box>
    );
};

export default ProductSlider;
