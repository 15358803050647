import { Box, Button, Grid, List, ListItem, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useRef } from 'react';
import AboutImage from '../../Images/About.png'
import '../../Css/Style.css'
import TimeSet from '../../Images/icons/timesetting.png'
import FooterPage from '../../Components/Footer';
import { Accordion, Container } from 'react-bootstrap';
import AboutCarousel from '../../Images/Carousel/Aboutcarousel.png'
import CheckCircleIcon from '../../Images/icons/check-circle-broken.png'
import CustomizedAccordions from '../../Components/Accordion';
import TestimonialSlider from '../../Components/ModelCardSlider';
import NavigationBar from '../NavigationBar';
import IconService from '../../Images/icons/iconservice.png'
import IconSettings from '../../Images/icons/Group 282(1).png'
import IncreaseIcon from '../../Images/icons/Vector(3).png'
import ReduceIcon from '../../Images/icons/Group(2).png'
import UserExpIcon from '../../Images/icons/Vector(4).png'
import ResponseIcon from '../../Images/icons/Vector(5).png'
import ProductBg from '../../Images/Pages/product-bgimg.png'
import ProductSlider from '../ProductsSlider';
import rapidtoken from '../../Images/rapidtoken.png';
import demo from "../../Images/demo.jpg";
import verfication from "../../Images/stack/verfications.png"
import securtiy from "../../Images/stack/security.png"
import reach from "../../Images/stack/reach.png"
import opportunity from "../../Images/stack/opprounity.png"
import engagement from "../../Images/productsicons/engamenttwo.png"
import sustainblity from "../../Images/stack/sustainblity.png"













export default function RapidTokenPage({handleOpenDialog}) {

    const footerRef = useRef(null);
    const scrollToFooter = () => {
        footerRef.current.scrollIntoView({ behavior: 'smooth' });
      };


      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const cards = [
        { id: "01", title: "Secure and Transparent", description: "Built on blockchain technology ensuring security and transparency in transactions.", color: "#07A117" },
        { id: "02", title: "Customizable Tokens", description: "Easily create and customize your NFTs with unique attributes and metadata.", color: "#07A117" },
        { id: "03", title: "Interoperability", description: "Supports multiple blockchain networks for greater flexibility and reach.", color: "#07A117" },
        { id: "04", title: "User-Friendly Interface", description: "Intuitive and easy-to-use platform for both beginners and experienced users.", color: "#07A117" },
        { id: "05", title: "Smart Contract Integration", description: "Automate processes and enforce rules with integrated smart contracts.", color: "#07A117" },
        { id: "06", title: "Scalable Solutions", description: "Designed to handle high volumes of transactions and users without compromising performance.", color: "#07A117" },
        { id: "07", title: "Royalty Management", description: "Automatic royalty distribution to creators on secondary sales.", color: "#07A117" },
        { id: "08", title: "Marketplace Integration", description: "Seamless integration with popular NFT marketplaces for broader exposure.", color: "#07A117" },
        { id: "09", title: "Sustainability Focus", description: "Designed with eco-friendly protocols to minimize the environmental impact of NFT creation and transactions.", color: "#07A117" }
    ];

    return (
        <Box>
            <Box sx={{ position: 'relative',height:{xs:"100vh",md:"auto"} }}>
                <NavigationBar scrollToFooter={scrollToFooter}/>
                <img src={rapidtoken} width="100%" alt="slider1"  style={{height:"100vh",objectFit:"cover"}}/>
                <Box>
                    <Typography
                        variant="h2"
                        component="div"
                        sx={{
                            color: "#fff",
                            fontWeight: "700",
                            position: "absolute",
                            top: "55%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: '75%',
                            '@media (max-width: 720px)': {
                                fontSize: '1rem',
                                marginTop: "10px"
                            },
                            '@media (min-width: 601px) and (max-width: 960px)': {
                                fontSize: '2.5rem',
                            },
                            '@media (min-width: 961px)': {
                                fontSize: '2.5rem',
                            },
                        }}
                    >
                        Innovative NFT solution designed to revolutionize digital asset management and ownership
                        <Box sx={{
                            '@media (max-width: 720px)': {
                              
                            },
                        }}>
                            <Box sx={{ display: "flex" }}>
                                <Box sx={{
                                    fontSize: "1rem", width: "45%",
                                    mt:1,
                                    '@media (max-width: 720px)': {
                                        width: "100%",
                                        fontSize: ".7rem",

                                    },
                                }}>
                                    <Typography>
                                    RapidToken is an innovative NFT solution designed to revolutionize digital asset management and ownership. With advanced features and seamless integration, RapidToken offers unparalleled benefits for both creators and collectors.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Button className='WebButton' variant="contained" onClick={handleOpenDialog}>Request to Demo</Button>
                            </Box>
                        </Box>
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: "#1C1D22" }}>
                <Grid container spacing={3} paddingTop={"5%"} sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item lg={7} md={7} sm={12} xs={12} >
                        <Box sx={{ paddingLeft:{xs:"2%",md:"13%"}, }}>
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{
                                    color: "#fff",
                                    fontWeight: "400",
                                    textAlign: "center",
                                    px: 4,
                                    fontSize:{xs:"14px",md:"22px"}
                                }}
                            >
                                RapidQube Digital is a disruptive, results-driven, next-gen IT services provider solving the modern digital challenges and
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box p={4}>
            <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center",gap:5 }}>
                {cards.map((card, index) => {
                    const isEvenColumn = (index % 2) === 0;
                    const textColor = isEvenColumn ? "black" : "white";
                    const backgroundColor = isEvenColumn ? "#fff" : "#000";

                    return (
                        <Grid key={index} className="GridCardHeight" item lg={3.5} md={3.5} sm={5.5} xs={12}>
                            <Box
                                className={`GridCard${index % 2 === 0 ? '2' : '3'}`}
                                p={2}
                                sx={{
                                    width: isMobile ? '400px' : 'auto',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap:2,
                                    backgroundColor: backgroundColor
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                        fontWeight: "700",
                                        color: card.color,
                                    
                                       
                                    }}
                                >
                                    {card.id}
                                </Typography>
                                <Typography
                                     
                                    component="div"
                                    sx={{
                                        fontWeight: "bold",
                                          fontsize:"18px",
                                        color: textColor,
                                       
                                    }}
                                >
                                    {card.title}
                                </Typography>
                                <Typography
                                    component="div"
                                    sx={{
                                
                                        lineHeight: "1.5rem",
                                        pb: 1,
                                        color: textColor,
                                      
                                    }}
                                >
                                    {card.description}
                                </Typography>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
                <Box sx={{ backgroundColor: "#2C2D2F", mt: 4, padding: "3%" }}>
                <Container>
                        <Typography
                            variant="h3"
                            component="div"
                            sx={{
                                color: "#07A117",
                                fontWeight: "700",
                                fontSize:{xs:"21px",lg:"32px"}
                            }}
                        >
                          Why RapidToken Stands Out
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Grid container spacing={3} >
                                    <Grid item lg={8} md={5} sm={12} xs={12} >
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                color: "#fff",
                                                fontWeight: "400",
                                                marginTop:"10px",
                                                fontSize:{xs:"14px",md:"18px"}
                                            }}
                                        >
                                           Choose RapidToken for its cutting-edge NFT solutions, offering secure asset management, seamless integration, and innovative opportunities for creators and collectors.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{m:0,  width:{xs:'100%',lg:'auto'},display: "flex", justifyContent: "center", mt: 3, padding: { xs: "20px", lg: "0px" } }}>
            {[{
                icon: verfication,
                title: "Ownership Verification",
                description: "Easily verify digital asset ownership and authenticity, significantly reducing fraud and counterfeiting risks in transactions."
            }, {
                icon: securtiy,
                title: "Enhanced Security",
                description: "Immutable records and tamper-proof transactions guarantee the safety and integrity of your digital assets."
            }, {
                icon: reach,
                title: "Global Reach",
                description: "Access a global marketplace to broaden your reach and maximize the potential audience for your NFTs."
            }, {
                icon: engagement,
                title: "Community Engagement",
                description: "Build and engage a community of collectors and fans, fostering loyalty and driving brand growth."
            }, {
                icon: opportunity,
                title: "Monetization Opportunities",
                description: " New revenue streams for creators through primary sales, royalties, and secondary market transactions."
            }, {
                icon: sustainblity,
                title: "Sustainability Focus",
                description: "Designed with eco-friendly protocols to minimize the environmental impact of NFT creation and transactions."
            }].map((card, index) => (
                <Grid key={index} className="GridCard4" item lg={3.5} md={3.5} sm={5} xs={12} mt={3} mx={{ xs: 0, sm: 3 }} sx={{border:"1px solid white"}}>
                    <Box className="" px={2} py={5} my={3}>
                        <img src={card.icon} width={80} alt={`${card.title.toLowerCase().replace(/\s+/g, '-')}`} />
                        <Typography
                            
                            component="div"
                            sx={{
                                fontWeight: "700",
                                mt: 3
                            }}
                        >
                            {card.title}
                        </Typography>
                        <Typography
                            variant=""
                            component="div"
                            sx={{
                                mt: 2,
                                lineHeight: "1.5rem",
                                pb: 3
                            }}
                        >
                            {card.description}
                        </Typography>
                    </Box>
                </Grid>
            ))}
        </Grid>
                    </Container>
                </Box>
                <Box
          sx={{
            position: "relative",
            width: "100%",
            height: {
              xs: "400px", // Mobile view
              sm: "400px", // Other views
            },
            overflow: "hidden", // Ensure the image doesn't overflow the box
          }}
        >
          <img
            src={demo}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensure the image covers the box area
            }}
            alt="product bg"
          />
          <Button
            sx={{
              position: "absolute",
              zIndex: 999,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            variant="contained"
            className="WebButton"
            onClick={() => handleOpenDialog()}
          >
            Ask for Demo
          </Button>
        </Box>
                <Box padding={"5%"}>
                    <ProductSlider />
                </Box>
            </Box>

            <FooterPage footerRef={footerRef}/>
        </Box>
    )
}