import React, { useEffect } from 'react';
import HomePage from './Pages/HomePage';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import NavigationBar from './Components/NavigationBar';
import AboutPage from './Pages/AboutUs';
import ServicesPage from './Pages/Services';
import TalkifyPage from './Components/ProductsComps/Talkify';
import RapidVersePage from './Components/ProductsComps/RapidVerse';
import RapidTokenPage from './Components/ProductsComps/RapidToken';
import RSuitePage from './Components/ProductsComps/RSuite';
import QubespherePage from './Components/ProductsComps/Qubesphere';
import RapidEHRPage from './Components/ProductsComps/RapidEHR';
import RMartPage from './Components/ProductsComps/RMart';
import EFanEngPage from './Components/ProductsComps/EFanEng';
import RapidProperty from './Components/ProductsComps/RapidProperty';
import DoctPage from './Components/ProductsComps/Doct';
import CloudInfPage from './Components/ServiceComps/CloudInf';
import ApplicationPage from './Components/ServiceComps/Apllication';
import ProductPage from './Components/ServiceComps/Product';
import CutomerExpPage from './Components/ServiceComps/Customer';
import DataIntelligencePage from './Components/ServiceComps/DataIntel';
import AutomaitionPage from './Components/ServiceComps/Automation';
import SustainabilityPage from './Components/ServiceComps/Sustainability';
import AdvisoryPage from './Components/ServiceComps/Advisory';
import FooterPage from './Components/Footer';
import SliderComponent from './Pages/Slider';
import data from "./Pages/data"
import { useState } from 'react';
import { Dialog, DialogContent, Typography, IconButton, Grid, FormControlLabel, Checkbox, Button,TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ScrollToTop from './Components/ServiceComps/ScrollToTop';
//import CustomTextField from './CustomTextField'; // Adjust import according to your project structure
import axios from 'axios';

export default function RouterPage() {

    const [openDialog, setOpenDialog] = useState(false);

    const [defaultEl,setDefault] = useState("");

    const location = useLocation()

    const CustomTextField = styled(TextField)(({ theme }) => ({
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#777777',
          },
          '&:hover fieldset': {
            borderColor: '#777777',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#777777',
          },
        },
        '& .MuiInputLabel-root': {
          color: '#777777',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#777777',
        },
      }));

    const handleOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };


  


    const [formValues, setFormValues] = useState({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyName: '',
      product: defaultEl,
      message: '',
      consent: false,
    });

    const [productRequired, setProductRequired] = useState(false);


   

    useEffect(() => {
      const lastSegment = location.pathname.split('/').filter(segment => segment !== '').pop();
      const currentPage = lastSegment ? lastSegment.replace(/([a-z])([A-Z])/g, '$1 $2') : '';
      const capitalizedText = capitalizeWords(currentPage);
      
      const productFieldsNotRequired = [
        'services', 'cloudinfrastructure', 'applicationmanagement',
        'productdevelopment', 'customerexperience', 'dataintelligence',
        'automations', 'sustainability', 'advisoryconsulting'
      ];
    
      // Check if the currentPage matches any in the array
      if (!productFieldsNotRequired.includes(lastSegment?.toLowerCase())) {
        // Set defaultText as the capitalized currentPage
        setDefault(capitalizedText);
        // Mark the product field as required if needed
        setProductRequired(true); // Assuming you have a state to control this
      } else {
        // If it matches, set the product field as not required
        setProductRequired(false); // Assuming you have a state to control this
      }
    }, [location]);

   
    useEffect(() => {
      // Update the product field in formValues when defaultEl changes
      setFormValues(prevValues => ({
        ...prevValues,
        product: defaultEl
      }));
    }, [defaultEl]);

    const capitalizeWords = (text) => {
      return text
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };
  
    // Capitalize the first letter of the string function (optional)
    // const capitalizeFirstLetter = (text) => {
    //   return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    // };

    const resetForm = () => {
      setFormValues({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        companyName: '',
       
        message: '',
        consent: false,
      });
    };
  
   
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
    };
  
    const handleCheckboxChange = (e) => {
      setFormValues({ ...formValues, consent: e.target.checked });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
    
      // Create a new FormData object
      const formData = new FormData();
      
      // Append form values to FormData object
      formData.append('firstName', formValues.firstName);
      formData.append('lastName', formValues.lastName);
      formData.append('email', formValues.email);
      formData.append('mobile', formValues.phone);
      formData.append('companyName', formValues.companyName);
      formData.append('product', formValues.product);
      formData.append('messageTxt', formValues.message);
      formData.append('consent', formValues.consent);
    
      try {
        // Make a POST request to your endpoint with FormData
        const response = await axios.post('http://localhost/bahrain/booking_details', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    
        // Handle the response if needed
        console.log('Response:', response.data);
    
        // Reset form or close dialog after submission
        alert(response.data.message)
        handleCloseDialog();
        resetForm()
      } catch (error) {
        // Handle errors if the request fails
        console.error('Error submitting the form:', error);
      }
    };

    return (
        <Box>
           <ScrollToTop /> {/* Add the component here */}
        <Routes>
       
            <Route path="/" element={<HomePage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/about" element={<AboutPage />}></Route>
            <Route path="/services" element={<ServicesPage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/talkify" element={<TalkifyPage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/rapidverse" element={<RapidVersePage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/rapidtoken" element={<RapidTokenPage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/rsuite" element={<RSuitePage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/qubesphere" element={<QubespherePage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/rapidehr" element={<RapidEHRPage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/rmart" element={<RMartPage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/efaneng" element={<EFanEngPage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/rapidproperty" element={<RapidProperty handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/doct" element={<DoctPage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/cloudinfrastructure" element={<CloudInfPage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/applicationmanagement" element={<ApplicationPage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/productdevelopment" element={<ProductPage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/customerexperience" element={<CutomerExpPage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/dataintelligence" element={<DataIntelligencePage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/automations" element={<AutomaitionPage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/sustainability" element={<SustainabilityPage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/advisoryconsulting" element={<AdvisoryPage handleOpenDialog={handleOpenDialog}/>}></Route>
            <Route path="/footer" element={<FooterPage handleOpenDialog={handleOpenDialog}/>}/>
           
        </Routes>
        <div>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogContent className="custom-dialog-content">
        <Typography variant="h5" component="div" sx={{ color: '#07A117', pl: 2 }}>
          Request for Demo
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 0,
            top: 18,
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item lg={6} md={6} sm={12} xs={12} p={2} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="First Name"
                name="firstName"
                size="small"
                variant="outlined"
                sx={{ width: '100%' }}
                value={formValues.firstName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} p={2} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="Last Name"
                name="lastName"
                size="small"
                variant="outlined"
                sx={{ width: '100%' }}
                value={formValues.lastName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} p={2} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="Email Address"
                name="email"
                size="small"
                variant="outlined"
                sx={{ width: '100%' }}
                value={formValues.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} p={2} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="Phone Number"
                name="phone"
                size="small"
                variant="outlined"
                sx={{ width: '100%' }}
                value={formValues.phone}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={productRequired  ? 6 : 12} md={6} sm={12} xs={12} p={2} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="Company Name"
                name="companyName"
                size="small"
                variant="outlined"
                sx={{ width: '100%' }}
                value={formValues.companyName}
                onChange={handleInputChange}
              />
            </Grid>
            {
              productRequired  &&   <Grid item lg={6} md={6} sm={12} xs={12} p={2} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="Product"
                name="product"
                size="small"
                variant="outlined"
                sx={{ width: '100%' }}
                value={formValues.product}
                onChange={handleInputChange}
              />
            </Grid>
            }
          
            <Grid item lg={12} md={12} sm={12} xs={12} p={2} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="Message"
                name="message"
                size="small"
                variant="outlined"
                multiline
                rows={3}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    height: 'auto',
                  },
                }}
                value={formValues.message}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', pl: 3 }}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: 'green',
                    '&.Mui-checked': {
                      color: 'green',
                    }
                  }}
                  checked={formValues.consent}
                  onChange={handleCheckboxChange}
                />
              }
              label={
                <Typography component="div" sx={{ color: '#777777', paddingTop: '10px', fontSize: "14px" }}>
                  By submitting your details, you consent to receive communications from our organization regarding your demo request, including updates, promotional offers, and other relevant information.
                </Typography>
              }
              sx={{ alignItems: 'flex-start' }}
            />
          </Box>
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item lg={5} md={5} sm={12} xs={12} p={1}>
              <Button
                variant='outlined'
                sx={{ width: "100%" }}
                className='CancelButton'
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12} p={1}>
              <Button
                variant='contained'
                sx={{ width: "100%" }}
                className='SubmitButton'
                type="submit"
              >
                Request for Demo
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
    </div>
        </Box>
    )
}