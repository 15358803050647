import { Box, Button, Grid, List, ListItem, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import AboutImage from '../../Images/About.png'
import '../../Css/Style.css'
import TimeSet from '../../Images/icons/timesetting.png'
import FooterPage from '../../Components/Footer';
import { Accordion, Container } from 'react-bootstrap';
import AboutCarousel from '../../Images/Carousel/Aboutcarousel.png'
import CheckCircleIcon from '../../Images/icons/check-circle-broken.png'
import CustomizedAccordions from '../../Components/Accordion';
import TestimonialSlider from '../../Components/ModelCardSlider';
import NavigationBar from '../NavigationBar';
import IconService from '../../Images/icons/iconservice.png'
import IconSettings from '../../Images/icons/Group 282(1).png'
import IncreaseIcon from '../../Images/icons/Vector(3).png'
import ReduceIcon from '../../Images/icons/Group(2).png'
import UserExpIcon from '../../Images/icons/Vector(4).png'
import ResponseIcon from '../../Images/icons/Vector(5).png'
import ProductBg from '../../Images/Pages/product-bgimg.png'
import ProductSlider from '../ProductsSlider';
import { useRef } from 'react';
import qbsqure from '../../Images/qubsqare.png';
import demo from '../../Images/demo.jpg';
import efficieny from '../../Images/stack/efficiency.png'
import communication from '../../Images/stack/communication.png'
import data from '../../Images/stack/data.png'
import transparency from '../../Images/stack/transparency.png'
import optimazation from '../../Images/stack/optimazaation.png'
import custmazie from '../../Images/stack/solutions.png'












export default function QubespherePage({handleOpenDialog}) {


    const footerRef = useRef(null);
    const scrollToFooter = () => {
        footerRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const cards = [
        { id: "01", title: "Student Information Management", description: "Maintain comprehensive student records, including personal details, academic history, attendance, and behavior logs." },
        { id: "02", title: "Timetable Management", description: "Easily create and manage class schedules, ensuring optimal use of resources and minimizing conflicts." },
        { id: "03", title: "Assessment and Reporting", description: "Track student performance with customizable assessment tools and generate detailed reports for parents and administrators." },
        { id: "04", title: "Attendance Tracking", description: "Monitor student attendance with automated tracking and reporting features, including notifications for absences." },
        { id: "05", title: "Communication Tools", description: "Facilitate seamless communication between teachers, students, and parents through integrated messaging, emails, and notifications." },
        { id: "06", title: "Resource Management", description: "Efficiently manage school resources such as classrooms, libraries, and laboratory equipment, ensuring availability and proper usage." },
        { id: "07", title: "Parent Portal", description: "Provide parents with access to their child's academic progress, attendance records, and school announcements through a dedicated portal." },
        { id: "08", title: "Homework and Assignments", description: "Assign, track, and grade homework and assignments, with tools for both teachers and students to manage their workloads." },
        { id: "09", title: "Financial Management", description: "Handle school finances, including fee collection, expense tracking, and budget management with built-in financial tools." }
    ];


    return (
        <Box>
            <Box sx={{ position: 'relative',height:{xs:"100vh",md:"auto"} }}>
                <NavigationBar scrollToFooter={scrollToFooter}/>
                <img src={qbsqure} width="100%" alt="slider1" style={{height:"100vh",objectFit:"cover"}}/>
                <Box>
                    <Typography
                        variant="h2"
                        component="div"
                        sx={{
                            color: "#fff",
                            fontWeight: "700",
                            position: "absolute",
                            top: "55%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: '75%',
                            '@media (max-width: 720px)': {
                                fontSize: '1rem',
                                marginTop: "10px"
                            },
                            '@media (min-width: 601px) and (max-width: 960px)': {
                                fontSize: '2.5rem',
                            },
                            '@media (min-width: 961px)': {
                                fontSize: '2.5rem',
                            },
                        }}
                    >
                       QubeSphere School<br/> Management Solution
                        <Box sx={{
                            '@media (max-width: 720px)': {
                               
                            },
                        }}>
                            <Box sx={{ display: "flex" }}>
                                <Box sx={{
                                    fontSize: "1rem", width: "45%",mt:1,
                                    '@media (max-width: 720px)': {
                                        width: "100%",
                                        fontSize: ".7rem",

                                    },
                                }}>
                                    <Typography>
                                    Empower your educational institution with QubeSphere, a comprehensive school management solution designed to streamline administrative tasks, enhance communication, and improve overall efficiency.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Button className='WebButton' variant="contained" onClick={handleOpenDialog}>Request to Demo</Button>
                            </Box>
                        </Box>
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: "#1C1D22" }}>
                <Grid container spacing={3} paddingTop={"5%"} sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item lg={7} md={7} sm={12} xs={12} >
                        <Box sx={{ paddingLeft: {xs:"2%",lg:"13%"}, }}>
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{
                                    color: "#fff",
                                    fontWeight: "700",
                                    textAlign: "center",
                                    px: 4,
                                    fontSize:{xs:"14px",md:"24px"}
                                }}
                            >
                                RapidQube Digital is a disruptive, results-driven, next-gen IT services provider solving the modern digital challenges and
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box p={4}>
            <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center",gap:5 }}>
                {cards.map((card, index) => {
                    const textColor = index % 3 === 1 ? "white" : "black";
                    const backgroundColor = index % 3 === 1 ? "transparent" : "#fff";
                    const boxClass = index % 2 === 0 ? 'GridCard2' : 'GridCard3';

                    return (
                        <Grid key={index} className="GridCardHeight" item lg={3.5} md={3.5} sm={5.5} xs={12}>
                            <Box
                                className={boxClass}
                                p={2}
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap:2,
                                    height:"230px",
                                    backgroundColor: backgroundColor,
                                    color: textColor,
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    width: isMobile ? '400px' : 'auto',
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                        fontWeight: "700",
                                        color: "#07A117",
                            
                                       
                                    }}
                                >
                                    {card.id}
                                </Typography>
                                <Typography
                        
                                    component="div"
                                    sx={{
                                        fontWeight: "bold",
                                        
                                         fontSize:"18px"
                                    }}
                                >
                                    {card.title}
                                </Typography>
                                <Typography
                                    component="div"
                                    sx={{
                                    
                                        lineHeight: "1.5rem",
                                        pb: 1,
                                       
                                    }}
                                >
                                    {card.description}
                                </Typography>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
                <Box sx={{ backgroundColor: "#2C2D2F", mt: 4, padding: "3%" }}>
                    <Container>
                        <Typography
                            variant="h3"
                            component="div"
                            sx={{
                                color: "#07A117",
                                fontWeight: "700",
                                fontSize:{xs:"21px",lg:"32px"}
                            }}
                        >
                         Why QubeSphere Stands Out
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Grid container spacing={3} sx={{ paddingTop: "2%" }}>
                                    <Grid item lg={8} md={5} sm={12} xs={12} >
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                color: "#fff",
                                                fontWeight: "400",
                                                fontSize:{xs:"14px",lg:"18px"}
                                            }}
                                        >
                                         Choose QubeSphere for its comprehensive school management solution that streamlines administration, enhances communication, and boosts overall efficiency with user-friendly technology.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{m:0,  width:{xs:'100%',lg:'auto'},display: "flex", justifyContent: "center", mt: 3,gap:3, padding: { xs: "20px", lg: "0px" } }}>
  {[
    {
      src: efficieny,
      title: "Enhanced Efficiency",
      description: "Streamline administrative tasks, reducing the time and effort required for managing school operations."
    },
    {
      src: communication,
      title: "Improved Communication",
      description: "Faster better communication among students, parents, and staff, enhancing collaboration and engagement."
    },
    {
      src: data,
      title: "Data-Driven Decision Making",
      description: "Utilize detailed reports and analytics to make informed decisions and improve educational outcomes."
    },
    {
      src: transparency,
      title: "Increased Transparency",
      description: "Provide parents with real-time access to their child's academic progress and school activities, fostering trust and involvement."
    },
    {
      src: optimazation,
      title: "Resource Optimization",
      description: "Ensure optimal use of school resources, minimizing waste and maximizing availability for educational activities."
    },
    {
      src: custmazie,
      title: "Customizable Solutions",
      description: "Customize the platform to meet your school’s specific needs, aligning it with your educational goals and requirements."
    }
  ].map((card, index) => (
    <Grid key={index} className="GridCard4" item lg={3.5} md={3.5} sm={4.5} xs={12} sx={{ mt: 3,border:"1px solid white" }}>
      <Box px={2} py={5} my={3}>
        <img src={card.src} width={80} alt="icon service" />
        <Typography  component="div" sx={{ fontWeight: "700", mt: 3 }}>
          {card.title}
        </Typography>
        <Typography component="div" sx={{ mt: 2, lineHeight: "1.5rem", pb: 3 }}>
          {card.description}
        </Typography>
      </Box>
    </Grid>
  ))}
</Grid>

                    </Container>
                </Box>
                <Box
          sx={{
            position: "relative",
            width: "100%",
            height: {
              xs: "400px", // Mobile view
              sm: "400px", // Other views
            },
            overflow: "hidden", // Ensure the image doesn't overflow the box
          }}
        >
          <img
            src={demo}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensure the image covers the box area
            }}
            alt="product bg"
          />
          <Button
            sx={{
              position: "absolute",
              zIndex: 999,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            variant="contained"
            className="WebButton"
            onClick={() => handleOpenDialog()}
          >
            Ask for Demo
          </Button>
        </Box>
                <Box padding={"5%"}>
                    <ProductSlider />
                </Box>
            </Box>

            <FooterPage footerRef={footerRef}/>
        </Box>
    )
}