import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import NavigationBar from "../Components/NavigationBar";
import AboutImage from "../Images/Carousel/Aboutcarousel.png";
import OurMission from "../Images/Pages/Rectangle 4454(1).png";
import OurVision from "../Images/Pages/ourvision.png";
import "../Css/Style.css";
import CoFounder from "../Images/Pages/co-founder.png";
import VicePresident from "../Images/Pages/vice-president.png";
import VicePresident1 from "../Images/Pages/vice-president1.png";
import { Link } from "react-router-dom";
import FooterPage from "../Components/Footer";
import YoutubeIcon from "../Images/icons/logos_youtube-icon.png";
import InIcon from "../Images/icons/Group(1).png";
import FBIcon from "../Images/icons/logos_facebook.png";
import InstaIcon from "../Images/icons/skill-icons_instagram.png";
import TwitterIcon from "../Images/icons/pajamas_twitter.png";
import { styled } from "@mui/system";
import { useRef } from "react";
import demo from "../Images/demo.jpg"
import axios from "axios";

export default function AboutPage() {


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
  
    setLoading(true);
    setError(null);
    setSuccess(null);
  
    // Create a new FormData object
    const formData = new FormData();
    formData.append('userName', name);
    formData.append('workEmail', email);
    formData.append('messageTxt', message);
  
    try {
      // Send the form data using Axios
      const response = await axios.post('http://localhost/bahrain/contact_us', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
      });
  
      setSuccess('Message sent successfully!');
      setName('');
      setEmail('');
      setMessage('');
      alert(response.data.message)
    } catch (error) {
      setError('Failed to send message. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
        color: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
        color: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
        color: "#fff",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#fff",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#fff",
    },
  }));

  const footerRef = useRef(null);

  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Box>
    <Box
  sx={{
    position: "relative",
    textAlign: "center",
    height: { xs: "70vh", md: "70vh" },
    overflow: "hidden", // Ensure that any overflow is hidden
  }}
>
  <NavigationBar scrollToFooter={scrollToFooter} />

  <img
    src={demo}
    width="100%"
    style={{ height: "100vh", objectFit: "cover" }}
    alt="slider1"
  />

  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "70vh",
      background: "linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.8))",
      zIndex: 1, // Ensure the gradient is above the image but below the text
    }}
  />

  <Typography
    variant="h2"
    component="div"
    sx={{
      color: "#fff",
      fontWeight: "700",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      zIndex: 2, // Ensure the text is above the gradient
      "@media (max-width: 600px)": {
        fontSize: "1.5rem",
      },
      "@media (min-width: 601px) and (max-width: 960px)": {
        fontSize: "2.5rem",
      },
      "@media (min-width: 961px)": {
        fontSize: "3.5rem",
      },
    }}
  >
    About Us
  </Typography>
</Box>

      <Box sx={{ backgroundColor: "#1C1D22" }}>
        <Grid container spacing={3} paddingTop={"5%"}>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{ display: { xs: "none", lg: "block" } }}
          >
            <Box sx={{ paddingLeft: "13%" }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  color: "#07A117",
                  fontWeight: "700",
                }}
              >
                Who We’re
              </Typography>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  color: "#fff",
                  fontWeight: "700",
                }}
              >
                We’re
              </Typography>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  color: "#07A117",
                  fontWeight: "700",
                }}
              >
                Rapidqube
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <Box sx={{ paddingLeft: {xs:"5%",sm:"3%",md:"8%"} }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  color: "#07A117",
                  fontWeight: "600",
                  fontSize: "28px",
                }}
              >
                Who We’re
              </Typography>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  color: "#fff",
                  fontWeight: "600",
                  fontSize: "28px",
                }}
              >
                We’re Rapidqube
              </Typography>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  color: "#07A117",
                  fontWeight: "700",
                }}
              ></Typography>
            </Box>
          </Grid>
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <Box px={3} mb={5} pb={4}>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  color: "#fff",
                  fontSize: { xs: "14px", md: "18px" },
                }}
              >
                RapidQube Digital Solutions Pvt. Ltd. is a technology powerhouse
                committed to helping businesses unlock new opportunities through
                state-of-the-art technological advancements. Our team of
                experienced professionals is dedicated to providing bespoke
                solutions that enhance efficiency, security, and operational
                excellence.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <img src={OurMission} width={"100%"} alt="our mission" />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box px={3}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  color: "#07A117",
                  fontWeight: "700",
                  pt: 1,
                }}
              >
                <span style={{ color: "#fff" }}>Our</span> Mission
              </Typography>
              <Typography
                variant=""
                component="div"
                sx={{
                  color: "#fff",
                  marginTop: "20px",
                  lineHeight: "1.9rem",
                }}
              >
                Our Mission is to help our clients achieve and exceed their
                digital technology adaptation goals and make digital technology
                become a pillar of their business growth and profitability.
              </Typography>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  color: "#07A117",
                  fontWeight: "700",
                  marginTop: "30px",
                }}
              >
                <span style={{ color: "#fff" }}>Our</span> Vision
              </Typography>
              <Typography
                variant=""
                component="div"
                sx={{
                  color: "#fff",
                  marginTop: "20px",
                  lineHeight: "1.9rem",
                }}
              >
                Our Vision is to become the incubator for digital technology,
                innovating, disrupting, and delivering successful future-ready
                business solutions through the development and deployment of
                futuristic technologies.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{ display: "flex", alignItems: "end" }}
          >
            <img src={OurVision} width={"100%"} alt="our mission" />
          </Grid>
        </Grid>
        <Box p={5}>
          <Grid container spacing={6} paddingLeft={"3%"} paddingRight={"3%"}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid
                container
                spacing={3}
                sx={{ display: "flex", justifyContent: "center", pt: 5 }}
              >
                <Grid item lg={5} md={5} sm={12} xs={12}>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      textAlign: "center",
                      fontSize: { xs: "16px", lg: "32px" },
                      display:{xs:"none",lg:"block"}
                    }}
                  >
                    "Utilizing transformative technologies for sustainable
                    solutions"
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={12}
              xs={12}
              sx={{ marginBottom: "3%" }}
            >
              <Box className="GridCard" p={2}>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{
                    color: "#07A117",
                    fontWeight: "700",
                  }}
                >
                  2016
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: "#07A117",
                    fontWeight: "700",
                    mt: 3,
                  }}
                >
                  Concept Research
                </Typography>
                <List
                  className="GridCardText"
                  sx={{ listStyleType: "disc", pl: 4, color: "#fff" }}
                >
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="Formed In Mumbai, India" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="Started Operations with 3 Employees" />
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid className="StyleDown" item lg={4} md={4} sm={12} xs={12}>
              <Box className="GridCard " p={2}>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{
                    color: "#07A117",
                    fontWeight: "700",
                  }}
                >
                  2018
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: "#07A117",
                    fontWeight: "700",
                    mt: 3,
                  }}
                >
                  Engagement
                </Typography>
                <List
                  className="GridCardText"
                  sx={{ listStyleType: "disc", pl: 4, color: "#fff" }}
                >
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="Established Development Center at Chennai" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="1st Blockchain Project for Insurance Sector" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="1st Overseas Blockchain Project in UAE" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="1st POC for Maharashtra Government" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="6POC for Legal, Health Care, Public Sector" />
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={12}
              xs={12}
              sx={{
                marginBottom: "3%",
                marginTop:{sm:"60px",lg:"0px"},
                "@media (max-width: 600px)": {
                  mt: 3,
                },
              }}
            >
              <Box className="GridCard" p={2}>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{
                    color: "#07A117",
                    fontWeight: "700",
                  }}
                >
                  2019
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: "#07A117",
                    fontWeight: "700",
                    mt: {xs:3,md:10,lg:3},
                  }}
                >
                  Adoption
                </Typography>
                <List
                  className="GridCardText"
                  sx={{ listStyleType: "disc", pl: 4, color: "#fff" }}
                >
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="Got ISO 2015 Certified" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="Launched AI and Blockchain Consultancy Services & Won 1st AI Project" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="Extended Our Footstep in USA Market" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="1st Blockchain Project for MH Government" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="Featured in Insight Success Magazine" />
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box className="GridCard" p={2}>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{
                    color: "#07A117",
                    fontWeight: "700",
                  }}
                >
                  2017
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: "#07A117",
                    fontWeight: "700",
                    mt: 3,
                  }}
                >
                  Commitment
                </Typography>
                <List
                  className="GridCardText"
                  sx={{ listStyleType: "disc", pl: 4, color: "#fff" }}
                >
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="Won the First Blockchain Project for Healthcare" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="Won Hackathons" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="Active Participant in Various Webinars & Events as Speaker Contestant " />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="1st Corporate Blockchain Training" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="1st Project for Public Sector" />
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={12}
              xs={12}
              sx={{ display: "flex", alignItems: "end" }}
            >
              <Typography
                variant="h4"
                component="div"
                sx={{
                  color: "#fff",
                  fontWeight: "700",
                  textAlign: "center",
                  fontSize: { xs: "16px", lg: "32px" },
                  display:{xs:"none",lg:"block"}
                }}
              >
               "Leveraging innovation for sustainable success."
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box className="GridCard" p={2}>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{
                    color: "#07A117",
                    fontWeight: "700",
                  }}
                >
                  2020
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: "#07A117",
                    fontWeight: "700",
                    mt: 3,
                  }}
                >
                  Scalling
                </Typography>
                <List
                  className="GridCardText"
                  sx={{ listStyleType: "disc", pl: 4, color: "#fff" }}
                >
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="Regsitered Company in USA" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="1st Project for one of the Biggest Manufacturing Company at Bahrain" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="Renewal of ISO 2015 Certificate" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="Multiple Projects for Digital Transformations" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0, py: 0, my: 0 }}>
                    <ListItemText primary="Expanding Reach to 4 Countries & 5 States" />
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box padding={"20px"}>
          <Typography
            variant="h3"
            component="div"
            sx={{
              color: "#07A117",
              fontWeight: "700",
              textAlign: "center",
              mt: 3,
              fontSize: { xs: "16px",sm:"22px", lg: "32px" },
            }}
          >
            Why Choose Us
          </Typography>
          <Typography
           
            component="div"
            sx={{
              color: "#fff",
              fontWeight: "300",
              textAlign: "center",
              mt: 3,
              fontSize: { xs: "16px", md: "22px" },
            }}
          >
            RapidQube Digital is a disruptive, results-driven, next-gen IT
            services provider solving the modern digital challenges and
          </Typography>
          <Box sx={{p:3}}>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", justifyContent: "center", gap: 5 }}
            >
              {[
                {
                  id: "01",
                  title: "Innovative Solutions",
                  description:
                    "We leverage the latest  technologies to create innovative solutions that  drive growth and efficiency",
                },
                {
                  id: "02",
                  title: "BeSpoke Solutions",
                  description:
                    "We commence with prebuilt  solutions, expedite the development process,  and enable rapid ",
                },
                {
                  id: "03",
                  title: "Experienced Team",
                  description:
                    "Our expert team delivers consistently successful outcomes across diverse industries.",
                },
                {
                  id: "04",
                  title: "Sustainable Solution",
                  description:
                    "We provide dependable open-source solutions that cut IT costs and support community initiatives.",
                },
                {
                  id: "05",
                  title: "Experienced Team",
                  description:
                    "We deliver customized solutions that align with your unique business goals and needs.",
                },
              ].map((item) => (
                <Grid item lg={3.5} md={4} sm={4.5} xs={12} key={item.id}>
                  <Box
                    className="GridCard1"
                    p={2}
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "start",
                      padding:"10px",
                      gap: 2,
                    }}
                  >
                    <Typography
                      
                      component="div"
                      sx={{
                        color: "#07A117",
                        fontWeight: "700",
                        mt: 1,
                        textAlign:"left",
                        fontSize: { xs: "18px", md: "22px" },
                      }}
                    >
                      {item.id}{" "}
                      <span
                        style={{ color: "#fff", margin: "0px 0px 0px 5px" }}
                      >
                        {item.title}
                      </span>
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        color: "#fff",
                        mt: 1,
                        pb: 2,
                      }}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <Box paddingBottom={"5%"}>
          <Typography
            variant="h3"
            component="div"
            sx={{
              color: "#07A117",
              fontWeight: "700",
              textAlign: "center",
              margin: "20px 0px",
              fontSize: { xs: "22px", lg: "32px" },
            }}
          >
            Leadership Team
          </Typography>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid
              container
              spacing={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <Typography
                  paddingLeft={"3%"}
                  paddingRight={"3%"}
                  variant="h6"
                  component="div"
                  sx={{
                    color: "#fff",
                    fontWeight: { xs: "400", md: "400" },
                    textAlign: "center",
                  
                    mb:3,
                    fontSize: { xs: "16px", md: "22px" },
                  }}
                >
                  Our Leadership Team Blends Industry Expertise with Visionary
                  Insight, Driving Innovation and Excellence through Strategic
                  Guidance and Exceptional Results.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Box p={2}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img src={CoFounder} width={"90%"} alt="co-founder" />
                  </Box>
                  <Box className="LeaderStyle" p={2}>
                    <Typography
                      paddingLeft={"3%"}
                      paddingRight={"3%"}
                  
                      component="div"
                      sx={{
                        color: "#fff",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      Raghunath Vaddadi
                    </Typography>
                    <Typography
                      paddingLeft={"3%"}
                      paddingRight={"3%"}
                      variant=""
                      component="div"
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      Co-Founder & CEO
                    </Typography>

                    <Box
                      sx={{ display: "flex", justifyContent: "center", pt: 1 }}
                    >
                      <a href="https://in.linkedin.com/in/raghunadh-vaddadi-55a7105" target="_blank">
                        <img
                          style={{ paddingRight: "10px" }}
                          src={InIcon}
                          width={30}
                          alt="youtube icon"
                        />
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Box p={2}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img src={VicePresident} width={"90%"} alt="co-founder" />
                  </Box>
                  <Box className="LeaderStyle" px={1} py={2}>
                    <Typography
                      
                      component="div"
                      sx={{
                        color: "#fff",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      Muthuprakash Ravindran
                    </Typography>
                    <Typography
                      paddingLeft={"3%"}
                      paddingRight={"3%"}
                      variant=""
                      component="div"
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      VicePresident
                    </Typography>

                    <Box
                      sx={{ display: "flex", justifyContent: "center", pt: 1 }}
                    >
                      <a href="https://www.linkedin.com/in/muthu-prakash-ravindran-pmp-csm-4570854/"  target="_blank">
                        <img
                          style={{ paddingRight: "10px" }}
                          src={InIcon}
                          width={30}
                          alt="youtube icon"
                        />
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Box p={2}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img src={VicePresident1} width={"90%"} alt="co-founder" />
                  </Box>
                  <Box className="LeaderStyle" p={2}>
                    <Typography
                      paddingLeft={"3%"}
                      paddingRight={"3%"}
                  
                      component="div"
                      sx={{
                        color: "#fff",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      Mohanraj Polurbalu
                    </Typography>
                    <Typography
                      paddingLeft={"3%"}
                      paddingRight={"3%"}
                      variant=""
                      component="div"
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      Vice President
                    </Typography>

                    <Box
                      sx={{ display: "flex", justifyContent: "center", pt: 1 }}
                    >
                      <a href="https://www.linkedin.com/in/mohanrajpb/" target="_blank">
                        <img
                          style={{ paddingRight: "10px" }}
                          src={InIcon}
                          width={30}
                          alt="youtube icon"
                        />
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        p={3}
        style={{ backgroundColor: "#ffffff" }}
        ref={footerRef}
        id="footer"
      >
        <Box padding={"3%"}>
          <Grid container spacing={3} paddingTop={"5%"}>
            <Grid
              item
              lg={8.5}
              md={8.5}
              sm={12}
              xs={12}
              p={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      textAlign: "center",
                      color: "#07A117",
                      fontWeight: "700",
                    }}
                  >
                    RapidQube
                  </Typography>
                  <Typography
                    variant=""
                    component="div"
                    sx={{
                      textAlign: "center",
                      color: "#000",
                      margin: "10px 0px",
                    }}
                  >
                    Partner With Us to Unlock Your Business's Full Potential.
                  </Typography>
                  <Box p={3} sx={{ display: "flex", justifyContent: "center" }}>
                            
                            <a href="https://www.youtube.com/channel/UCkwqExwAISY_9yYinPUWYpg" target="_blank" rel="noopener noreferrer">
                              <img style={{ paddingRight: "10px" }} src={YoutubeIcon} width={40} alt="YouTube icon" />
                            </a>
                            <a href="https://www.linkedin.com/company/rapidqube-bahrain/" target="_blank" rel="noopener noreferrer">
                              <img style={{ paddingRight: "10px" }} src={InIcon} width={30} alt="LinkedIn icon" />
                            </a>
                            <a href="https://www.facebook.com/RapidQubeBahrain" target="_blank" rel="noopener noreferrer">
                              <img style={{ paddingRight: "10px" }} src={FBIcon} width={30} alt="Facebook icon" />
                            </a>
                            <a href="https://www.instagram.com/rapidqubebahrain/" target="_blank" rel="noopener noreferrer">
                              <img style={{ paddingRight: "10px" }} src={InstaIcon} width={30} alt="Instagram icon" />
                            </a>
                            <a href="https://x.com/RapidqubeB" target="_blank" rel="noopener noreferrer">
                              <img style={{ paddingRight: "10px" }} src={TwitterIcon} width={30} alt="Twitter icon" />
                            </a>
                          
                                                        </Box>
                </Grid>
               
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Typography
                    variant=""
                    component="div"
                    sx={{ color: "#07A117", fontWeight: "700" }}
                  >
                    Services
                  </Typography>
                  <Box mt={1}>
                    <Link to="/cloudinfrastructure" className="FooterLink">
                      Cloud & Infrastructure
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/dataintelligence" className="FooterLink">
                      Data & Intelligence
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/productdevelopment" className="FooterLink">
                      Product Development
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/customerexperience" className="FooterLink">
                      Customer Experience
                    </Link>
                  </Box>
                </Grid>
                
                <Grid item lg={2} md={2} sm={12} xs={12} pb={3}>
                  <Typography
                    variant=""
                    component="div"
                    sx={{ color: "#07A117", fontWeight: "700" }}
                  >
                    Products
                  </Typography>
                  <Box mt={1}>
                    <Link to="/rsuite" className="FooterLink">
                      R-suite
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/doct" className="FooterLink">
                      docT
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/rapidverse" className="FooterLink">
                      Rapid Verse
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/talkify" className="FooterLink">
                      Talkify
                    </Link>
                  </Box>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12}>
                  <Typography
                    variant=""
                    component="div"
                    sx={{ color: "#07A117", fontWeight: "700" }}
                  >
                    Our Links
                  </Typography>
                  <Box mt={1}>
                    <Link to="/talkify" className="FooterLink">
                      Products
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/services" className="FooterLink">
                      Services
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/about" className="FooterLink">
                      About Us
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3.5} md={3.5} sm={12} xs={12} style={{ backgroundColor: '#07A117', padding: '16px' }}>
      <Typography variant="h6" component="div" style={{ color: '#fff', fontSize: '20px', fontWeight: 700 }}>
        Let's Get Started
      </Typography>
      <form onSubmit={handleSubmit} style={{ marginTop: '16px' }}>
        <TextField
          label="Name"
          size="small"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ width: '100%', marginTop: '8px' }}
          InputProps={{
            style: {
              color: '#fff',
              borderColor: '#fff', // Attempt to set border color here
            },
          }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#fff', // Outline color for TextField
              },
              '&:hover fieldset': {
                borderColor: '#fff', // Outline color when hovered
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff', // Outline color when focused
              },
            },
            '& .MuiInputLabel-root': {
              color: '#fff',
            },
            '& .MuiInputBase-input': {
              color: '#fff',
            },
          }}
        />
        <TextField
          label="Work Email"
          size="small"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ width: '100%', marginTop: '8px' }}
          InputProps={{
            style: { color: '#fff' },
          }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#fff',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
        />
        <TextField
          label="Message"
          size="small"
          variant="outlined"
          multiline
          rows={3}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={{ width: '100%', marginTop: '8px' }}
          InputProps={{
            style: { color: '#fff', height: 'auto' },
          }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#fff',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          style={{
            width: '100%',
            marginTop: '8px',
            backgroundColor: '#112F37',
            color: '#fff',
            boxShadow: 'none',
            textTransform: 'none',
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0e4a52'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#112F37'}
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send'}
        </Button>
        
      </form>
    </Grid>
          </Grid>
          <Box mt={4}>
            <hr />
          </Box>
        </Box>
        <Typography
          variant=""
          component="div"
          sx={{ mb: 2, textAlign: "center", color: "#000", fontSize: "16px" }}
        >
          Copyright © 2024 RapidQube Digital Solutions WLL. All Rights Reserved.{" "}
          <Link> Privacy Policy</Link>
        </Typography>
      </Box>
    </Box>
  );
}
