import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useRef } from "react";
import AboutImage from "../../Images/About.png";
import "../../Css/Style.css";
import TimeSet from "../../Images/icons/timesetting.png";
import FooterPage from "../../Components/Footer";
import { Accordion, Container } from "react-bootstrap";
import AboutCarousel from "../../Images/Carousel/Aboutcarousel.png";
import CheckCircleIcon from "../../Images/icons/check-circle-broken.png";
import CustomizedAccordions from "../../Components/Accordion";
import TestimonialSlider from "../../Components/ModelCardSlider";
import NavigationBar from "../NavigationBar";
import IconService from "../../Images/icons/iconservice.png";
import IconSettings from "../../Images/icons/Group 282(1).png";
import IncreaseIcon from "../../Images/icons/Vector(3).png";
import ReduceIcon from "../../Images/icons/Group(2).png";
import UserExpIcon from "../../Images/icons/Vector(4).png";
import ResponseIcon from "../../Images/icons/Vector(5).png";
import ProductBg from "../../Images/Pages/product-bgimg.png";
import ProductSlider from "../ProductsSlider";
import doct from "../../Images/doct.png";
import demo from "../../Images/demo.jpg";
import productivty from "../../Images/stack/productivity.png"
import enhanced from "../../Images/stack/efficent.png"
import error from "../../Images/stack/error.png"
import complaince from "../../Images/stack/compalaince.png"
import security from "../../Images/stack/securitydoct.png"
import collabration from "../../Images/stack/collabrationdoct.png"















export default function DoctPage({ handleOpenDialog }) {
  const footerRef = useRef(null);
  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const cards = [
    {
      number: "01",
      title: "Seamless Document Sharing",
      description:
        "Effortlessly share documents with internal and external stakeholders, enhancing collaboration and communication.",
    },
    {
      number: "02",
      title: "360-Degree Document View",
      description:
        "Gain a comprehensive view of all document exchanges within and outside the organization.",
    },
    {
      number: "03",
      title: "Automated Workflow",
      description:
        "Automate the entire process of document creation, preparation, and routing, reducing manual effort and errors.",
    },
    {
      number: "04",
      title: "Centralized Storage",
      description:
        "Store all documents in a centralized repository with version control to prevent data duplication and ensure access to the latest information.",
    },
    {
      number: "05",
      title: "Automated Document Routing",
      description:
        "Ensure documents are automatically directed to the appropriate individuals or departments without manual intervention.",
    },
    {
      number: "06",
      title: "Support for Multiple File Formats",
      description:
        "Handle a wide range of file formats, including PDF, CSV, text, and more, catering to varied user preferences.",
    },
    {
      number: "07",
      title: "API Integration",
      description:
        "Generate API credentials and access comprehensive documentation for seamless data retrieval and integration with other systems.",
    },
    {
      number: "08",
      title: "Granular Access Control",
      description:
        "Implement detailed access control measures to protect sensitive documents and reduce the risk of data breaches.",
    },
    {
      number: "09",
      title: "Client Collaboration Tools",
      description:
        "Facilitate easy feedback sharing and document approval or rejection, streamlining the collaboration process.",
    },
  ];

  return (
    <Box>
      <Box sx={{ position: "relative", height: { xs: "100vh", md: "auto" } }}>
        <NavigationBar scrollToFooter={scrollToFooter} />
        <img
          src={doct}
          width="100%"
          alt="slider1"
          style={{ height: "100vh", objectFit: "cover" }}
        />
        <Box>
          <Typography
            variant="h2"
            component="div"
            sx={{
              color: "#fff",
              fontWeight: "700",
              position: "absolute",
              top: "55%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "75%",
              "@media (max-width: 720px)": {
                fontSize: "1rem",
                marginTop: "10px",
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "2.5rem",
              },
              "@media (min-width: 961px)": {
                fontSize: "2.5rem",
              },
            }}
          >
            docT- Streamlined
            <br /> Document Sharing
            <Box
              sx={{
                "@media (max-width: 720px)": {},
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    fontSize: "1rem",
                    width: "45%",
                    mt:2,
                    "@media (max-width: 720px)": {
                      width: "100%",
                      fontSize: ".7rem",
                    },
                  }}
                >
                  <Typography>
                    docT is an innovative platform designed for streamlined and
                    secure document sharing among business partners or within
                    departments. Supporting various formats and transfer
                    methods, docT ensures efficient communication in different
                    business contexts.
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Button
                  className="WebButton"
                  variant="contained"
                  onClick={handleOpenDialog}
                >
                  Request to Demo
                </Button>
              </Box>
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#1C1D22" }}>
        <Grid
          container
          spacing={3}
          paddingTop={"5%"}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <Box sx={{ paddingLeft: { xs: "2%", lg: "13%" } }}>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  color: "#fff",
                  fontWeight: "700",
                  textAlign: "center",
                  px: 4,
                  fontSize: { xs: "14px", md: "24px" },
                }}
              >
                RapidQube Digital is a disruptive, results-driven, next-gen IT
                services provider solving the modern digital challenges and
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box p={4}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center", gap: 5 }}
          >
            {cards.map((card, index) => {
              const textColor = index % 3 === 1 ? "white" : "black"; // Column 2 is white, others are black
              const backgroundColor = index % 3 === 1 ? "transparent" : "white"; // Column 2 has a different background

              return (
                <Grid
                  key={index}
                  className="GridCardHeight"
                  item
                  lg={3.5}
                  md={3.5}
                  sm={5.5}
                  xs={12}
                >
                  <Box
                    className={`GridCard${index % 2 === 0 ? "2" : "3"}`}
                    p={2}
                    sx={{
                      width: isMobile ? "400px" : "auto",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      backgroundColor: backgroundColor,
                      color: textColor,
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontWeight: "700",
                        color: "#07A117",
                    
                      }}
                    >
                      {card.number}
                    </Typography>
                    <Typography
                  
                      component="div"
                      sx={{
                        fontWeight: "700",
                    
                      }}
                    >
                      {card.title}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                  
                        lineHeight: "1.5rem",
                        pb: 1,
                      }}
                    >
                      {card.description}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box sx={{ backgroundColor: "#2C2D2F", mt: 4, padding: "3%" }}>
          <Container>
            <Typography
              variant="h3"
              component="div"
              sx={{
                color: "#07A117",
                fontWeight: "700",
                marginTop: "5px",
                fontSize: { xs: "21px", lg: "32px" },
              }}
            >
              Why docT Stands Out
            </Typography>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={3}>
                  <Grid item lg={8} md={5} sm={12} xs={12}>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        color: "#fff",
                        fontWeight: "400",
                        marginTop: "20px",
                        fontSize: { xs: "14px", md: "18px" },
                      }}
                    >
                      Choose docT for its comprehensive document management
                      solution that enhances productivity, security, and
                      collaboration with seamless integration and automation.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              sx={{
                m: 0,
                width: { xs: "100%", lg: "auto" },
                display: "flex",
                justifyContent: "center",
                gap: 3,
                mt: 3,
                padding: { xs: "20px", lg: "0px" },
              }}
            >
              {[
                {
                  src: productivty,
                  title: "Increased Productivity",
                  description:
                    "Boost productivity by up to 30% by eliminating time wasted searching for documents and automating routine tasks.",
                },
                {
                  src: enhanced,
                  title: "Enhanced Efficiency",
                  description:
                    "Achieve a 50% reduction in the time spent searching for documents, allowing teams to focus on more strategic activities.",
                },
                {
                  src: error,
                  title: "Error Reduction",
                  description:
                    "Minimize errors caused by outdated document versions, ensuring accuracy and consistency across all documents.",
                },
                {
                  src: complaince,
                  title: "Regulatory Compliance",
                  description:
                    "Simplify compliance with industry regulations through streamlined document retention and management practices.",
                },
                {
                  src: security,
                  title: "Improved Security",
                  description:
                    "Reduce the risk of data breaches with robust access control measures, protecting sensitive information from unauthorized access.",
                },
                {
                  src: collabration,
                  title: "Better Collaboration",
                  description:
                    "Improve team collaboration with seamless document sharing, feedback integration, and automated workflows.",
                },
              ].map((card, index) => (
                <Grid
                  key={index}
                  className="GridCard4"
                  item
                  lg={3.5}
                  md={3.5}
                  sm={4.5}
                  xs={12}
                  sx={{ mt: 3, border: "1px solid white" }}
                >
                  <Box px={2} py={5} my={3}>
                    <img src={card.src} width={80} alt="icon service" />
                    <Typography
                      
                      component="div"
                      sx={{ fontWeight: "700", mt: 3,fontSize:"18px" }}
                    >
                      {card.title}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{ mt: 2, lineHeight: "1.5rem", pb: 3 }}
                    >
                      {card.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: {
              xs: "400px", // Mobile view
              sm: "400px", // Other views
            },
            overflow: "hidden", // Ensure the image doesn't overflow the box
          }}
        >
          <img
            src={demo}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensure the image covers the box area
            }}
            alt="product bg"
          />
          <Button
            sx={{
              position: "absolute",
              zIndex: 999,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            variant="contained"
            className="WebButton"
            onClick={() => handleOpenDialog()}
          >
            Ask for Demo
          </Button>
        </Box>
        <Box padding={"5%"} >
          <ProductSlider />
        </Box>
      </Box>

      <FooterPage footerRef={footerRef} />
    </Box>
  );
}
