import React, { useState } from "react";
import { Box, Grid, Typography, useTheme,useMediaQuery } from "@mui/material";
import YoutubeIcon from "../Images/icons/logos_youtube-icon.png";
import InIcon from "../Images/icons/Group(1).png";
import FBIcon from "../Images/icons/logos_facebook.png";
import InstaIcon from "../Images/icons/skill-icons_instagram.png";
import TwitterIcon from "../Images/icons/pajamas_twitter.png";
import { styled } from "@mui/system";
// Adjust the import path as needed
import serviceimg from "../Images/serviceimg.png";
import serviceel from "../Images/serviceel.png";
import { Link } from "react-router-dom";
import iconone from "../Images/stack/iconone (1).png"
import icontwo from "../Images/stack/iconone (2).png";
import iconthree from  "../Images/stack/iconone (3).png"
import iconfour from "../Images/stack/iconone (4).png"
import iconfive from "../Images/stack/iconone (5).png";
import iconsix from  "../Images/stack/iconone (6).png"
import iconseven from "../Images/stack/iconone (7).png"
import iconeight from "../Images/stack/iconone (8).png"

import imageone from "../Images/Pages/arrow.png"
import imagetwo from "../Images/Pages/box.png"
import imagethree from "../Images/Pages/like.png"
import imagefour from "../Images/Pages/world.png"
import imagefive from "../Images/Pages/settings.png"
import imagesix from "../Images/Pages/coin.png"
import imageseven from "../Images/Pages/one.png"
import imageeight from "../Images/Pages/message.png"


const Card = ({ title, description, link,image,isHovered, onMouseEnter, onMouseLeave }) => {
  // const [isHovered, setIsHovered] = useState(false);

  // const handleMouseEnter = () => setIsHovered(true);
  // const handleMouseLeave = () => setIsHovered(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Use 'sm' for mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const cardStyle = {
    position: "relative",
    overflow: "hidden",
    transition: "background-color 0.3s ease",
    backgroundColor: isHovered ? "#07A117" : "white", // Change color on hover
    color: isHovered ? "white" : "black",
    padding: "20px",
    borderRadius: "27px",
    height: "280px",
  };

  const buttonStyle = {
    display: isHovered ? "block" : "none",
    position: "absolute",
    bottom: "16px",
    right: "16px",
    backgroundColor: "white",
    color: "#07A117",
    border: "none",
    padding: "8px 16px",
    cursor: "pointer",
    borderRadius: "4px",
    fontWeight: "700",
    transition: "opacity 0.3s ease",
   
  };
  const buttonStyle2 = {
    display: "block",
    position: "absolute",
    bottom: "16px",
    right: "16px",
    backgroundColor: "#07A117",
    color: "#fff",
    border: "none",
    padding: "8px 16px",
    cursor: "pointer",
    borderRadius: "4px",
    fontWeight: "700",
    transition: "opacity 0.3s ease",
   
  };

  return (
    <Grid item lg={4.5} md={4.5} sm={6} xs={12}>
      <Link to={link} style={{ textDecoration: "none" }}>
        <Box
          style={cardStyle}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <img
            src={image}
            width={50}
            alt="service icon"
          />
          <Typography
            
            component="div"
            sx={{
              fontWeight: { xs: "700", md: "700" },
              fontSize: { xs: "14px",sm: "16px", md: "22px" },
              mt: 3,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant=""
            component="div"
            sx={{ mt: 2, lineHeight: "1.5rem", pb: 3,  fontSize: {
              xs: '14px',  // Mobile
              sm: '14px',  // Tablet and mobile
              md: '18px',  // Tablets and up
              lg: '18px',  // Larger screens
            }}}
          >
            {description}
          </Typography>
          {
           isMobile || isTablet ?  <button style={buttonStyle2} >Read More</button> : <button style={buttonStyle}>Read More</button>
          }
         
        </Box>
      </Link>
    </Grid>
  );
};





const ServiceCards = () => {

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const services = [
    {
      title: "Cloud & Infrastructure",
      description:
        "We offer  comprehensive cloud services and manage IT  infrastructure to optimize performance and  scalability, ensuring seamless operations.",
      link: "/cloudinfrastructure",
      img:hoveredIndex == 0 ? imageseven : iconeight,
    },
    {
      title: "Application Management Services",
      description:
       "We enhance performance, availability, and security of your applications, ensuring they align seamlessly with your business objectives.",
      link: "/applicationmanagement",
      img:hoveredIndex == 1 ? imagefive : iconseven,
    },
    {
      title: "Product Development",
      description:
       "From ideation to launch, we specialize in creating innovative products that meet market demands and drive significant business growth.",
      link: "/productdevelopment",
      img:hoveredIndex == 2 ? imagetwo :iconone,
    },
    {
      title: "Customer Experience",
      description:
       "We elevate customer engagement through web, mobile, and omni-channel experiences, ensuring seamless interactions across all touchpoints.",
      link: "/customerexperience",
      img:hoveredIndex == 3 ? imagethree :iconsix,
    },
    {
      title: "Data & Intelligence",
      description:
       "Harness the power of data  with our solutions that analyze and derive  insights, empowering informed decisionmaking and strategic planning.",
      link: "/dataintelligence",
      img:hoveredIndex == 4 ? imagesix :iconfive,
    },
    {
      title: "Automations",
      description:
       "We automate manual tasks using  cutting-edge technologies, such as RPA and AI,  to improve efficiency and reduce errors in your  processes.",
      link: "/automations",
      img:hoveredIndex == 5 ? imageone :iconfour,
    },
    {
      title: "Sustainability",
      description:
      "We assist businesses with ESG reporting, carbon analysis, and sustainable supply chain practices to reduce impact and drive value.",
      link: "/sustainability",
      img:hoveredIndex == 6 ? imagefour:iconthree,
    },
    {
      title: "Advisory, Consulting & Education",
      description:
       "Our experts offer strategic advice, consultancy, and education to help you navigate IT complexities and achieve objectives.",
      link: "/advisoryconsulting",
      img:hoveredIndex == 7 ? imageeight:icontwo,
    },
  ];
  return (
    <Box p={4}>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {services?.map((service, index) => (
          <Card
            key={index}
            title={service.title}
            link={service.link}
            image={service.img}
            description={service.description}
            isHovered={hoveredIndex === index}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default ServiceCards;
