import React from 'react';
import { Box, useMediaQuery, useTheme, IconButton } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from '../Images/Carousel/Carousel.png';
import Carousel1 from '../Images/Carousel/Carousel1.jpeg';
import '../Css/Style.css';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import carsoulimageone from '../Images/carosoulone.png'
import carsoulimagetwo from '../Images/carosoultwo.png'
import carsoulimagethree from '../Images/carosoulthree.png'

export default function CarouselSliderPage({ handleOpenDialog }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const CustomPrevArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      sx={{ position: 'absolute', top: '50%', left: '10px', fontSize: "14px", zIndex: 1, backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", '&:hover': {
        backgroundColor: "white", // ensure background color stays the same on hover
        opacity: 0.5,
        overflow: "hidden"
      } }}
    >
      <ArrowBackIos />
    </IconButton>
  );

  const CustomNextArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      sx={{ position: 'absolute', top: '50%', right: '10px',fontSize: "14px", zIndex: 1, backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", "&:hover": {
        backgroundColor: "white",
        opacity: 0.5
      } }}
    >
      <ArrowForwardIos />
    </IconButton>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    prevArrow: <CustomPrevArrow />, // use custom prev arrow
    nextArrow: <CustomNextArrow />, // use custom next arrow
  };

  const sliderContent = () => (
    <Box sx={{ overflow: "hidden", position: 'relative' }}>
      <Slider {...settings}>
        <Box className="slider-item">
          <img src={carsoulimagethree} width="100%" alt="slider1" />
          <Box className="slider-text">
            <h2 className='textheader'    sx={{ lineHeight: '67.68px' }}>Software Development for Business Growth</h2>
            <p style={{margin:"20px 0"}} sx={{lineHeight:"60px",fontSize:"20px"}}>Delivering Solutions Leveraging “Best In Class” Digital Technology To Enhance Your Business.</p>
            <Box className="slider-buttons">
              <Link to="/talkify" className="btn btn-primary ele" style={{textDecoration:"none"}}>View Product</Link>
              <Link to="/about" className="btn btn-secondary eletwo" style={{textDecoration:"none"}}>Learn More</Link>
            </Box>
          </Box>
        </Box>
        <Box className="slider-item">
          <img src={carsoulimagetwo} width="100%" alt="slider1" />
          <Box className="slider-text">
            <h2 className='textheader'    sx={{ lineHeight: '67.68px' }}>Software Development for Business Growth</h2>
            <p style={{margin:"20px 0"}} sx={{lineHeight:"34px",fontSize:"20px"}}>Delivering Solutions Leveraging “Best In Class” Digital Technology To Enhance Your Business.</p>
            <Box className="slider-buttons">
              <Link to="/talkify" className="btn btn-primary ele" style={{textDecoration:"none"}}>View Product</Link>
              <Link to="/about" className="btn btn-secondary eletwo" style={{textDecoration:"none"}}>Learn More</Link>
            </Box>
          </Box>
        </Box>
        <Box className="slider-item">
          <img src={carsoulimageone} width="100%" alt="slider1" />
          <Box className="slider-text">
            <h2 className='textheader'    sx={{ lineHeight: '67.68px' }}>Software Development for Business Growth</h2>
            <p style={{margin:"20px 0"}} sx={{lineHeight:"34px",fontSize:"20px"}}>Delivering Solutions Leveraging “Best In Class” Digital Technology To Enhance Your Business.</p>
            <Box className="slider-buttons">
              <Link to="/talkify" className="btn btn-primary ele" style={{textDecoration:"none"}}>View Product</Link>
              <Link to="/about" className="btn btn-secondary eletwo" style={{textDecoration:"none"}}>Learn More</Link>
            </Box>
          </Box>
        </Box>
       
      </Slider>
    </Box>
  );

  const staticContent = () => (
    <Box className="slider-item" style={{height:"100vh",opacity:0.8,overflow:"hidden"}}>
      <img src={carsoulimageone} width="100%" height="100%" alt="static" style={{objectFit:"cover"}} />
      <Box className="slider-text">
        <h2 style={{fontSize:"24px"}}>Software<br/> Development for <br/>Business Growth</h2>
        <p style={{fontSize:"14px"}}>Delivering Solutions Leveraging “Best In Class” Digital Technology To Enhance Your Business</p>
        <Box className="slider-buttons" style={{margin:"10px 0px"}}>
          <Link to="/talkify" className="btn btn-primary" style={{fontSize:"12px",textDecoration:"none"}}  >View Product</Link>
          <Link to="/about" className="btn btn-secondary" style={{fontSize:"12px",textDecoration:"none"}} >Learn More</Link>
        </Box>
      </Box>
    </Box>
  );


  

  return (
    <Box sx={{ overflow: 'hidden', position: 'relative', width: '100%' }}>
      {isMobile ? staticContent() : (isTablet ? staticContent() : sliderContent())}
    </Box>
  );
}
