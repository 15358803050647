import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  IconButton,
  FormControlLabel,
  Select,
  InputLabel,
  Checkbox,
  MenuItem,
} from "@mui/material";
import React, { memo } from "react";
import NavigationBar from "../Components/NavigationBar";
import AboutImage from "../Images/About.png";
import OurMission from "../Images/Pages/Rectangle 4454(1).png";
import OurVision from "../Images/Pages/ourvision.png";
import "../Css/Style.css";
import CoFounder from "../Images/Pages/co-founder.png";
import VicePresident from "../Images/Pages/vice-president.png";
import VicePresident1 from "../Images/Pages/vice-president1.png";
import TimeSet from "../Images/icons/timesetting.png";
import { Link } from "react-router-dom";
import FooterPage from "../Components/Footer";
import { Accordion, Container, FormControl } from "react-bootstrap";
import AboutCarousel from "../Images/Carousel/Aboutcarousel.png";
import Carroussel from "../Components/StyledSlider";
import CheckCircleIcon from "../Images/icons/check-circle-broken.png";
import CustomizedAccordions from "../Components/Accordion";
import TestimonialSlider from "../Components/ModelCardSlider";
import ServiceCards from "./ServiceCards";
import YoutubeIcon from "../Images/icons/logos_youtube-icon.png";
import InIcon from "../Images/icons/Group(1).png";
import FBIcon from "../Images/icons/logos_facebook.png";
import InstaIcon from "../Images/icons/skill-icons_instagram.png";
import TwitterIcon from "../Images/icons/pajamas_twitter.png";
import { styled } from "@mui/system";
import { useRef } from "react";
import imagesbanner from "../Images/banner.jpg";
import { Opacity } from "@mui/icons-material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
const cardData = [
  { key: 1, content: <img src={AboutImage} width={"100%"} alt="1" /> },
  { key: 2, content: <img src={AboutImage} alt="2" /> },
  { key: 3, content: <img src={AboutImage} alt="3" /> },
  { key: 4, content: <img src={AboutImage} alt="3" /> },
  { key: 5, content: <img src={AboutImage} alt="3" /> },
];

export default function ServicesPage({handleOpenDialog}) {
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };




  const [selectedProduct, setSelectedProduct] = useState("");
  const productOptions = [
    { value: "product1", label: "Product 1" },
    { value: "product2", label: "Product 2" },
    { value: "product3", label: "Product 3" },
  ];

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
  
    setLoading(true);
    setError(null);
    setSuccess(null);
  
    // Create a new FormData object
    const formData = new FormData();
    formData.append('userName', name);
    formData.append('workEmail', email);
    formData.append('messageTxt', message);
  
    try {
      // Send the form data using Axios
      const response = await axios.post('http://localhost/bahrain/contact_us', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
      });
  
      setSuccess('Message sent successfully!');
      setName('');
      setEmail('');
      setMessage('');
      alert(response.data.message)
    } catch (error) {
      setError('Failed to send message. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
        color: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
        color: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
        color: "#fff",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#fff",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#fff",
    },
  }));
  

  const CustomTextField = memo((props) => <StyledTextField {...props} />);
 

  

  const footerRef = useRef(null);

  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const services = [
    {
      title: "Cloud & Infrastructure",
      description:
        "We offer comprehensive cloud services and manage IT infrastructure to optimize performance and scalability, ensuring seamless operations.",
    },
    {
      title: "Application Management Services",
      description:
        "Our services oversee and optimize the performance, availability, and security of your enterprise applications, ensuring they align with your business goals.",
    },
    {
      title: "Product Development",
      description:
        "From ideation to launch, we specialize in developing innovative products that meet market demands and drive business growth.",
    },
    {
      title: "Customer Experience",
      description:
        "We enhance customer engagement through web, mobile, and omni-channel experiences, ensuring seamless and satisfying experiences across all touchpoints.",
    },
    {
      title: "Data & Intelligence",
      description:
        "Harness the power of data with our solutions that analyze and derive insights, empowering informed decision-making and strategic planning.",
    },
    {
      title: "Automations",
      description:
        "We automate manual tasks using cutting-edge technologies, such as RPA and AI, to improve efficiency and reduce errors in your processes.",
    },
    {
      title: "Sustainability",
      description:
        "We help businesses implement sustainable practices, including ESG reporting, carbon footprint analysis, and sustainable supply chain management, to reduce environmental impact and drive long-term value.",
    },
    {
      title: "Advisory, Consulting & Education",
      description:
        "Our experts provide strategic advice, consultancy, and educational programs to help you navigate the complex IT landscape and achieve your business objectives.",
    },
  ];

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Box
        sx={{
          position: "relative",
          textAlign: { xs: "left", lg: "center" },
          height: { xs: "100vh", md: "auto" },
          background:
            "linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5))",
        }}
      >
        <NavigationBar scrollToFooter={scrollToFooter} />

        <img
          src={imagesbanner}
          width="100%"
          style={{ opacity: 0.3, objectFit: "cover", height: "100vh" }}
          sx={{
            height: { xs: "100vh", md: "auto" },
            background:
              "linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5))",
          }}
          alt="slider1"
        />
        <Typography
          variant="h2"
          component="div"
          sx={{
            color: "#fff",
            fontWeight: "700",
            position: "absolute",
            top: "55%",
            padding: { xs: "20px", md: "0px" },
            left: "50%",
            margin: { xs: "10px", lg: "0px" },
            transform: "translate(-50%, -50%)",
            width: "100%",

            "@media (max-width: 600px)": {
              fontSize: "1rem",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              fontSize: "2.5rem",
              textAlign: "center",
            },
            "@media (min-width: 961px)": {
              fontSize: "3.5rem",
            },
          }}
        >
          Services
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: { xs: "left", md: "center" },
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                width: "43%",
                textAlign: { xs: "left", md: "center" },
                margin: { xs: "10px 0px", md: "0px" },
                "@media (max-width: 720px)": {
                  width: "100%",
                  fontSize: "0.8rem",
                },
              }}
            >
              We provide innovative solutions and expert guidance to boost
              efficiency, drive growth, and exceed your business goals with
              cutting-edge technology.
            </Typography>
          </Box>
          <Box>
            <Button
              className="WebButton"
              variant="contained"
              onClick={() => handleOpenDialog()}
            >
              Get Connect
            </Button>
          </Box>
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: "#1C1D22" }}>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Grid container spacing={3} paddingTop={"5%"}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box sx={{ paddingLeft: "13%" }}>
                <Typography
                  component="div"
                  sx={{
                    color: "#fff",
                    fontWeight: "600",
                    px: 4,
                  }}
                >
                  Explore Our Services Below to see How <br /> We can Help your
                  Business Thrive in the
                  <br /> Digital age.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box sx={{ paddingLeft: "13%" }}>
                <Typography
                  variant="p"
                  component="div"
                  sx={{
                    color: "#fff",
                    fontWeight: "400",
                    px: 4,
                    fontSize: "16px",
                  }}
                >
                  Our range of IT services is designed to meet the diverse needs
                  of businesses, ensuring efficiency, security, and innovation.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Grid container spacing={3} paddingTop={"5%"}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box>
                <Typography
                  variant="p"
                  component="div"
                  sx={{
                    color: "#07A117",
                    fontWeight: "500",
                    fontSize: "24px",
                    px: 4,
                    textAlign: "center",
                  }}
                >
                  Explore our services
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box>
                <Typography
                  variant="p"
                  component="div"
                  sx={{
                    color: "#fff",
                    fontWeight: "400",
                    px: 4,
                    fontSize: "14px",
                  }}
                >
                  Our range of IT services is designed to meet the diverse needs
                  of businesses, ensuring efficiency, security, and innovation.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <ServiceCards />
        </Box>
        <Box sx={{ backgroundColor: "#2C2D2F", mt: 4, padding: "3%" }}>
          <Container>
            <Typography
              variant="h3"
              component="div"
              sx={{
                color: "#07A117",
                fontWeight: "700",
                marginLeft: "9%",
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            >
              Why choose us
            </Typography>
            <Typography
              variant="h4"
              component="div"
              sx={{
                display: { xs: "block", md: "none" },
                color: "#07A117",
                fontWeight: "700",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Why choose us
            </Typography>
            <Grid
              item
              lg={4}
              md={4}
              sm={12}
              xs={12}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              <img src={AboutImage} width={"100%"} alt="about image" />
            </Grid>
            <Grid
              container
              spacing={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid
                item
                lg={6}
                md={12}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "center", p: 4 }}
              >
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 1,
                        mt: 1,
                      }}
                    >
                      <img src={CheckCircleIcon} width="25" alt="check icon" />
                    </Box>
                    <Box>
                      <Typography
                        component="div"
                        sx={{
                          color: "#FFFFFF",
                          mt: 2,
                          lineHeight: "2.5rem",
                          fontSize: { xs: "16px", md: "20px" },
                        }}
                      >
                        <strong>Expert Team:</strong>
                        <span>
                          <Typography>
                            Our team of certified professionals brings extensive
                            experience and knowledge to every project.
                          </Typography>
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { md: "left", lg: "center" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 1,
                        mt: 1,
                      }}
                    >
                      <img src={CheckCircleIcon} width="25" alt="check icon" />
                    </Box>
                    <Box>
                      <Typography
                        component="div"
                        sx={{
                          color: "#FFFFFF",
                          mt: 2,
                          lineHeight: "2.5rem",
                          fontSize: { xs: "16px", md: "20px" },
                        }}
                      >
                        <strong>Proactive Support:</strong>
                        <Typography>
                          We stay ahead of potential issues with proactive
                          monitoring and maintenance.
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { md: "left", lg: "center" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 1,
                        mt: 1,
                      }}
                    >
                      <img src={CheckCircleIcon} width="25" alt="check icon" />
                    </Box>
                    <Box>
                      <Typography
                        component="div"
                        sx={{
                          color: "#FFFFFF",
                          mt: 2,
                          lineHeight: "2.5rem",
                          fontSize: { xs: "16px", md: "20px" },
                        }}
                      >
                        <strong>Customized Solutions:</strong>

                        <Typography>
                          We tailor our services to meet your unique business
                          needs and goals.
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 1,
                        mt: 1,
                      }}
                    >
                      <img src={CheckCircleIcon} width="25" alt="check icon" />
                    </Box>
                    <Box>
                      <Typography
                        component="div"
                        sx={{
                          color: "#FFFFFF",
                          mt: 2,
                          lineHeight: "2.5rem",
                          fontSize: { xs: "16px", md: "20px" },
                        }}
                      >
                        <strong>Client-Focused:</strong>
                        <Typography>
                          Our client-first approach ensures you receive the best
                          possible service and support.
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                lg={3.5}
                md={4}
                sm={12}
                xs={12}
                sx={{ display: { xs: "none", md: "block" } }}
              >
                <img src={AboutImage} width={"100%"} alt="about image" />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ height: { xs: "100%", md: "auto", padding: "30px" } }}>
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Typography
                variant="h3"
                component="div"
                sx={{
                  color: "#07A117",
                  fontWeight: "700",
                  textAlign: "center",
                  fontSize: { xs: "21px", lg: "32px" },
                }}
              >
                Frequently Asked Question
              </Typography>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  color: "#fff",
                  textAlign: "center",
                  py: 2,
                  fontSize: { xs: "16px", md: "18px" },
                }}
              >
                Building next-generation products, platforms & experience that
                connects with your customers and transforming businesses by
                developing products, services & experiences
              </Typography>
              <Box sx={{ p: 2 }}>
                <CustomizedAccordions />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        p={3}
        style={{ backgroundColor: "#ffffff" }}
        ref={footerRef}
        id="footer"
      >
        <Box padding={"3%"}>
          <Grid container spacing={3} paddingTop={"5%"}>
            <Grid
              item
              lg={8.5}
              md={8.5}
              sm={12}
              xs={12}
              p={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      textAlign: "center",
                      color: "#07A117",
                      fontWeight: "700",
                    }}
                  >
                    RapidQube
                  </Typography>
                  <Typography
                    variant=""
                    component="div"
                    sx={{
                      textAlign: "center",
                      color: "#000",
                      margin: "10px 0px",
                    }}
                  >
                    Partner with us to Unlock Your Business's Full Potential.
                  </Typography>
                  <Box p={3} sx={{ display: "flex", justifyContent: "center" }}>
                    <a
                      href="https://www.youtube.com/channel/UCkwqExwAISY_9yYinPUWYpg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ paddingRight: "10px" }}
                        src={YoutubeIcon}
                        width={40}
                        alt="YouTube icon"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/rapidqube-bahrain/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ paddingRight: "10px" }}
                        src={InIcon}
                        width={30}
                        alt="LinkedIn icon"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/RapidQubeBahrain"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ paddingRight: "10px" }}
                        src={FBIcon}
                        width={30}
                        alt="Facebook icon"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/rapidqubebahrain/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ paddingRight: "10px" }}
                        src={InstaIcon}
                        width={30}
                        alt="Instagram icon"
                      />
                    </a>
                    <a
                      href="https://x.com/RapidqubeB"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ paddingRight: "10px" }}
                        src={TwitterIcon}
                        width={30}
                        alt="Twitter icon"
                      />
                    </a>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Typography
                    variant=""
                    component="div"
                    sx={{ color: "#07A117", fontWeight: "700" }}
                  >
                    Services
                  </Typography>
                  <Box mt={1}>
                    <Link to="/cloudinfrastructure" className="FooterLink">
                      Cloud & Infrastructure
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/dataintelligence" className="FooterLink">
                      Data & Intelligence
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/productdevelopment" className="FooterLink">
                      Product Development
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/customerexperience" className="FooterLink">
                      Customer Experience
                    </Link>
                  </Box>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12} pb={3}>
                  <Typography
                    variant=""
                    component="div"
                    sx={{ color: "#07A117", fontWeight: "700" }}
                  >
                    Products
                  </Typography>
                  <Box mt={1}>
                    <Link to="/rsuite" className="FooterLink">
                      R-suite
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/doct" className="FooterLink">
                      docT
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/rapidverse" className="FooterLink">
                      Rapid Verse
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/talkify" className="FooterLink">
                      Talkify
                    </Link>
                  </Box>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12}>
                  <Typography
                    variant=""
                    component="div"
                    sx={{ color: "#07A117", fontWeight: "700" }}
                  >
                    Our Links
                  </Typography>
                  <Box mt={1}>
                    <Link to="/talkify" className="FooterLink">
                      Products
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/services" className="FooterLink">
                      Services
                    </Link>
                  </Box>
                  <Box mt={1}>
                    <Link to="/about" className="FooterLink">
                      About Us
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3.5} md={3.5} sm={12} xs={12} style={{ backgroundColor: '#07A117', padding: '16px' }}>
      <Typography variant="h6" component="div" style={{ color: '#fff', fontSize: '20px', fontWeight: 700 }}>
        Let's Get Started
      </Typography>
      <form onSubmit={handleSubmit} style={{ marginTop: '16px' }}>
        <TextField
          label="Name"
          size="small"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ width: '100%', marginTop: '8px' }}
          InputProps={{
            style: {
              color: '#fff',
              borderColor: '#fff', // Attempt to set border color here
            },
          }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#fff', // Outline color for TextField
              },
              '&:hover fieldset': {
                borderColor: '#fff', // Outline color when hovered
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff', // Outline color when focused
              },
            },
            '& .MuiInputLabel-root': {
              color: '#fff',
            },
            '& .MuiInputBase-input': {
              color: '#fff',
            },
          }}
        />
        <TextField
          label="Work Email"
          size="small"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ width: '100%', marginTop: '8px' }}
          InputProps={{
            style: { color: '#fff' },
          }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#fff',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
        />
        <TextField
          label="Message"
          size="small"
          variant="outlined"
          multiline
          rows={3}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={{ width: '100%', marginTop: '8px' }}
          InputProps={{
            style: { color: '#fff', height: 'auto' },
          }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#fff',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          style={{
            width: '100%',
            marginTop: '8px',
            backgroundColor: '#112F37',
            color: '#fff',
            boxShadow: 'none',
            textTransform: 'none',
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0e4a52'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#112F37'}
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send'}
        </Button>
        
      </form>
    </Grid>
          </Grid>
          <Box mt={4}>
            <hr />
          </Box>
        </Box>
        <Typography
          variant=""
          component="div"
          sx={{ mb: 2, textAlign: "center", color: "#000", fontSize: "16px" }}
        >
          Copyright © 2024 RapidQube Digital Solutions WLL. All Rights Reserved.{" "}
          <Link> Privacy Policy</Link>
        </Typography>
      </Box>
     
    </Box>
  );
}
