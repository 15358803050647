import * as React from 'react';
import {
  AppBar, Box, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, Collapse, Menu, MenuItem, Button,
  Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, TextField, Checkbox, DialogActions,
  backdropClasses,
  InputLabel,
  FormControl,
  Select
} from '@mui/material';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import logo from "../Images/logo.png"
import axios from 'axios';
//link: '/products'
const pages = [
  { label: 'Home', link: '/' },
  {
    label: 'Products', hasSubmenu: true, submenu: [
      { name: 'Talkify', link: '/talkify' },
      { name: 'RapidVerse', link: '/rapidverse' },
      { name: 'RapidToken', link: '/rapidtoken' },
      { name: 'R-suite', link: '/rsuite' },
      { name: 'Qubesphere', link: '/qubesphere' },
      { name: 'Doct', link: '/doct' },
      { name: 'Rapid EHR', link: '/rapidehr' },
      { name: 'R-Mart', link: '/rmart' },
      { name: 'eFanEng', link: '/efaneng' },
      { name: 'Rapid Property', link: '/rapidproperty' },
    ]
  },
  {
    label: 'Services', link: '/services', hasSubmenu: true, submenu: [
      { name: 'Cloud Infrastructure', link: '/cloudinfrastructure' },
      { name: 'Application Management', link: '/applicationmanagement' },
      { name: 'Product Development', link: '/productdevelopment' },
      { name: 'Customer Experience', link: '/customerexperience' },
      { name: 'Data & Intelligence', link: '/dataintelligence' },
      { name: 'Automations', link: '/automations' },
      { name: 'Sustainability', link: '/sustainability' },
      { name: 'Advisory & Consulting', link: '/advisoryconsulting' },
    ]
  },
  { label: 'About Us', link: '/about' },
   { label: 'Contact Us',  },
];

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#777777',
    },
    '&:hover fieldset': {
      borderColor: '#777777',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#777777',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#777777',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#777777',
  },
}));

function NavigationBar({ scrollToFooter }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openSubMenus, setOpenSubMenus] = React.useState({});

  const [selectedProduct, setSelectedProduct] = React.useState('');
  const productOptions = [
    { value: 'Talkify', label: 'Talkify' },
    { value: 'Rapidverse', label: 'Rapidverse' },
    { value: 'Rapid-Token', label: 'Rapid-Token' },
    {value:'QubeSphere',label:'QubeSphere'},
    {value:'docT',label:'docT'},
    {value:'Rapid EHR',label:'Rapid EHR'},
    {value:'R-Mart',label:'R-Mart'},
    {value:'eFanEng',label:'eFanEng'},
    {value:'RapidProperty',label:'RapidProperty'},
    {value:'R-Suite',label:'R-Suite'}
  ];

  const handleChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  
  const handleOpenNavMenu = () => {
    setMobileOpen(true);
  };

  const handleCloseNavMenu = () => {
    setMobileOpen(false);
  };

  const handleMenuOpen = (event, menuLabel) => {
    setAnchorEl({ anchor: event.currentTarget, label: menuLabel });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRequestClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubMenuToggle = (label) => {
    setOpenSubMenus((prevOpenSubMenus) => ({
      ...prevOpenSubMenus,
      [label]: !prevOpenSubMenus[label],
    }));
  };

  const isMenuOpen = (label) => anchorEl?.label === label;

  const [formValues, setFormValues] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    product:"" ,
    message: '',
    consent: false,
  });


  const resetForm = () => {
    setFormValues({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyName: '',
     
      message: '',
      consent: false,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setFormValues({ ...formValues, consent: e.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Create a new FormData object
    const formData = new FormData();
    
    // Append form values to FormData object
    formData.append('firstName', formValues.firstName);
    formData.append('lastName', formValues.lastName);
    formData.append('email', formValues.email);
    formData.append('mobile', formValues.phone);
    formData.append('companyName', formValues.companyName);
    formData.append('product', formValues.product);
    formData.append('messageTxt', formValues.message);
    formData.append('consent', formValues.consent);
  
    try {
      // Make a POST request to your endpoint with FormData
      const response = await axios.post('http://localhost/bahrain/booking_details', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Handle the response if needed
      console.log('Response:', response.data);
  
      // Reset form or close dialog after submission
      handleCloseDialog();
      resetForm()
    } catch (error) {
      // Handle errors if the request fails
      console.error('Error submitting the form:', error);
    }
  };

  return (
    <Box sx={{display:"flex",justifyContent:"center",width:"100%"}}>
    <AppBar position="fixed" sx={{ boxShadow:"none",backgroundColor: 'transparent', px: 3,width:"100%",display:"flex",justifyContent:"center",alignItems:"center" }} >
      <Container maxWidth="xl" className='mblview'>
        <Toolbar disableGutters sx={{backgroundColor:"#161815",width:"100%",padding:{xs:"0px",lg:"15px 20px"}}} className='elementradius'>
          <Box className="elementbox" sx={{ flexGrow: 1,display: { xs: 'flex', md: 'none',justifyContent:"space-between",alignItems:"center",padding:"5px" } }}>

          <Box sx={{ display: "flex",alignItems:"center",gap:1 }}>
            <Box>
              <img src={logo} alt="logo" width="30px" height="30px"/>
            </Box>
            <Box>
            <Typography
                variant="h6"
                noWrap
                component={Link}
                to="/"
                sx={{
                  mr: 3,
                  display: { md: 'flex',justifyContent:"center" },
                  fontWeight: 400,
                  letterSpacing: '.1rem',
                  color: 'inherit',
                  textDecoration: 'none',
                  align:"center",
                  fontSize:"12px"
                }}
              >
                RapidQube
              </Typography>
              <Typography sx={{ color: "#07A117", fontSize: "8px" }}>Transforming Business Paradigms</Typography>
            </Box>
             
            </Box>

            <IconButton
              size="large"
              aria-label="open navigation menu"
              onClick={handleOpenNavMenu}
              color="inherit"
              style={{color:"#07A117"}}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={mobileOpen}
              onClose={handleCloseNavMenu}
            >
              <List>
                {pages.map((page) => (
                  <div key={page.label}>
                    {page.hasSubmenu ? (
                      <>
                        <ListItem button component={Link} to={page.link} onClick={handleCloseNavMenu}>
                          <ListItemText primary={page.label} />
                          <IconButton
                            edge="end"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleSubMenuToggle(page.label);
                            }}
                          >
                            {openSubMenus[page.label] ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        </ListItem>
                        <Collapse in={openSubMenus[page.label]} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {page.submenu.map((item) => (
                              <ListItem
                                button
                                key={item.name}
                                component={Link}
                                to={item.link}
                                onClick={handleCloseNavMenu}
                                sx={{ pl: 4 }}
                              >
                                <ListItemText primary={item.name} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </>
                    ) :  (
                      page.label == 'Contact Us' ? (
                        <Button
                          component="a"
                        
                          sx={{ '&:hover': { color: '#07A117' } }}
                          color="inherit"
                          onClick={scrollToFooter}
                        >
                          {page.label}
                        </Button>
                      ) : (
                        <Button
                          component={Link}
                          to={page.link}
                          sx={{ '&:hover': { color: '#07A117' } }}
                          color="inherit"
                        >
                          {page.label}
                        </Button>
                      ))}
                  </div>
                ))}
              </List>
              
            <button className='reqButtonmbl' style={{color:"white",outline:"none",border:"none",fontSize:"15px",margin:"0px 10px",fontWeight:"700",cursor:"pointer"}}  onClick={handleRequestClick}>
              Request a Demo
            </button>
            </Drawer>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between',alignItems:"center",padding:"0px 10px",borderRadius:"10%"  }}>
            <Box sx={{ display: "flex",alignItems:"center" }}>
            <Box>
              <img src={logo} alt="logo" width="50px" height="50px"/>
            </Box>
            <Box>
            <Typography
                variant="h6"
                noWrap
                component={Link}
                to="/"
                sx={{
                  mr: 3,
                  display: { xs: 'none', md: 'flex',justifyContent:"center" },
                  fontWeight: 800,
                  letterSpacing: '.1rem',
                  color: 'inherit',
                  textDecoration: 'none',
                  align:"center"
                }}
              >
                RapidQube
              </Typography>
              <Typography sx={{ color: "#07A117", fontSize: "10px" }}>Transforming Business Paradigms</Typography>
            </Box>
             
            </Box>

      {pages.map((page) => (
        <Box key={page.label} sx={{ position: 'relative', mr: 3 }}>
          {page.hasSubmenu ? (
            <>
              <Button
                component={Link}
                to={page.link}
                aria-controls={page.label.toLowerCase() + '-menu'}
                aria-haspopup="true"
                color="inherit"
                sx={{'&:hover': {
              color: '#07A117',
                       }}}
                endIcon={
                  <ExpandMoreIcon
                    onMouseEnter={(event) => handleMenuOpen(event, page.label)}
                    onClick={(event) => handleMenuOpen(event, page.label)}
                    sx={{
                      transform: isMenuOpen(page.label) ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s ease',
                       
                    }}
                  />
                }
              >
                {page.label}
              </Button>
              <Menu
                id={page.label.toLowerCase() + '-menu'}
                anchorEl={isMenuOpen(page.label) ? anchorEl.anchor : null}
                open={isMenuOpen(page.label)}
                onClose={handleMenuClose}
                MenuListProps={{ onMouseLeave: handleMenuClose }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{ mt: 5 }}
              >
                {page.submenu.map((item, index) => (
                  <MenuItem key={index} component={Link} to={item.link}   onClick={handleMenuClose} >
                    {item.name}
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            
            page.label == 'Contact Us' ? (
              <Button
                component="a"
              
                sx={{ '&:hover': { color: '#07A117' } }}
                color="inherit"
                onClick={scrollToFooter}
              >
                {page.label}
              </Button>
            ) : (
              <Button
                component={Link}
                to={page.link}
                sx={{ '&:hover': { color: '#07A117' } }}
                color="inherit"
              >
                {page.label}
              </Button>
            ))}
        </Box>
      ))}
   
            <button className='reqButton' style={{fontWeight:"700",color:"white",outline:"none",border:"none",fontSize:"15px",cursor:"pointer"}} onClick={handleRequestClick}>
              Request a Demo
            </button>
          </Box>

          <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogContent className="custom-dialog-content">
        <Typography variant="h5" component="div" sx={{ color: '#07A117', pl: 2 }}>
          Request for Demo
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 0,
            top: 18,
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item lg={6} md={6} sm={12} xs={12} p={2} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="First Name"
                name="firstName"
                size="small"
                variant="outlined"
                sx={{ width: '100%' }}
                value={formValues.firstName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} p={2} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="Last Name"
                name="lastName"
                size="small"
                variant="outlined"
                sx={{ width: '100%' }}
                value={formValues.lastName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} p={2} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="Email Address"
                name="email"
                size="small"
                variant="outlined"
                sx={{ width: '100%' }}
                value={formValues.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} p={2} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="Phone Number"
                name="phone"
                size="small"
                variant="outlined"
                sx={{ width: '100%' }}
                value={formValues.phone}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} p={2} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="Company Name"
                name="companyName"
                size="small"
                variant="outlined"
                sx={{ width: '100%' }}
                value={formValues.companyName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} p={2} sx={{ display: "flex", justifyContent: "center" }}>
            <FormControl size="small" variant="outlined" sx={{ width: '100%' }}>
      <InputLabel>Product</InputLabel>
      <Select
        label="Product"
        name="product"
        value={formValues.product}
        onChange={handleInputChange}
        renderValue={(selected) => selected || "Select a product"}
      >
        <MenuItem value="Talkify">Talkify</MenuItem>
        <MenuItem value="Rapidverse">Rapidverse</MenuItem>
        <MenuItem value="Rapid-Token">Rapid-Token</MenuItem>
        <MenuItem value="QubeSphere">QubeSphere</MenuItem>
        <MenuItem value="docT">docT</MenuItem>
        <MenuItem value="Rapid EHR">Rapid EHR</MenuItem>
        <MenuItem value="R-Mart">R-Mart</MenuItem>
        <MenuItem value="eFanEng">eFanEng</MenuItem>
        <MenuItem value="RapidProperty">RapidProperty</MenuItem>
        <MenuItem value="R-Suite">R-Suite</MenuItem>
      </Select>
    </FormControl>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} p={2} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                label="Message"
                name="message"
                size="small"
                variant="outlined"
                multiline
                rows={3}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    height: 'auto',
                  },
                }}
                value={formValues.message}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', pl: 3 }}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: 'green',
                    '&.Mui-checked': {
                      color: 'green',
                    }
                  }}
                  checked={formValues.consent}
                  onChange={handleCheckboxChange}
                />
              }
              label={
                <Typography component="div" sx={{ color: '#777777', paddingTop: '10px', fontSize: "14px" }}>
                  By submitting your details, you consent to receive communications from our organization regarding your demo request, including updates, promotional offers, and other relevant information.
                </Typography>
              }
              sx={{ alignItems: 'flex-start' }}
            />
          </Box>
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item lg={5} md={5} sm={12} xs={12} p={1}>
              <Button
                variant='outlined'
                sx={{ width: "100%" }}
                className='CancelButton'
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12} p={1}>
              <Button
                variant='contained'
                sx={{ width: "100%" }}
                className='SubmitButton'
                type="submit"
              >
                Request for Demo
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
        </Toolbar>
      </Container>
    </AppBar>
    </Box>
  );
}

export default NavigationBar;
