import { Box, Button, Grid, List, ListItem, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useRef } from 'react';
import AboutImage from '../../Images/About.png'
import '../../Css/Style.css'
import TimeSet from '../../Images/icons/timesetting.png'
import FooterPage from '../../Components/Footer';
import { Accordion, Container } from 'react-bootstrap';
import AboutCarousel from '../../Images/Carousel/Aboutcarousel.png'
import CheckCircleIcon from '../../Images/icons/check-circle-broken.png'
import CustomizedAccordions from '../../Components/Accordion';
import TestimonialSlider from '../../Components/ModelCardSlider';
import NavigationBar from '../NavigationBar';
import IconService from '../../Images/icons/iconservice.png'
import IconSettings from '../../Images/icons/Group 282(1).png'
import IncreaseIcon from '../../Images/icons/Vector(3).png'
import ReduceIcon from '../../Images/icons/Group(2).png'
import UserExpIcon from '../../Images/icons/Vector(4).png'
import ResponseIcon from '../../Images/icons/Vector(5).png'
import ProductBg from '../../Images/Pages/product-bgimg.png'
import ProductSlider from '../ProductsSlider';
import rmart from '../../Images/rmart.png';
import demo from "../../Images/demo.jpg";
import customer from "../../Images/stack/customer.png"

import effi from "../../Images/stack/efficiencythree.png"
import insights from "../../Images/stack/insights.png"
import secure from "../../Images/stack/securetrans.png"
import sales from "../../Images/productsicons/sales.png"
import scalable from "../../Images/productsicons/scalable.png"


export default function RMartPage({handleOpenDialog}) {

    const footerRef = useRef(null);
    const scrollToFooter = () => {
        footerRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
      

    const cards = [
        { id: "01", title: "Scheduling", description: "This feature can significantly improve efficiency and save time by automating recurring tasks. It also allows for better organization and planning of future events." },
        { id: "02", title: "e-Prescribing", description: "This eliminates the need for paper prescriptions and reduces the risk of errors caused by illegible handwriting or miscommunication." },
        { id: "03", title: "Medical Billing", description: "It is integrated with HIPAA standards to ensure the confidentiality and security of patient information." },
        { id: "04", title: "CMS Reporting", description: "This feature streamlines the process of generating comprehensive reports, making it easy for users to access the information they need." },
        { id: "05", title: "Lab Integration", description: "This system eliminates the need for manual data entry and reduces the risk of errors, providing accurate and timely results." },
        { id: "06", title: "Clinical Decision Rules", description: "The advanced engine is designed to navigate complex patient care algorithms, making it easier for healthcare professionals to provide effective treatment." },
        { id: "07", title: "Advanced Security", description: "Advanced security features, specifically designed to comply with HIPAA regulations, are in place to protect sensitive information." },
        { id: "08", title: "Multilingual Support", description: "The multilingual support of a product, stating that it is available in over 30 languages and can be customized for more." },
        { id: "09", title: "Cloud-Based Deployment", description: "Cloud-based deployment offers the flexibility to choose between on-premise or cloud deployment options." }
    ];


    return (
        <Box>
            <Box sx={{ position: 'relative',height:{xs:"100vh",md:"auto"}  }}>
                <NavigationBar scrollToFooter={scrollToFooter}/>
                <img src={rmart} width="100%" alt="slider1" style={{height:"100vh",objectFit:"cover"}}/>
                <Box>
                    <Typography
                        variant="h2"
                        component="div"
                        sx={{
                            color: "#fff",
                            fontWeight: "700",
                            position: "absolute",
                            top: "55%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: '75%',
                            '@media (max-width: 720px)': {
                                fontSize: '1rem',
                                marginTop: "10px"
                            },
                            '@media (min-width: 601px) and (max-width: 960px)': {
                                fontSize: '2.5rem',
                            },
                            '@media (min-width: 961px)': {
                                fontSize: '2.5rem',
                            },
                        }}
                    >
                        R-Mart - Global Commerce
                        <Box sx={{
                            '@media (max-width: 720px)': {
                               
                            },
                        }}>
                            <Box sx={{ display: "flex" }}>
                                <Box sx={{
                                    fontSize: "1rem", width: "45%",mt:1,
                                    '@media (max-width: 720px)': {
                                        width: "100%",
                                        fontSize: ".7rem",

                                    },
                                }}>
                                    <Typography>
                                    R- Mart - provides a range of features and tools to help users manage products, accept payments, process orders, and manage customers. Supports multiple languages and currencies, making it a versatile solution for users around the world. It also integrates with various payment gateways and shipping carriers, making it easy for users to start accepting payments and shipping products to customers​.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Button className='WebButton' variant="contained" onClick={handleOpenDialog}>Request to Demo</Button>
                            </Box>
                        </Box>
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: "#1C1D22" }}>
                <Grid container spacing={3} paddingTop={"5%"} sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item lg={7} md={7} sm={12} xs={12} >
                        <Box sx={{ paddingLeft:{xs:"2%", lg:"13%"}, }}>
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{
                                    color: "#fff",
                                    fontWeight: "700",
                                    textAlign: "center",
                                    px: 4,
                                    fontSize:{xs:"14px",md:"24px"}
                                }}
                            >
                                RapidQube Digital is a disruptive, results-driven, next-gen IT services provider solving the modern digital challenges and
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box p={4}>
            <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center",gap:5 }}>
                {cards.map((card, index) => {
                    const textColor = index % 3 === 1 ? "white" : "black"; // Column 2 is white, others are black
                    const backgroundColor = index % 3 === 1 ? "transparent" : "#fff"; // Column 2 has a different background

                    return (
                        <Grid key={index} className="GridCardHeight" item lg={3.5} md={3.5} sm={5.5} xs={12}>
                            <Box
                                className={`GridCard${index % 2 === 0 ? '2' : '3'}`}
                                p={2}
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    height:"230px",
                                    flexDirection: 'column',
                                    gap:2,
                                    backgroundColor: backgroundColor,
                                    color: textColor,
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    width: isMobile ? '400px' : 'auto',
                                   
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                        fontWeight: "700",
                                        color: "#07A117",
                                      
                                       
                                    }}
                                >
                                    {card.id}
                                </Typography>
                                <Typography
                                
                                    component="div"
                                    sx={{
                                        fontWeight: "bold",
                                       fontSize:"18px"
                                        
                                    }}
                                >
                                    {card.title}
                                </Typography>
                                <Typography
                                    component="div"
                                    sx={{
                                      
                                        lineHeight: "1.5rem",
                                        pb: 1,
                                     
                                    }}
                                >
                                    {card.description}
                                </Typography>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
                <Box sx={{ backgroundColor: "#2C2D2F", mt: 4, padding: "3%" }}>
                    <Container>
                        <Typography
                            variant="h3"
                            component="div"
                            sx={{
                                color: "#07A117",
                                fontWeight: "700",
                                fontSize:{xs:"21px",lg:"32px"}
                            }}
                        >
                           Why R- Mart Stands Out
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Grid container spacing={3} sx={{ paddingTop: "1%" }}>
                                    <Grid item lg={8} md={5} sm={12} xs={12} >
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                color: "#fff",
                                                fontWeight: "400",
                                                fontSize:{xs:"14px",md:"18px"}
                                            }}
                                        >
                                          Choose Rapid EHR to transform healthcare with improved data sharing, patient engagement, and streamlined workflows for enhanced care and efficiency.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
      container
      spacing={3}
      sx={{ 
        display: "flex", 
        justifyContent: "center", 
        mt: 3, 
        m:0,
        width:{xs:'100%',lg:'auto'},
        padding: { xs: "20px", lg: "0px" } 
      }}
    >
      {[
        {
          icon: customer,
          title: "Enhanced Customer Experience",
          description: "Advanced search, personalization, and mobile responsiveness create a user-friendly shopping experience."
        },
        {
          icon: sales,
          title: "Increased Sales Opportunities",
          description: "Integration across multiple sales channels and personalized recommendations drive more sales."
        },
        {
          icon: effi,
          title: "Operational Efficiency",
          description: "AI/ML, advanced inventory management, and real-time analytics streamline operations and reduce costs."
        },
        {
          icon: scalable,
          title: "Business Scalability",
          description: "Cloud infrastructure and scalable solutions ensure the platform grows with your business needs."
        },
        {
          icon: insights,
          title: "Comprehensive Insights",
          description: "Advanced analytics provide actionable insights into customer behavior and sales performance."
        },
        {
          icon: secure,
          title: "Secure Transactions",
          description: "Fast, easy, and secure checkout process enhances customer satisfaction and loyalty."
        }
      ].map((item, index) => (
        <Grid 
          key={index} 
          item 
          lg={3.5} 
          md={3.5} 
          sm={4.5} 
          xs={12} 
          mt={3} 
          sx={{ mx: { sm: 2, md: 2, lg: 3 } ,border:"1px solid white"}}
          className="GridCard4"
        >
          <Box px={2} py={5} my={3}>
            <img src={item.icon} width={80} alt="icon service" />
            <Typography
    
              component="div"
              sx={{
                fontWeight: "700",
                mt: 3
              }}
            >
              {item.title}
            </Typography>
            <Typography
              component="div"
              sx={{
                mt: 2,
                lineHeight: "1.5rem",
                pb: 3
              }}
            >
              {item.description}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
                    </Container>
                </Box>
                <Box
          sx={{
            position: "relative",
            width: "100%",
            height: {
              xs: "400px", // Mobile view
              sm: "400px", // Other views
            },
            overflow: "hidden", // Ensure the image doesn't overflow the box
          }}
        >
          <img
            src={demo}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensure the image covers the box area
            }}
            alt="product bg"
          />
          <Button
            sx={{
              position: "absolute",
              zIndex: 999,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            variant="contained"
            className="WebButton"
            onClick={() => handleOpenDialog()}
          >
            Ask for Demo
          </Button>
        </Box>
                <Box padding={"5%"}>
                    <ProductSlider />
                </Box>
            </Box>

            <FooterPage footerRef={footerRef}/>
        </Box>
    )
}