import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import '../../Css/Style.css'
import FooterPage from '../../Components/Footer';
import { Accordion, Container } from 'react-bootstrap';
import AboutCarousel from '../../Images/Carousel/Aboutcarousel.png'
import NavigationBar from '../NavigationBar';
import IconService from '../../Images/icons/iconservice.png'
import IconSettings from '../../Images/icons/Group 282(1).png'
import IncreaseIcon from '../../Images/icons/Vector(3).png'
import ReduceIcon from '../../Images/icons/Group(2).png'
import UserExpIcon from '../../Images/icons/Vector(4).png'
import ResponseIcon from '../../Images/icons/Vector(5).png'
import ProductBg from '../../Images/Pages/product-bgimg.png'
import ProductSlider from '../ProductsSlider';
import { useRef } from 'react';
import rapdihr from '../../Images/rapidehr.png'
import demo from "../../Images/demo.jpg";
import scalable from "../../Images/productsicons/scalable.png"

import efficiency from '../../Images/stack/enhancedecr.png'
import report from "../../Images/stack/reporting.png"
import accsible from "../../Images/stack/records.png"
import care from "../../Images/stack/care.png"
import data from "../../Images/stack/dataprivacy.png"

export default function RapidEHRPage({handleOpenDialog}) {

    const footerRef = useRef(null);
    const scrollToFooter = () => {
        footerRef.current.scrollIntoView({ behavior: 'smooth' });
      };


      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const cards = [
        { id: "01", title: "Scheduling", description: "This feature can significantly improve efficiency and save time by automating recurring tasks. It also allows for better organization and planning of future events." },
        { id: "02", title: "e-Prescribing", description: "This eliminates the need for paper prescriptions and reduces the risk of errors caused by illegible handwriting or miscommunication." },
        { id: "03", title: "Medical Billing", description: "It is integrated with HIPAA standards to ensure the confidentiality and security of patient inform." },
        { id: "04", title: "CMS Reporting", description: "This feature streamlines the process of generating comprehensive reports, making it easy for users to access the information they need." },
        { id: "05", title: "Lab Integration", description: "This system eliminates the need for manual data entry and reduces the risk of errors, providing accurate and timely results." },
        { id: "06", title: "Clinical Decision Rules", description: "The advanced engine is designed to navigate complex patient care algorithms, making it easier for healthcare professionals to provide effective treatment." },
        { id: "07", title: "Advanced Security", description: "Advanced security features, specifically designed to comply with HIPAA regulations, are in place to protect sensitive information." },
        { id: "08", title: "Multilingual Support", description: "The multilingual support of a product, stating that it is available in over 30 languages and can be customized for more." },
        { id: "09", title: "Cloud-Based Deployment", description: "Cloud-based deployment offers the flexibility to choose between on-premise or cloud deployment options." }
    ];


    return (
        <Box>
            <Box sx={{ position: 'relative',height:{xs:"100vh",md:"auto"}  }}>
                <NavigationBar scrollToFooter={scrollToFooter}/>
                <img src={rapdihr} width="100%" alt="slider1" style={{height:"100vh",objectFit:"cover"}}/>
                <Box>
                    <Typography
                        variant="h2"
                        component="div"
                        sx={{
                            color: "#fff",
                            fontWeight: "700",
                            position: "absolute",
                            top: "55%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: '75%',
                            '@media (max-width: 720px)': {
                                fontSize: '1rem',
                                marginTop: "10px"
                            },
                            '@media (min-width: 601px) and (max-width: 960px)': {
                                fontSize: '2.5rem',
                            },
                            '@media (min-width: 961px)': {
                                fontSize: '2.5rem',
                            },
                        }}
                    >
                        Rapid Electronic Health <br/> Record
                        <Box sx={{
                            '@media (max-width: 720px)': {
                                
                            },
                        }}>
                            <Box sx={{ display: "flex" }}>
                                <Box sx={{
                                    fontSize: "1rem", width: "45%",mt:1,
                                    '@media (max-width: 720px)': {
                                        width: "100%",
                                        fontSize: ".7rem",

                                    },
                                }}>
                                    <Typography>
                                    Rapid EHR is a patient-centered approach to Electronic Health Records, prioritizing the needs of patients in their healthcare. It is a revolutionary method that aims to improve the overall quality and efficiency of healthcare by putting patients in control of their own health information.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Button className='WebButton' variant="contained" onClick={handleOpenDialog}>Request to Demo</Button>
                            </Box>
                        </Box>
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: "#1C1D22" }}>
                <Grid container spacing={3} paddingTop={"5%"} sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item lg={7} md={7} sm={12} xs={12} >
                        <Box sx={{ paddingLeft: {xs:"2%",lg:"13%"}, }}>
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{
                                    color: "#fff",
                                    fontWeight: "700",
                                    textAlign: "center",
                                    px: 4,
                                    fontFamily:"Montserrat",
                                    fontSize:{xs:"14px",md:"24px"}
                                }}
                            >
                                RapidQube Digital is a disruptive, results-driven, next-gen IT services provider solving the modern digital challenges and
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box p={4}>
            <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center",gap:5 }}>
                {cards.map((card, index) => {
                    const textColor = index % 2 === 0 ? "black" : "white";
                    const backgroundColor = index % 2 === 0 ? "#fff" : "#333";
                    const boxClass = index % 2 === 0 ? 'GridCard2' : 'GridCard3';

                    return (
                        <Grid key={index} className="GridCardHeight" item lg={3.5} md={3.5} sm={5.5} xs={12}>
                            <Box
                                className={boxClass}
                                p={2}
                                sx={{
                                    width: isMobile ? '400px' : 'auto',
                                   
                                    height: '230px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap:2,
                                    backgroundColor: backgroundColor,
                                    color: textColor,
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                        fontWeight: "700",
                                        color: "#07A117",
                                        
                                     
                                    }}
                                >
                                    {card.id}
                                </Typography>
                                <Typography
                                    
                                    component="div"
                                    sx={{
                                        fontWeight: "700",
                                        mt: 1,
                                      fontSize:"18px",
                                    }}
                                >
                                    {card.title}
                                </Typography>
                                <Typography
                                    component="div"
                                    sx={{
                                        
                                        lineHeight: "1.5rem",
                                        pb: 1,
                                       
                                    }}
                                >
                                    {card.description}
                                </Typography>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
                <Box sx={{ backgroundColor: "#2C2D2F", mt: 4, padding: "3%" }}>
                    <Container>
                        <Typography
                            variant="h3"
                            component="div"
                            sx={{
                                color: "#07A117",
                                fontWeight: "700",
                                fontSize:{xs:"21px",lg:"32px"}
                            }}
                        >
                           Why Rapid EHR Stands Out
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Grid container spacing={3} sx={{ paddingTop: "2%" }}>
                                    <Grid item lg={8} md={5} sm={12} xs={12} >
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                color: "#fff",
                                                fontWeight: "400",
                                                fontSize:{xs:"14px",md:"18px"}
                                            }}
                                        >
                                           Choose Rapid EHR to transform healthcare with improved data sharing, patient engagement, and streamlined workflows for enhanced care and efficiency.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
  container
  spacing={3}
  sx={{
    m:0,
    width:{xs:'100%',lg:'auto'},
    display:"flex",
    justifyContent: "center",
    mt: 3,
    gap:3,
    padding: { xs: "20px", lg: "0px" },
  }}
>
  {[
    {
      src: efficiency,
      title: "Enhanced Efficiency",
      description: "Automating routine tasks saves time and resources, allowing individuals and organizations to focus on complex, critical activities.",
    },
    {
      src: scalable,
      title: "Scalable Solutions",
      description: "This ensures seamless integration and scalability, making it a valuable tool for businesses of any size and growth.",
    },
    {
      src: report,
      title: "Comprehensive Reporting",
      description: "This solution offers efficient, simplified access to essential clinical and administrative reports, enhancing overall workflow.",
    },
    {
      src: accsible,
      title: "Accessible Records",
      description: "This provides a convenient and efficient way for individuals to manage and monitor their health information.",
    },
    {
      src: care,
      title: "Integrated Care",
      description: "This approach aims to improve patient outcomes and experiences by promoting communication and collaboration ",
    },
    {
      src: data,
      title: "Enhanced Data Privacy",
      description: "Robust security measures are in place to protect personal health information from unauthorized access.",
    },
  ].map((card, index) => (
    <Grid key={index} className="GridCard4" item lg={3.5} md={3.5} sm={4.5} xs={12} sx={{ mt: 3,border:"1px solid white" }}>
      <Box px={2} py={5} my={3}>
        <img src={card.src} width={80} alt="icon service" />
        <Typography
    
          component="div"
          sx={{ fontWeight: "bold", mt: 3,fontSize:"18px" }}
        >
          {card.title}
        </Typography>
        <Typography
          component="div"
          sx={{ mt: 2, lineHeight: "1.5rem", pb: 3 }}
        >
          {card.description}
        </Typography>
      </Box>
    </Grid>
  ))}
</Grid>

                    </Container>
                </Box>
                <Box
          sx={{
            position: "relative",
            width: "100%",
            height: {
              xs: "400px", // Mobile view
              sm: "400px", // Other views
            },
            overflow: "hidden", // Ensure the image doesn't overflow the box
          }}
        >
          <img
            src={demo}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensure the image covers the box area
            }}
            alt="product bg"
          />
          <Button
            sx={{
              position: "absolute",
              zIndex: 999,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            variant="contained"
            className="WebButton"
            onClick={() => handleOpenDialog()}
          >
            Ask for Demo
          </Button>
        </Box>
                <Box padding={"5%"}>
                    <ProductSlider />
                </Box>
            </Box>

            <FooterPage footerRef={footerRef}/>
        </Box>
    )
}