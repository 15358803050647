import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useRef } from "react";
import AboutImage from "../../Images/About.png";
import "../../Css/Style.css";
import TimeSet from "../../Images/icons/timesetting.png";
import FooterPage from "../../Components/Footer";
import { Accordion, Container } from "react-bootstrap";
import AboutCarousel from "../../Images/Carousel/Aboutcarousel.png";
import CheckCircleIcon from "../../Images/icons/check-circle-broken.png";
import CustomizedAccordions from "../../Components/Accordion";
import TestimonialSlider from "../../Components/ModelCardSlider";
import NavigationBar from "../NavigationBar";
import IconService from "../../Images/icons/iconservice.png";
import IconSettings from "../../Images/icons/Group 282(1).png";
import IncreaseIcon from "../../Images/icons/Vector(3).png";
import ReduceIcon from "../../Images/icons/Group(2).png";
import UserExpIcon from "../../Images/icons/Vector(4).png";
import ResponseIcon from "../../Images/icons/Vector(5).png";
import ProductBg from "../../Images/Pages/product-bgimg.png";
import ProductSlider from "../ProductsSlider";
import Talkifyimg from "../../Images/talkify.png";
import demo from "../../Images/demo.jpg";
import communication from "../../Images/productsicons/communication.png"
import engagement from "../../Images/productsicons/engagement.png"
import growth from "../../Images/productsicons/growth.png"
import quality from "../../Images/productsicons/quality.png"
import sales from "../../Images/productsicons/sales.png"
import scalable from "../../Images/productsicons/scalable.png"












export default function TalkifyPage({ handleOpenDialog }) {
  const footerRef = useRef(null);
  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const features = [
    {
      id: "01",
      title: "Gen AI",
      description:
        "Gen AI is a cutting-edge platform that harnesses the power of artificial intelligence to produce a wide range of creative text.",
    },
    {
      id: "02",
      title: "Natural Language Processing",
      description:
        "Natural Language Processing (NLP) is a technology that helps machines understand and interpret human language.",
    },
    {
      id: "03",
      title: "Ticketing System",
      description:
        "A ticketing system automates the process of creating support tickets, streamlining customer support and improving efficiency.",
    },
    {
      id: "04",
      title: "ERP Integration",
      description:
        "ERP integration allows for a smooth and efficient connection between your current Enterprise Resource Planning system and other software or technologies.",
    },
    {
      id: "05",
      title: "24/7 Availability",
      description:
        "This allows for a high level of convenience and responsiveness for customers, leading to improved satisfaction and retention.",
    },
    {
      id: "06",
      title: "Multilingual Support",
      description:
        "Multilingual support allows businesses to communicate with customers in various languages.",
    },
    {
      id: "07",
      title: "Sentiment Analysis",
      description:
        "Detect customer emotions and adapt responses to improve engagement and interaction quality.",
    },
    {
      id: "08",
      title: "Personalized Conversations",
      description:
        "Tailor interactions based on customer history and preferences for a more personalized experience.",
    },
    {
      id: "09",
      title: "Analytics Dashboard",
      description:
        "Monitor chatbot performance and gain insights into customer interactions with a comprehensive dashboard.",
    },
  ];

  return (
    <Box sx={{ fontFamily: "Montserrat" }}>
      <Box sx={{ position: "relative", height: { xs: "100vh", md: "auto" } }}>
        <NavigationBar scrollToFooter={scrollToFooter} />
        <img
          src={Talkifyimg}
          width="100%"
          alt="slider1"
          style={{ height: "100vh", objectFit: "cover" }}
        />
        <Box>
          <Typography
            variant="h2"
            component="div"
            sx={{
              color: "#fff",
              fontWeight: "700",
              position: "absolute",
              fontFamily: "Montserrat",
              top: "55%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "75%",
              "@media (max-width: 720px)": {
                fontSize: "1rem",
                marginTop: "10px",
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "2.5rem",
              },
              "@media (min-width: 961px)": {
                fontSize: "2.5rem",
              },
            }}
          >
            FEATURES THAT MAKE
            <br /> SELLING EASIER
            <Box
              sx={{
                "@media (max-width: 720px)": {},
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    fontSize: "1rem",
                    width: "45%",
                    "@media (max-width: 720px)": {
                      width: "100%",
                      fontSize: ".7rem",
                    },
                  }}
                >
                  <Typography
                    sx={{ margin: "10px 0px", fontFamily: "Montserrat" }}
                  >
                    Experience the future of customer interaction with Talkify,
                    the ultimate Conversational AI solution designed to
                    transform the way you engage with your customers. Talkify
                    revolutionizes customer service, automates routine tasks,
                    and boosts efficiency, offering a seamless blend of advanced
                    AI and user-friendly design.
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Button
                  className="WebButton"
                  sx={{ fontFamily: "Montserrat" }}
                  variant="contained"
                  onClick={handleOpenDialog}
                >
                  Request a Demo
                </Button>
              </Box>
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#1C1D22" }}>
        <Grid
          container
          spacing={3}
          paddingTop={"5%"}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <Box
              sx={{
                paddingLeft: { xs: "2%", md: "13%" },
                fontSize: { xs: "14px", md: "24px" },
              }}
            >
              <Typography
                variant="h5"
                component="div"
                sx={{
                  color: "#fff",
                  fontWeight: "700",
                  textAlign: "center",
                  px: 4,
                  fontSize: { xs: "14px", md: "24px" },
                }}
              >
                RapidQube Digital is a disruptive, results-driven, next-gen IT
                services provider solving the modern digital challenges and
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box p={4}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center", gap: 5 }}
          >
            {features.map((feature, index) => {
              const isOdd = index % 2 !== 0;
              const boxClass = isOdd ? "GridCard3" : "GridCard2";
              const textColor = isOdd ? "white" : "black";
              const backgroundColor = isOdd ? "#333" : "#fff";

              return (
                <Grid
                  key={index}
                  className="GridCardHeight"
                  item
                  lg={3.5}
                  md={3.5}
                  sm={5.5}
                  xs={12}
                >
                  <Box
                    className={boxClass}
                    p={2}
                    sx={{
                      backgroundColor: backgroundColor,
                      color: textColor,
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      height:"230px",
                      gap: 2,

                      width: isMobile ? "400px" : "auto",
                    }}
                  >
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontWeight: "700",
                        color: "#07A117",
                        mt: 1,
                      }}
                    >
                      {feature.id}
                    </Typography>
                    <Typography
                    
                      component="div"
                      sx={{
                        fontWeight: "bold",
                      
                       fontSize:"18px",
                        color: textColor,
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      variant=""
                      component="div"
                      sx={{
                    
                        lineHeight: "1.4rem",
                        pb: 1,
                        fontSize:"16px",
                        color: textColor,
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box sx={{ backgroundColor: "#2C2D2F", mt: 4, padding: "3%" }}>
          <Container className="mblviewcontainer">
            <Typography
              variant="h3"
              component="div"
              sx={{
                color: "#07A117",
                fontWeight: "700",
                fontFamily: "Montserrat",
                fontSize: { xs: "21px", lg: "32px" },
              }}
            >
              Why Talkify Stands Out
            </Typography>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={3} sx={{ paddingTop: "20px" }}>
                  <Grid item lg={8} md={5} sm={12} xs={12}>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: "Montserrat",
                        fontSize: { xs: "14px", md: "22px" },
                      }}
                    >
                      Choose Talkify for its transformative conversational AI
                      that enhances customer service, boosts efficiency, and
                      drives exceptional business growth.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              sx={{
                m: 0,
                display: "flex",
                justifyContent: "center",
                mt: 3,
                padding: { xs: "20px", lg: "0px" },
              }}
            >
              {[
                {
                  src: communication,
                  title: "Improved Customer Satisfaction",
                  description:
                   "Resolve inquiries more efficiently and deliver exceptional service to enhance overall customer experience.",
                },
                {
                  src: sales,
                  title: "Increase Sales",
                  description:
                   "Convert website visitors into paying customers through tailored support and personalized engagement strategies.",
                },
                {
                  src:growth,
                  title: "Increasing Efficiency",
                  description:
                    "Automate routine tasks to enhance operations, freeing up staff to tackle more complex issues and boost productivity.",
                },
                {
                  src: scalable,
                  title: "Scalable",
                  description:
                   "Address customer inquiries immediately, minimizing wait times and enhancing overall customer satisfaction and efficiency.",
                },
                {
                  src: quality,
                  title: "Consistent Quality",
                  description:
                    "Deliver uniform, high-quality responses in every customer interaction to maintain excellence and reliability.",
                },
                {
                  src: engagement,
                  title: "Proactive Engagement",
                  description:
                    "Anticipate customer needs and initiate assistance proactively, addressing issues before they are raised.",
                },
              ].map((card, index) => (
                <Grid
                  className="GridCard4"
                  key={index}
                  item
                  lg={3.5}
                  md={3.5}
                  sm={4.5}
                  xs={12}
                  sx={{ mt: 3, m: 2, border: "1px solid white" }}
                >
                  <Box sx={{ px: 2, py: 5, my: 3 }}>
                    <img src={card.src} width={80} alt={card.title} />
                    <Typography
                  
                      component="div"
                      sx={{ fontWeight: "700", mt: 3 }}
                    >
                      {card.title}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{ mt: 2, lineHeight: "1.5rem", pb: 3 }}
                    >
                      {card.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: {
              xs: "400px", // Mobile view
              sm: "400px", // Other views
            },
            overflow: "hidden", // Ensure the image doesn't overflow the box
          }}
        >
          <img
            src={demo}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensure the image covers the box area
            }}
            alt="product bg"
          />
          <Button
            sx={{
              position: "absolute",
              zIndex: 999,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            variant="contained"
            className="WebButton"
            onClick={() => handleOpenDialog()}
          >
            Ask for Demo
          </Button>
        </Box>
        <Box padding={"5%"}>
          <ProductSlider />
        </Box>
      </Box>

      <FooterPage footerRef={footerRef} />
    </Box>
  );
}
