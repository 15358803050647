import { Box, Button, Grid, List, ListItem, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useRef } from 'react';
import '../../Css/Style.css'
import FooterPage from '../../Components/Footer';
import { Accordion, Container } from 'react-bootstrap';
import AboutCarousel from '../../Images/Carousel/Aboutcarousel.png'
import NavigationBar from '../NavigationBar';
import IconService from '../../Images/icons/iconservice.png'
import IconSettings from '../../Images/icons/Group 282(1).png'
import IncreaseIcon from '../../Images/icons/Vector(3).png'
import ReduceIcon from '../../Images/icons/Group(2).png'
import UserExpIcon from '../../Images/icons/Vector(4).png'
import ResponseIcon from '../../Images/icons/Vector(5).png'
import ProductBg from '../../Images/Pages/product-bgimg.png'
import ProductSlider from '../ProductsSlider';
import rapidproperty from "../../Images/rapidproperty.png"
import demo from "../../Images/demo.jpg";
import business from "../../Images/stack/business.png"
import intgration from "../../Images/stack/intgration.png"
import ready from "../../Images/stack/future.png"
import compalince from "../../Images/stack/complaince.png"
import sales from "../../Images/productsicons/sales.png"
import scalable from "../../Images/productsicons/scalable.png"

export default function RapidProperty({handleOpenDialog}) {


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  


    const cardData = [
        {
          icon: business,
          title: "Optimized Business Processes",
          description: "Streamlines the entire real estate transaction process, enhancing efficiency and simplifying each step of the transaction."
        },
        {
          icon: sales,
          title: "Increased Transactional Efficiency",
          description: "Enhances transactional efficiency by leveraging automation and smart technology to streamline and optimize the entire process."
        },
        {
          icon: intgration,
          title: "Seamless Integration",
          description: "Offers seamless integration with other business applications, enhancing connectivity and system interoperability."
        },
        {
          icon: scalable,
          title: "Business Scalability",
          description: "Cloud infrastructure and scalable solutions ensure the platform grows with your business needs."
        },
        {
          icon: ready,
          title: "Future Ready",
          description: "Incorporates future-ready technologies and automated processes enabling continuous improvements."
        },
        {
          icon: compalince,
          title: "Improved Compliance",
          description: "Enhances auditability and regulatory compliance, reducing risks associated with real estate transactions."
        }
      ];


    const footerRef = useRef(null);
    const scrollToFooter = () => {
        footerRef.current.scrollIntoView({ behavior: 'smooth' });
      };

    const cards = [
        { id: "01", title: "Property Listings", description: "Allows sellers to list properties for buyers to view and rent." },
        { id: "02", title: "Document Collection", description: "Notary team collects all necessary documents and details from buyers and sellers." },
        { id: "03", title: "Validation and Verification", description: "Documents are submitted to relevant government departments or agencies for validation." },
        { id: "04", title: "Smart Calculator", description: "Ensures that the correct fee is charged for transactions by calculating property value and associated fees." },
        { id: "05", title: "Title Deed and Rental Agreement Processing", description: "Facilitates the processing and transfer of title deeds or rental agreements upon validation." },
        { id: "06", title: "Blockchain Integration", description: "Utilizes blockchain technology for secure, transparent, and immutable records of transactions." },
        { id: "07", title: "Real-Time Tracking", description: "Provides real-time updates and tracking of the transaction process for all parties involved." },
        { id: "08", title: "User Roles and Permissions", description: "Supports multiple user roles, including sellers, buyers, notaries, and government agencies, with specific permissions and functionalities." },
        { id: "09", title: "Audit and Compliance", description: "Ensures auditability and traceability with respect to who, why, and when for audit and regulatory compliance." }
    ];

    return (
        <Box>
            <Box sx={{ position: 'relative',height:{xs:"100vh",md:"auto"} }}>
                <NavigationBar scrollToFooter={scrollToFooter}/>
                <img src={rapidproperty} width="100%" alt="slider1" style={{height:"100vh",objectFit:"cover"}}/>
                <Box>
                    <Typography
                        variant="h2"
                        component="div"
                        sx={{
                            color: "#fff",
                            fontWeight: "700",
                            position: "absolute",
                            top: "55%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: '75%',
                            '@media (max-width: 720px)': {
                                fontSize: '1rem',
                                marginTop: "10px"
                            },
                            '@media (min-width: 601px) and (max-width: 960px)': {
                                fontSize: '2.5rem',
                            },
                            '@media (min-width: 961px)': {
                                fontSize: '2.5rem',
                            },
                        }}
                    >
                        Rapid Property - Title Deed<br/> Transfer
                        <Box sx={{
                            '@media (max-width: 720px)': {
                              
                            },
                        }}>
                            <Box sx={{ display: "flex" }}>
                                <Box sx={{
                                    fontSize: "1rem", width: "45%",mt:1,
                                    '@media (max-width: 720px)': {
                                        width: "100%",
                                        fontSize: ".7rem",

                                    },
                                }}>
                                    <Typography>
                                    The Digital Application for Title Deed Transfer Service in Bahrain helps facilitate the transfer of title deeds between buyers and sellers.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Button className='WebButton' variant="contained" onClick={handleOpenDialog}>Request to Demo</Button>
                            </Box>
                        </Box>
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: "#1C1D22" }}>
                <Grid container spacing={3} paddingTop={"5%"} sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item lg={7} md={7} sm={12} xs={12} >
                        <Box sx={{ paddingLeft:{xs:"2%",lg:"13%"} }}>
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{
                                    color: "#fff",
                                    fontWeight: "700",
                                    textAlign: "center",
                                    px: 4,
                                    fontSize:{xs:"14px",md:"24px"}
                                }}
                            >
                                RapidQube Digital is a disruptive, results-driven, next-gen IT services provider solving the modern digital challenges and
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box p={4}>
            <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center",gap:5 }}>
                {cards.map((card, index) => {
                    const textColor = index % 2 === 0 ? "black" : "white";
                    const backgroundColor = index % 2 === 0 ? "#fff" : "#333";
                    const boxClass = index % 2 === 0 ? 'GridCard2' : 'GridCard3';

                    return (
                        <Grid key={index} className="GridCardHeight" item lg={3.5} md={3.5} sm={5.5} xs={12}>
                            <Box
                                className={boxClass}
                                p={2}
                                sx={{
                                    width: isMobile ? '400px' : 'auto',
                                    height: '230px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap:2,
                                    backgroundColor: backgroundColor,
                                    color: textColor,
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                        fontWeight: "700",
                                        color: "#07A117",
                                      
                                        
                                    }}
                                >
                                    {card.id}
                                </Typography>
                                <Typography
                                    
                                    component="div"
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize:"18px"
                                        
                                    }}
                                >
                                    {card.title}
                                </Typography>
                                <Typography
                                    component="div"
                                    sx={{
                                    
                                        lineHeight: "1.5rem",
                                        pb: 1,
                                        
                                       
                                    }}
                                >
                                    {card.description}
                                </Typography>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
                <Box sx={{ backgroundColor: "#2C2D2F", mt: 4, padding: "3%" }}>
                    <Container>
                        <Typography
                            variant="h3"
                            component="div"
                            sx={{
                                color: "#07A117",
                                fontWeight: "700",
                                fontSize:{xs:"21px",lg:"32px"}
                            }}
                        >
                            Why Rapid Property Stands Out
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Grid container spacing={3} sx={{ paddingTop: "2%" }}>
                                    <Grid item lg={8} md={5} sm={12} xs={12} >
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                color: "#fff",
                                                fontWeight: "400",
                                                fontSize:{xs:"14px",lg:"18px"}
                                            }}
                                        >
                                          Choose Rapid EHR to transform healthcare with improved data sharing, patient engagement, and streamlined workflows for enhanced care and efficiency.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
      container
      spacing={3}
      sx={{ 
        display: "flex", 
        justifyContent: "center", 
        mt: 3, 
        m:0,
        width:{xs:'100%',lg:'auto'},
        padding: { xs: "20px", lg: "0px" } 
      }}
    >
      {cardData.map((item, index) => (
        <Grid
          key={index}
          className="GridCard4"
          item
          lg={3.5}
          md={3.5}
          sm={4.5}
          xs={12}
          mt={3}
          sx={{ mx: { sm: 2, md: 1.5, lg: 3 },border:"1px solid white" }}
        >
          <Box px={2} py={5} my={3}>
            <img src={item.icon} width={80} alt="icon service" />
            <Typography
        
              component="div"
              sx={{
                fontWeight: "700",
                mt: 3
              }}
            >
              {item.title}
            </Typography>
            <Typography
              component="div"
              sx={{
                mt: 2,
                lineHeight: "1.5rem",
                pb: 3
              }}
            >
              {item.description}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
                    </Container>
                </Box>
                <Box
          sx={{
            position: "relative",
            width: "100%",
            height: {
              xs: "400px", // Mobile view
              sm: "400px", // Other views
            },
            overflow: "hidden", // Ensure the image doesn't overflow the box
          }}
        >
          <img
            src={demo}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensure the image covers the box area
            }}
            alt="product bg"
          />
          <Button
            sx={{
              position: "absolute",
              zIndex: 999,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            variant="contained"
            className="WebButton"
            onClick={() => handleOpenDialog()}
          >
            Ask for Demo
          </Button>
        </Box>
                <Box padding={"5%"}>
                    <ProductSlider />
                </Box>
            </Box>

            <FooterPage footerRef={footerRef}/>
        </Box>
    )
}