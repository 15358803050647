import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useRef } from "react";
import AboutImage from "../../Images/About.png";
import "../../Css/Style.css";
import TimeSet from "../../Images/icons/timesetting.png";
import FooterPage from "../../Components/Footer";
import { Accordion, Container } from "react-bootstrap";
import AboutCarousel from "../../Images/Carousel/Aboutcarousel.png";
import CheckCircleIcon from "../../Images/icons/check-circle-broken.png";
import CustomizedAccordions from "../../Components/Accordion";
import TestimonialSlider from "../../Components/ModelCardSlider";
import NavigationBar from "../NavigationBar";
import IconService from "../../Images/icons/iconservice.png";
import IconSettings from "../../Images/icons/Group 282(1).png";
import IncreaseIcon from "../../Images/icons/Vector(3).png";
import ReduceIcon from "../../Images/icons/Group(2).png";
import UserExpIcon from "../../Images/icons/Vector(4).png";
import ResponseIcon from "../../Images/icons/Vector(5).png";
import ProductBg from "../../Images/Pages/product-bgimg.png";
import ProductSlider from "../ProductsSlider";
import rapidverse from "../../Images/rapidverse.png";
import demo from "../../Images/demo.jpg";
import spaces from "../../Images/productsicons/spaces.png";
import sales from "../../Images/productsicons/sales.png";
import freedom from "../../Images/productsicons/freedom.png"
import collabration from "../../Images/productsicons/collabrations.png"
import sclable from "../../Images/productsicons/scalable.png"
import engamenttwo from "../../Images/productsicons/engamenttwo.png"











export default function RapidVersePage({ handleOpenDialog }) {
  const footerRef = useRef(null);
  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const features = [
    {
      id: "01",
      title: "Virtual Workspaces",
      description:
        "Create dynamic and collaborative virtual offices, meeting rooms, and co-working spaces that boost productivity and creativity.",
    },
    {
      id: "02",
      title: "Social Interaction",
      description:
        "Foster social connections with customizable avatars, interactive environments, and real-time communication tools.",
    },
    {
      id: "03",
      title: "Event Hosting",
      description:
        "Host immersive virtual events, from conferences and trade shows to concerts and exhibitions, with interactive features and live streaming capabilities.",
    },
    {
      id: "04",
      title: "Training and Education",
      description:
        "Enhance learning experiences with virtual classrooms, interactive training sessions, and educational resources.",
    },
    {
      id: "05",
      title: "Retail and E-commerce",
      description:
        "Leverage virtual spaces for innovative retail experiences and seamless e-commerce transactions.",
    },
    {
      id: "06",
      title: "Robust Security",
      description:
        "Protect your virtual spaces with advanced security features that safeguard your data and privacy.",
    },
    {
      id: "07",
      title: "Cost-Effective Solutions",
      description:
        "Achieve your virtual ambitions without compromising on quality or budget, thanks to our competitive pricing models.",
    },
    {
      id: "08",
      title: "Cutting-Edge Technology",
      description:
        "Stay ahead with the latest technological advancements, ensuring superior performance and capabilities.",
    },
    {
      id: "09",
      title: "User-Friendly Interface",
      description:
        "Enjoy an intuitive and easy-to-navigate platform that ensures a seamless user experience.",
    },
  ];

  return (
    <Box>
      <Box sx={{ position: "relative", height: { xs: "100vh", md: "auto" } }}>
        <NavigationBar scrollToFooter={scrollToFooter} />
        <img
          src={rapidverse}
          width="100%"
          alt="slider1"
          style={{ height: "100vh", objectFit: "cover" }}
        />
        <Box>
          <Typography
            variant="h2"
            component="div"
            sx={{
              color: "#fff",
              fontWeight: "700",
              position: "absolute",
              top: "55%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "75%",
              "@media (max-width: 720px)": {
                fontSize: "1rem",
                marginTop: "10px",
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "2.5rem",
              },
              "@media (min-width: 961px)": {
                fontSize: "2.5rem",
              },
            }}
          >
            Discover a New
           Dimension  <br /> with
             RapidVerse
            <Box
              sx={{
                "@media (max-width: 720px)": {},
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    fontSize: "1rem",
                    width: "45%",
                    mt:1,
                    "@media (max-width: 720px)": {
                      width: "100%",
                      fontSize: ".7rem",
                    },
                  }}
                >
                  <Typography>
                    Experience the next level of digital interaction with
                    RapidVerse, our groundbreaking Metaverse solution. Designed
                    to transform the way you connect, collaborate, and create,
                    RapidVerse offers immersive experiences that seamlessly
                    blend the physical and virtual worlds.
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Button
                  className="WebButton"
                  variant="contained"
                  onClick={handleOpenDialog}
                >
                  Request to Demo
                </Button>
              </Box>
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#1C1D22" }}>
        <Grid
          container
          spacing={3}
          paddingTop={"5%"}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <Box sx={{ paddingLeft: { xs: "2%", md: "13%" } }}>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  color: "#fff",
                  fontWeight: "700",
                  textAlign: "center",
                  px: 4,
                  fontSize: { xs: "14px", md: "24px" },
                }}
              >
                RapidQube Digital is a disruptive, results-driven, next-gen IT
                services provider solving the modern digital challenges and
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box p={4}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center", gap: 5 }}
          >
            {features.map((card, index) => {
              const textColor = index % 3 === 1 ? "white" : "black"; // Column 2 is white, others are black
              const backgroundColor = index % 3 === 1 ? "transparent" : "#fff"; // Column 2 has a different background

              return (
                <Grid
                  key={index}
                  className="GridCardHeight"
                  item
                  lg={3.5}
                  md={3.5}
                  sm={5.5}
                  xs={12}
                >
                  <Box
                    className={`GridCard${index % 2 === 0 ? "2" : "3"}`}
                    p={2}
                    sx={{
                      width: isMobile ? '400px' : 'auto',
                      height:"230px",
                      display: "flex",
                      flexDirection: "column",
                      gap:2,
                      backgroundColor: backgroundColor,
                      color: textColor,
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontWeight: "700",
                        color: "#07A117",
                        mt: 1,
                        
                      }}
                    >
                      {card.id}
                    </Typography>
                    <Typography
                      
                      component="div"
                      sx={{
                        fontWeight: "bold",
                        
                        fontSize:"18px",
                      }}
                    >
                      {card.title}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                  
                        lineHeight: "1.5rem",
                        pb: 1,
                     
                      }}
                    >
                      {card.description}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box sx={{ backgroundColor: "#2C2D2F", mt: 4, padding: "3%"}}>
          <Container sx={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <Typography
              variant="h3"
              component="div"
              sx={{
                color: "#07A117",
                fontWeight: "700",
                fontSize: { xs: "21px", lg: "32px" },
              }}
            >
           Why RapidVerse Stands Out
            </Typography>
            <Grid container spacing={3} >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={3}>
                  <Grid item lg={8} md={5} sm={12} xs={12}>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        color: "#fff",
                        fontWeight: "400",
                        margin: "10px 0px",
                        fontSize: { xs: "14px", md: "18px" },
                      }}
                    >
                     Choose RapidVerse to revolutionize your digital interactions with immersive, scalable, and engaging Metaverse solutions that enhance connectivity and collaboration.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
      container
      spacing={3}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap:3,
        mt: 3,
        m:0,
        padding: { xs: '20px', lg: '0px' },
        width:{xs:'100%',lg:'auto'}
      }}
    >
      {[
        {
          src: spaces,
          title: 'Customizable Spaces',
          description: 'Tailor virtual environments to meet your specific needs, whether for business meetings, social gatherings, or educational purposes.',
        },
        {
          src: sales,
          title: 'Increase Sales',
          description: 'Enter fully interactive environments that captivate and engage users, providing an unparalleled sense of immersion.',
        },
        {
          src: collabration,
          title: 'Enhanced Collaboration',
          description: 'Facilitate seamless collaboration and communication with tools designed for virtual teamwork, regardless of your location.',
        },
        {
          src: sclable,
          title: 'Scalable',
          description: 'For businesses, RapidVerse offers scalable solutions that can be tailored to fit any size or type of organization',
        },
        {
          src: engamenttwo,
          title: 'Innovative Engagement',
          description: 'Engage your audience with virtual events, conferences, product launches, and immersive training sessions.',
        },
        {
          src: freedom,
          title: 'Creative Freedom',
          description: 'Build and customize your virtual spaces, create interactive content, and share your creations with the world.',
        },
      ].map((card, index) => (
        <Grid
          key={index}
          className="GridCard4"
          item
          lg={3.5}
          md={3.5}
          sm={5.5}
          xs={12}
          sx={{ m:3,mt: 3, mx: { sm: 0, xs: 0 }, my: { lg: 3, xs: 1.5 },border:"1px solid white" }}
        >
          <Box sx={{ px: 2, py: 5 }}>
            <img src={card.src} width={80} alt={card.title} />
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontWeight: '700',
                mt: 3,
              }}
            >
              {card.title}
            </Typography>
            <Typography
              component="div"
              sx={{
                mt: 2,
                lineHeight: '1.5rem',
                pb: 3,
              }}
            >
              {card.description}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
          </Container>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: {
              xs: "400px", // Mobile view
              sm: "400px", // Other views
            },
            overflow: "hidden", // Ensure the image doesn't overflow the box
          }}
        >
          <img
            src={demo}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensure the image covers the box area
            }}
            alt="product bg"
          />
          <Button
            sx={{
              position: "absolute",
              zIndex: 999,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            variant="contained"
            className="WebButton"
            onClick={() => handleOpenDialog()}
          >
            Ask for Demo
          </Button>
        </Box>
        <Box padding={"5%"}>
          <ProductSlider />
        </Box>
      </Box>

      <FooterPage footerRef={footerRef} />
    </Box>
  );
}
