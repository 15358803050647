import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Container,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import serviceIcon from "../Images/icons/iconservice.png";
import serviceimg from "../Images/service.jpg";
import group from "../Images/Group.png";
import servicelogoone from "../Images/Pages/servicelogo (6).png"
import servicelogotwo from "../Images/Pages/servicelogo (5).png"
import servicelogothree from "../Images/Pages/servicelogo (4).png"
import servicelogofour from "../Images/Pages/servicelogo (3).png"
import servicelogofive from "../Images/Pages/servicelogo (2).png"
import servicelogosix from "../Images/Pages/servicelogo (1).png"
import logoone from "../Images/Pages/iconsbl (1).png"
import logotwo from "../Images/Pages/iconsbl (2).png"
import logothree from "../Images/Pages/iconsbl (3).png"
import logofour from "../Images/Pages/iconsbl (4).png"
import logofive from "../Images/Pages/iconsbl (5).png"
import logosix from "../Images/Pages/iconsbl (6).png"

const cardStyle = {
  width: "100%",
  height: "305px",
  position: "relative",
  overflow: "hidden",

  color: "white",
  perspective: "1000px",
  borderRadius: "8px",
};

const flipInnerStyle = (isHovered) => ({
  transformStyle: "preserve-3d",
  transition: "transform 0.6s",
  position: "absolute",
  width: "100%",
  height: "100%",
  transform: isHovered ? "rotateY(180deg)" : "rotateY(0deg)",
});

const flipSideStyle = {
  backfaceVisibility: "hidden",
  position: "absolute",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
};

const flipFrontStyle = {
  ...flipSideStyle,
  transform: "rotateY(0deg)",
  backgroundColor: "#3E4046",
};

const flipBackStyle = {
  ...flipSideStyle,
  transform: "rotateY(180deg)",
  color: "black",
  backgroundColor: "#ffffff",
};

const CardComponent = ({ title, description,image }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
      style={cardStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{ ...flipInnerStyle(isHovered) }}>
        <div style={flipFrontStyle}>
          <CardContent
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={image} width={"100"} alt="icon" />
              </div>
              <Typography
                style={{
                  marginTop: "16px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {title}
              </Typography>
            </div>
          </CardContent>
        </div>
        <div style={flipBackStyle}>
          <CardContent
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography style={{ textAlign: "center" }}>
              {description}
            </Typography>
          </CardContent>
        </div>
      </div>
    </Card>
  );
};
const CardComponentTwo = ({ title, description,image }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
      style={cardStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{ backgroundColor: "white", color: "black", padding: "10px" }}
      >
        <div>
          <CardContent
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div>
              <div style={{ display: "flex", justifyContent: "start" }}>
                <img
                  src={image}
                  style={{ width: "50px", height: "50px" }}
                  alt="icon"
                />
              </div>
              <Typography
                style={{
                  marginTop: "10px",
                  textAlign: "left",
                  fontWeight: "bold",
                  color: "#02720D",
                }}
              >
                {title}
              </Typography>
            </div>
          </CardContent>
        </div>
        <div>
          <CardContent
            style={{ display: "flex", justifyContent: "start", height: "auto" }}
          >
            <Typography style={{ textAlign: "start" }}>
              {description}
            </Typography>
          </CardContent>
        </div>
      </div>
    </Card>
  );
};

const RotateCard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [visibleCards, setVisibleCards] = useState(3);

  const [showAll, setShowAll] = useState(false); // State to toggle between showing all and fewer items

  const handleShowMore = () => {
    setVisibleCards(items.length);
    setShowAll(true);
  };

  const handleShowLess = () => {
    setVisibleCards(3); // Set to the initial number of visible cards
    setShowAll(false);
  };

  const items = [
    {
      image:isMobile ? logotwo  : servicelogoone ,
      title: "Cloud & Infrastructure",
      description:
        "Optimizing cloud and IT management for seamless performance, scalability, and reliable operations.",
    },
    {
      image:isMobile ? logothree : servicelogotwo,
      title: "Application Management Services",
      description:
        "Ensuring high-performance, secure, and reliable application management tailored to your business goals.",
    },
    {
      image:isMobile ? logoone : servicelogothree,
      title: "Product Development",
      description:
        "From ideation to launch, we develop innovative products that drive business growth.",
    },
    {
      image:isMobile ?  logofive   : servicelogofour,
      title: "Customer Experience",
      description:
      "We create seamless web, mobile, and omnichannel experiences to boost customer engagement and loyalty.",
        
    },
    {
      image:isMobile ? logofour :servicelogofive,
      title: "Data & Intelligence",
      description:
       "We enhance customer engagement with seamless web, mobile, and omnichannel experiences for optimal satisfaction.",
    },
    {
      image:isMobile ? logosix : servicelogosix,
      title: "Advisory, Consulting & Education Services",
      description:
        "Expert advice, consultancy, and education to navigate IT challenges and achieve business goals.",
    },
  ];

  const renderMobileLayout = () => (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ position: "relative" }}>
          <img
            src={serviceimg}
            width={"100%"}
            alt="about image"
            style={{ opacity: 0.3 }}
          />
          <Box
            sx={{
              position: "absolute",
              top: "30%",
              left: "5%",
              padding: "20px",
            }}
          >
            <Typography
              sx={{ fontSize: "18px", color: "#07A117", fontWeight: "bold",  fontFamily: "Poppins" }}
            >
              Our Services
            </Typography>
            <Typography sx={{ fontSize: "18px",fontFamily: "Poppins" }}>
              Partner with us to unlock your business's full potential.
            </Typography>
            <Link to="services">
              <Button
                sx={{
                  backgroundColor: "#07A117",
                  color: "white",
                  "&:hover": { backgroundColor: "#07A117" },
                  margin: "10px 0px",
                  fontFamily: "Poppins"
                }}
              >
                Explore Our Services
              </Button>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {items.slice(0, visibleCards).map((item, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <CardComponentTwo
                title={item.title}
                description={item.description}
                image={item.image}
              />
            </Box>
          ))}
        </Grid>
        {showAll ? (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              onClick={handleShowLess}
              sx={{
                borderBottom: "2px solid #07A117",
                backgroundColor: "transparent",
                color: "#07A117",
              }}
            >
              View Less
            </Button>
          </Grid>
        ) : (
          visibleCards < items.length && (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={handleShowMore}
                sx={{
                  backgroundColor: "#07A117",
                  color: "white",
                  "&:hover": { backgroundColor: "#07A117" },
                }}
              >
                View all Services
              </Button>
            </Grid>
          )
        )}
      </Grid>
    </Container>
  );

  const renderLaptopLayout = () => (
    <Container>
      <Grid container spacing={3} p={1}>
        <Grid item lg={4} md={4} sm={12} xs={12} sx={{ position: "relative" }}>
          <img
            src={serviceimg}
            width={"100%"}
            height={"100%"}
            alt="about image"
            style={{ opacity: 0.3 }}
          />
          <Box
            sx={{
              position: "absolute",
              top: { xs: "30%", md: "30%" },
              left: "5%",
              padding: { xs: "20px", lg: 4 },
              display: "flex",
              alignItems: "flex-start", // Align items to the start (top)
              maxWidth: "calc(100% - 40px)", // Ensure the content fits within the grid item
            }}
          >
            <Box
              sx={{
                width: "5px", // Line width
                height: "180px", // Line height to adjust based on content
                backgroundColor: "#07A117", // Green color
                marginRight: "12px", // Space between the line and text
              }}
            />
            <Box>
              <Typography
                sx={{
                  fontSize: { xs: "24px", md: "28px" },
                  color: "#07A117",
                  fontWeight: "bold",
                  
                }}
              >
                Our Services
              </Typography>
              <Typography sx={{ fontSize: { xs: "18px", md: "20px" } }}>
                Partner with us to unlock your business's full potential.
              </Typography>
              <Link to="services">
                <Button
                  sx={{
                    backgroundColor: "#07A117",
                    color: "white",
                    "&:hover": { backgroundColor: "#07A117" },
                    margin: { xs: "10px 0px", lg: "10px 0px" },
                  }}
                >
                  Explore Our Services
                </Button>
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Grid container spacing={3}>
            {items.map((item, index) => (
              <Grid item lg={4} md={6} sm={6} xs={12} key={index}>
                <CardComponent
                  title={item.title}
                  description={item.description}
                  image={item.image}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );

  return isMobile ? renderMobileLayout() : renderLaptopLayout();
};

export default RotateCard;
