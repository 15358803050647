import React, { useState, useEffect, lazy, Suspense } from 'react';
import { config } from '@react-spring/web';
import { Box, Button } from '@mui/material';

const Carousel = lazy(() => import('react-spring-3d-carousel'));

export default function Carroussel(props) {
    const [offsetRadius, setOffsetRadius] = useState(2);
    const [showArrows, setShowArrows] = useState(false);
    const [goToSlide, setGoToSlide] = useState(null);

    useEffect(() => {
        setOffsetRadius(props.offset);
        setShowArrows(props.showArrows);
    }, [props.offset, props.showArrows]);

    const cards = props.cards.map((element, index) => {
        return { ...element, onClick: () => setGoToSlide(index) };
    });

    return (
        <div style={{ width: props.width, height: props.height, margin: props.margin, position: 'relative' }}>
            <Suspense fallback={<div>Loading...</div>}>
                <Carousel
                    slides={cards}
                    goToSlide={goToSlide}
                    offsetRadius={offsetRadius}
                    showNavigation={false}
                    animationConfig={config.gentle}
                />
                {showArrows && (
                    <>
                        <button
                            style={arrowStyleLeft}
                            onClick={() => setGoToSlide(goToSlide === 0 ? cards.length - 1 : goToSlide - 1)}
                        >
                            &#9664;
                        </button>
                        <button
                            style={arrowStyleRight}
                            onClick={() => setGoToSlide(goToSlide === cards.length - 1 ? 0 : goToSlide + 1)}
                        >
                            &#9654;
                        </button>
                    </>
                )}
            </Suspense>
        </div>
    );
}

const arrowStyleLeft = {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translateY(-50%)',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: 1,
    color: '#00c853',
};

const arrowStyleRight = {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: 1,
    color: '#00c853',
};
