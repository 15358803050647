import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '8px', // Rounded borders
  marginBottom: theme.spacing(2), // Gap between each accordion
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      props.expanded ? (
        <CloseIcon sx={{ fontSize: '1.2rem' }} />
      ) : (
        <AddIcon sx={{ fontSize: '1.2rem' }} />
      )
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  borderRadius: '8px', // Rounded borders for the summary
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  borderRadius: '8px', // Rounded borders for the details
}));

const accordionData = [
  {
    header: 'How do I get started with RapidQube Digital Solutions Pvt. Ltd.?',
    content:
      'Getting started is easy! Simply reach out to us through our contact form, email, or phone. We’ll schedule an initial consultation to discuss your business needs and objectives. From there, we’ll develop a tailored proposal outlining how our solutions can address your specific challenges and goals.',
  },
  {
    header: 'What industries do you specialize in?',
    content:
      'RapidQube Digital Solutions Pvt. Ltd. has experience working across various industries including finance, healthcare, retail, manufacturing, and technology. Our team adapts to the unique requirements of each industry to deliver solutions that drive success and innovation.',
  },
  {
    header: 'How long does it typically take to develop a custom solution?',
    content:
      'The timeline for developing a custom solution depends on the complexity and scope of the project. During our initial consultation, we’ll provide an estimated timeline based on your requirements. We prioritize delivering high-quality solutions efficiently while maintaining open communication throughout the development process.',
  },
  {
    header: 'What makes RapidQube Digital Solutions Pvt. Ltd. different from other technology providers?',
    content:
      'Our differentiators include our commitment to bespoke solutions tailored to each client’s unique needs, our experienced team of professionals, and our focus on enhancing efficiency, security, and operational excellence. We take pride in our collaborative approach, ensuring that our solutions align perfectly with your business goals.',
  },
  {
    header: 'Can you help with ongoing support and maintenance after the initial deployment?',
    content:
      'Yes, we offer comprehensive support and maintenance services to ensure that your technology solutions continue to perform optimally. Our support packages can be customized to fit your needs, including regular updates, troubleshooting, and technical assistance.',
  },
  {
    header: 'Are your solutions scalable to accommodate future growth?',
    content:
      'Absolutely. We design our solutions with scalability in mind, allowing them to grow and adapt as your business evolves. Whether you’re expanding your operations or adding new features, our solutions are built to accommodate your changing needs.',
  },
  {
    header: 'How do you ensure the security of our data?',
    content:
      'Security is a top priority at RapidQube Digital Solutions Pvt. Ltd. We implement robust security measures including encryption, secure access controls, regular security audits, and compliance with industry standards to protect your data and ensure its confidentiality and integrity.',
  },
];

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {accordionData.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index + 1}`}
          onChange={handleChange(`panel${index + 1}`)}
        >
          <AccordionSummary
            aria-controls={`panel${index + 1}d-content`}
            id={`panel${index + 1}d-header`}
            expanded={expanded === `panel${index + 1}`}
          >
            <Typography>{item.header}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: '14px' }}>{item.content}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
