import { HashRouter as  Router} from 'react-router-dom';
import './App.css';
import RouterPage from './Router';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat"
  },
});

function App() {
  return (
  
    <Router>
        <ThemeProvider  theme={theme}>
        <RouterPage />
        </ThemeProvider>
     
    </Router>
   
  );
}

export default App;
