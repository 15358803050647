import { Box, Button, Grid, List, ListItem, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useRef } from 'react';
import AboutImage from '../../Images/About.png'
import '../../Css/Style.css'
import TimeSet from '../../Images/icons/timesetting.png'
import FooterPage from '../../Components/Footer';
import { Accordion, Container } from 'react-bootstrap';
import AboutCarousel from '../../Images/Carousel/Aboutcarousel.png'
import CheckCircleIcon from '../../Images/icons/check-circle-broken.png'
import CustomizedAccordions from '../../Components/Accordion';
import TestimonialSlider from '../../Components/ModelCardSlider';
import NavigationBar from '../NavigationBar';
import IconService from '../../Images/icons/iconservice.png'
import IconSettings from '../../Images/icons/Group 282(1).png'
import IncreaseIcon from '../../Images/icons/Vector(3).png'
import ReduceIcon from '../../Images/icons/Group(2).png'
import UserExpIcon from '../../Images/icons/Vector(4).png'
import ResponseIcon from '../../Images/icons/Vector(5).png'
import ProductBg from '../../Images/Pages/product-bgimg.png'
import ProductSlider from '../ProductsSlider';
import rsuite from '../../Images/rsuite.png';
import demo from "../../Images/demo.jpg";
import platform from "../../Images/stack/platform.png";
import tailored from "../../Images/stack/tailored.png";
import userfriendly from "../../Images/stack/userfriendly.png";
import scalable from "../../Images/productsicons/scalable.png"
import realtime from "../../Images/stack/realtime.png";
import collabration from "../../Images/stack/collabration.png";


export default function RSuitePage({handleOpenDialog}) {

    const footerRef = useRef(null);
    const scrollToFooter = () => {
        footerRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const cards = [
        { id: "01", title: "Finance Management", description: "Streamline your financial operations with robust accounting, budgeting, and reporting tools." },
        { id: "02", title: "Human Resources", description: "Simplify HR processes, including payroll, recruitment, and employee management." },
        { id: "03", title: "Inventory Management", description: "Optimize inventory levels, track stock movements, and manage procurement seamlessly." },
        { id: "04", title: "Sales and CRM", description: "Boost your sales performance with advanced CRM features that help you manage leads, opportunities, and customer relationships effectively." },
        { id: "05", title: "Project Management", description: "Keep projects on track with comprehensive project planning, tracking, and execution tools." },
        { id: "06", title: "Manufacturing", description: "Enhance production efficiency with integrated manufacturing modules that support planning, scheduling, and quality control." },
        { id: "07", title: "Sales and CRM", description: "Boost your sales performance with advanced CRM features that help you manage leads, opportunities, and customer relationships effectively." },
        { id: "08", title: "Project Management", description: "Keep projects on track with comprehensive project planning, tracking, and execution tools." },
        { id: "09", title: "Manufacturing", description: "Enhance production efficiency with integrated manufacturing modules that support planning, scheduling, and quality control." }
    ];

    return (
        <Box>
            <Box sx={{ position: 'relative',height:{xs:"100vh",md:"auto"} }}>
                <NavigationBar scrollToFooter={scrollToFooter}/>
                <img src={rsuite} width="100%" alt="slider1"  style={{height:"100vh",objectFit:"cover"}}/>
                <Box>
                    <Typography
                        variant="h2"
                        component="div"
                        sx={{
                            color: "#fff",
                            fontWeight: "700",
                            position: "absolute",
                            top: "55%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: '75%',
                            '@media (max-width: 720px)': {
                                fontSize: '1rem',
                                marginTop: "10px"
                            },
                            '@media (min-width: 601px) and (max-width: 960px)': {
                                fontSize: '2.5rem',
                            },
                            '@media (min-width: 961px)': {
                                fontSize: '2.5rem',
                            },
                        }}
                    >
                       Unlock Your Business Potential<br/> with R-Suite
                        <Box sx={{
                            '@media (max-width: 720px)': {
                               
                            },
                        }}>
                            <Box sx={{ display: "flex" }}>
                                <Box sx={{
                                    fontSize: "1rem", width: "45%",mt:1,
                                    '@media (max-width: 720px)': {
                                        width: "100%",
                                        fontSize: ".7rem",

                                    },
                                }}>
                                    <Typography>
                                    Transform your business processes with R-Suite, the ultimate ERP solution tailored to meet the unique needs of your organization. Designed for efficiency and scalability, R-Suite provides a comprehensive suite of tools to streamline operations, enhance productivity, and drive growth.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Button className='WebButton' variant="contained" onClick={handleOpenDialog}>Request a Demo</Button>
                            </Box>
                        </Box>
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: "#1C1D22" }}>
                <Grid container spacing={3} paddingTop={"5%"} sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item lg={7} md={7} sm={12} xs={12} >
                        <Box sx={{ paddingLeft:{xs:"2%",lg: "13%"}, }}>
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{
                                    color: "#fff",
                                    fontWeight: "700",
                                    textAlign: "center",
                                    px: 4,
                                    fontSize:{xs:"14px",md:"24px"}
                                }}
                            >
                                RapidQube Digital is a disruptive, results-driven, next-gen IT services provider solving the modern digital challenges and
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box p={4}>
            <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center",gap:5 }}>
                {cards.map((card, index) => {
                    const textColor = index % 3 === 1 ? "white" : "black"; // Column 2 is white, others are black
                    const backgroundColor = index % 3 === 1 ? "transparent" : "white"; // Column 2 has a different background

                    return (
                        <Grid key={index} className="GridCardHeight" item lg={3.5} md={3.5} sm={5.5} xs={12}>
                            <Box
                                className={`GridCard${index % 2 === 0 ? '2' : '3'}`}
                                p={2}
                                sx={{
                                    width: isMobile ? '400px' : 'auto',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    backgroundColor: backgroundColor,
                                    color: textColor,
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                        fontWeight: "700",
                                        color: "#07A117",
                                        mt: 1,
                                       
                                    }}
                                >
                                    {card.id}
                                </Typography>
                                <Typography
                            
                                    component="div"
                                    sx={{
                                        fontWeight: "bold",
                            
                                        fontSize:"18px"
                                    }}
                                >
                                    {card.title}
                                </Typography>
                                <Typography
                                    component="div"
                                    sx={{
                                
                                        lineHeight: "1.5rem",
                                        pb: 1,
                                       
                                    }}
                                >
                                    {card.description}
                                </Typography>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
                <Box sx={{ backgroundColor: "#2C2D2F", mt: 4, padding: "3%" }}>
                    <Container>
                        <Typography
                            variant="h3"
                            component="div"
                            sx={{
                                color: "#07A117",
                                fontWeight: "700",
                                fontSize:{xs:"21px",lg:"32px"}
                            }}
                        >
                       Why R-Suite Stands Out
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Grid container spacing={3} sx={{ paddingTop: "2%" }}>
                                    <Grid item lg={8} md={5} sm={12} xs={12} >
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                color: "#fff",
                                                fontWeight: "400",
                                                fontSize:{xs:"14px",md:"18px"}
                                            }}
                                        >
                                        Choose R-Suite to transform your business operations with a tailored, scalable ERP solution that enhances efficiency, productivity, and growth.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{m:0, width:{xs:'100%',lg:'auto'}, display: "flex", justifyContent: "center", mt: 3, padding: { xs: "20px", lg: "0px" } }}>
  {[
    { src: platform, title: "Integrated Platform", description: "Seamlessly integrate finance, HR, inventory, and customer management into a unified platform for streamlined business processes." },
    { src: tailored, title: "Tailored to Your Needs", description: "R-Suite provides customizable modules tailored to fit your specific business needs, ensuring a perfect operational match." },
    { src: userfriendly, title: "User-Friendly Interface", description: "R-Suite features an intuitive interface, allowing your team to quickly adapt and fully utilize the system." },
    { src: scalable, title: "Scalable", description: "R-Suite scales with your growth, offering flexibility for both small businesses and large enterprises." },
    { src: realtime, title: "Real Time Insights", description: "Gain insights with real-time analytics and reporting to make informed decisions and stay competitive." },
    { src: collabration, title: "Enhanced Collaboration", description: "Enhance team collaboration and communication with integrated tools for efficiency across all departments." }
  ].map((card, index) => (
    <Grid key={index} className="GridCard4" item  lg={3.5} md={3.5} sm={5} xs={12} sx={{ mt: 3, m: 2, border: "1px solid white", border:"1px solid white"}}>
      <Box px={2} py={5} my={3} mx={2}>
        <img src={card.src} width={80} alt="icon service" />
        <Typography  component="div" sx={{ fontWeight: "700", mt: 3 }}>
          {card.title}
        </Typography>
        <Typography component="div" sx={{ mt: 2, lineHeight: "1.5rem", pb: 3 }}>
          {card.description}
        </Typography>
      </Box>
    </Grid>
  ))}
</Grid>

                    </Container>
                </Box>
                <Box
          sx={{
            position: "relative",
            width: "100%",
            height: {
              xs: "400px", // Mobile view
              sm: "400px", // Other views
            },
            overflow: "hidden", // Ensure the image doesn't overflow the box
          }}
        >
          <img
            src={demo}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensure the image covers the box area
            }}
            alt="product bg"
          />
          <Button
            sx={{
              position: "absolute",
              zIndex: 999,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            variant="contained"
            className="WebButton"
            onClick={() => handleOpenDialog()}
          >
            Ask for Demo
          </Button>
        </Box>
                <Box padding={"5%"}>
                    <ProductSlider />
                </Box>
            </Box>

            <FooterPage footerRef={footerRef}/>
        </Box>
    )
}