

export default [
  {
    id: 1,
    bgColor: "#F54748",
   
    title: "Lorem Ipsum",
    desc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
  },
  {
    id: 2,
    bgColor: "#7952B3",
   
    title: "Lorem Ipsum",
    desc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
  },
  {
    id: 3,
    bgColor: "#1597BB",
    
    title: "Lorem Ipsum",
    desc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
  },
  {
    id: 4,
    bgColor: "#185ADB",
    
    title: "Lorem Ipsum",
    desc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
  },
  {
    id: 5,
    bgColor: "#FF616D",
    
    title: "Lorem Ipsum",
    desc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
  }
];
